import { useState } from 'react';
import { successAlert } from '@/helpers/alerts';
import useFaq from '@/hooks/useFaq';
import { Button, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import Modal from '@/components/Modal';
import UpdateForm from './UpdateForm';

const FaqsTable = ({ faqs, reload }: any) => {
  const { deleteFaq } = useFaq();
  const [editing, setEditing] = useState<any>(null);

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Nu vei putea reveni la starea initiala!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFaq(id).then(() => {
          successAlert('Intrebare stearsa cu succes');
          reload();
        });
      }
    });
  };
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <th scope="col">Intrebare</th>
            <th scope="col">Raspuns</th>
            <th scope="col">Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {faqs?.length === 0 && (
            <tr>
              <td colSpan={4} className="text-center">
                Nu exista intrebari
              </td>
            </tr>
          )}
          {faqs?.map((faq: any) => (
            <tr key={faq.id}>
              <td>{faq.id}</td>
              <td>{faq.question}</td>
              <td>{faq.answer}</td>
              <td>
                <Button
                  onClick={() => setEditing(faq)}
                  className="btn btn-sm btn-info"
                >
                  <i className="fas fa-edit" />
                </Button>
                <span className="m-2" />
                <Button
                  onClick={() => handleDelete(faq.id)}
                  className="btn btn-sm btn-danger"
                >
                  <i className="fas fa-trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {editing && (
        <Modal
          title="Editeaza intrebare"
          open={editing}
          toggle={() => setEditing(false)}
        >
          <UpdateForm
            initialValues={editing}
            onUpdated={() => {
              reload();
              setEditing(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FaqsTable;
