import React from 'react';
import FormikField from '@/components/FormikField';
import { Col, Row } from 'reactstrap';
import { useFormikContext } from 'formik';
import { deleteCriteriaRequest } from '@/pages/User/Groups/api';

const CriteriaFormSection = ({ remove, name, index }) => {
  const formik = useFormikContext<any>();

  const values = formik.values.criteria;

  const handleDeleteCriteria = (index) => {
    if (values[index]?.id) {
      deleteCriteriaRequest(values[index].id).then(() => {
        remove(index);
      });
    }
  };

  return (
    <div className={'mt-4'}>
      <h5 className={'d-flex justify-content-between border-bottom'}>
        <span>
          Criteria{' '}
          <span className={'text-primary text-capitalize'}>
            {values?.[index][`attribute`]}
          </span>
        </span>
        <a
          role={'button'}
          className={'text-danger'}
          onClick={() => handleDeleteCriteria(index)}
        >
          <i className="fas fa-trash ms-2"></i>
        </a>
      </h5>

      <div key={1}>
        <Row>
          <Col md={6}>
            <FormikField
              formik={formik}
              value={values?.[index][`attribute`]}
              name={`${name}.attribute`}
              label={'Atribut'}
              type={'select'}
            >
              <option value={''}>Selecteaza atribut</option>
              <option value="age">Varsta</option>
              <option value="email">Email</option>
              <option value="phone">Telefon</option>
              <option value={'amount_spent'}>Suma cheltuita</option>
              <option value={'number_of_orders'}>Numar cumparaturi</option>
            </FormikField>
          </Col>
          <Col md={6}>
            <FormikField
              formik={formik}
              value={values?.[index][`operator`]}
              name={`${name}.operator`}
              label={'Operator'}
              type={'select'}
            >
              <option value={''}>Selecteaza operator</option>
              <option value="=">Egal</option>
              <option value=">">Mai mare</option>
              <option value="<">Mai mic</option>
              <option value="contains">Contine</option>
              <option value="not_contains">Nu contine</option>
            </FormikField>
          </Col>
        </Row>

        <FormikField
          formik={formik}
          value={values?.[index][`value`]}
          name={`${name}.value`}
          label={'Valoare'}
          type={'text'}
        />
      </div>
    </div>
  );
};

export default CriteriaFormSection;
