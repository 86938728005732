import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Pagina nu a fost gasita - {brand.name}</title>
      </Helmet>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center">
                  <div>
                    <h1 className="display-2 error-text fw-bold">
                      4
                      <i className="ri-ghost-smile-fill align-bottom text-primary mx-1"></i>
                      4
                    </h1>
                  </div>
                  <div>
                    <h4 className="text-uppercase mt-4">
                      Ne pare rau, pagina nu a fost gasita
                    </h4>
                    <p>
                      Ca sa nu pierzi timpul, poti sa te intorci la pagina
                      principala :)
                    </p>
                    <div className="mt-4">
                      <Link to="/app" className="btn btn-primary">
                        Mergi Acasa
                        <i className="ri-arrow-right-line align-bottom ms-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;
