import React from 'react';
import { Navigate, Route, useNavigate } from 'react-router-dom';

import useCurrentUser from '../../hooks/useCurrentUser';
import { useEffect } from 'react';

const AuthProtected = (props: any) => {
  const navigate = useNavigate();
  const { isAuthenticated, profile, getProfile, setUser } = useCurrentUser();
  /*
    redirect is un-auth access protected routes via url
    */

  useEffect(() => {
    if (isAuthenticated && !profile) {
      getProfile()
        .then((res) => {
          setUser(res.data.user);
        })
        .catch((err) => {
          navigate('/');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, profile]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!profile) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center h-100vh"
      >
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="pt-4">Please wait...</p>
        </div>
      </div>
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <>
            {' '}
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
