import axios from './axios';

export const getMarketingData = async (params: any = {}) => {
  return await axios.get('/marketing', { params });
};

export const saveMarketingData = async (data: any = {}) => {
  return await axios.post('/marketing', data);
};

export const getMarketingDataById = async (id: any = {}) => {
  return await axios.get(`/marketing/${id}`);
};

export const getMarketingDataLog = async (id: any = {}) => {
  return await axios.get(`/marketing/log/${id}`);
};

export const updateMarketingData = async (id: number, data: any = {}) => {
  return await axios.post(`/marketing/${id}/update`, data);
};

export const deleteMarketingData = async (id: any = {}) => {
  return await axios.delete(`/reports/marketing/${id}`);
};

export const filterUsers = (filters: any = {}, type: 'string') => {
  return axios.post(`/marketing/filter-users`, { filters, type });
};

export const getCalendar = async () => {
  return await axios.get(`/marketing/calendar`);
};

export const copyMarketingData = async (id: number) => {
  return await axios.get(`/marketing/${id}/copy`);
};

export const deleteMarketingCampaign = async (id: number) => {
  try {
    await axios.delete(`/marketing/${id}`).then((res) => res.data);
  } catch (error) {
    if (error.response.status === 409) {
      return error.response.data;
    } else {
      return {
        error: true,
        message: 'A aparut o eroare la stergerea campaniei de marketing',
      };
    }
  }
};

export const cancelMarketingCampaign = async (id: number) => {
  try {
    await axios.get(`/marketing/${id}/stop`).then((res) => res.data);
  } catch (error) {
    if (error.response.status === 422) {
      return error.response.data;
    } else {
      return {
        error: true,
        message: 'A aparut o eroare la oprirea campaniei de marketing',
      };
    }
  }
};
