import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Loader from '../Loader';

interface ICardWidgetProps {
  title: string;
  value: number | string;
  note?: string;
  percentage?: number;
  icon?: string;
  loading?: boolean;
}

const CardWidget = ({
  title,
  value,
  note,
  percentage,
  loading,
  icon,
}: ICardWidgetProps) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {loading && (
            <div className="p-4">
              {' '}
              <Loader loading={loading} />
            </div>
          )}
          {!loading && (
            <div className="d-flex text-muted">
              <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className={icon}></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-1">{title}</p>
                <h5 className="mb-3">{value}</h5>
                {typeof percentage === 'number' && (
                  <p className="text-truncate mb-0">
                    <span
                      className={`text-${
                        percentage >= 0 ? 'success' : 'danger'
                      } me-2`}
                    >
                      {' '}
                      {percentage}%{' '}
                      <i
                        className={`ri-arrow-right-${
                          percentage >= 0 ? 'up' : 'down'
                        }-line align-bottom ms-1`}
                      ></i>
                    </span>{' '}
                    {note}
                  </p>
                )}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CardWidget.defaultProps = {
  note: '',
  icon: 'ri-group-line',
  loading: false,
};

export default CardWidget;
