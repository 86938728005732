import React from 'react';
const statusColorMap = {
  active: 'success',
  enabled: 'success',
  inactive: 'danger',
  paused: 'warning',
  draft: 'secondary',
};
const StatusBadge = ({ status }) => {
  const statusKey = status.toLowerCase();
  const color = statusColorMap[statusKey] ?? 'secondary';
  return <span className={`badge bg-${color}`}>{status}</span>;
};

export default StatusBadge;
