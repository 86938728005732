/**
 * Format a number to a string with a fixed number of decimals
 * @param number
 * @param decimals
 */
export function format_number(number: number | string, decimals = 2) {
  return Number(number).toFixed(decimals);
}

/**
 * Format a number to a string with a fixed number of decimals and a currency symbol
 * @param number
 * @param decimals
 */
export function format_currency(number: number | string, decimals = 2) {
  return `${format_number(Number(number), decimals)} RON`;
}
