import React from 'react';
import { Modal } from 'reactstrap';
import CampaignForm from '@/pages/User/LoyaltyCampaigns/components/CampaignForm';
import { ILoyaltyCampaign, IMilestone } from '@/types/loyalty-campaign';

const CampaignModal = ({
  isOpen,
  onClose,
  submitAction,
  campaign,
}: {
  campaign?: ILoyaltyCampaign | null;
  isOpen: boolean;
  onClose: () => void;
  submitAction: (data: IMilestone) => any;
}) => {
  const handleClose = () => {
    onClose();
  };
  const handleSubmitAction = async (values: any) => {
    return submitAction(values);
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{campaign?.name}</h5>
        <button type="button" className="close" onClick={() => handleClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <CampaignForm formAction={handleSubmitAction} campaign={campaign} />
      </div>
    </Modal>
  );
};

export default CampaignModal;
