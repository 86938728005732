import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import MilestoneForm from '@/pages/User/LoyaltyCampaigns/components/MilestoneForm';

import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import { useNavigate } from 'react-router-dom';
import { IMilestone } from '@/types/loyalty-campaign';
import CampaignForm from '@/pages/User/LoyaltyCampaigns/components/CampaignForm';
import Swal from 'sweetalert2';

const AddCampaignForm = () => {
  const { createCampaign, addMilestone } = useLoyaltyCampaign();
  const navigate = useNavigate();

  const handleMilestoneSubmit = (values: IMilestone) => {
    const response = addMilestone(values);

    if (response?.error) {
      Swal.fire({
        title: 'Error',
        text: 'Pragul exista deja!',
        icon: 'error',
      });
      return response;
    }
  };

  const handleAddCampaign = (values: any) => {
    createCampaign(values).then((response) => {
      if (response.status === 201) {
        // redirect to the campaign page
        navigate(`/app/loyalty-campaigns/${response.data.campaign.id}`);
      }
    });
  };

  return (
    <Row>
      <Col md={7}>
        <CampaignForm formAction={handleAddCampaign} />
      </Col>
      <Col md={5}>
        <Row>
          <Card className="border border-secondary bg-light p-4">
            <CardTitle color={'primary'} tag="h4">
              Detalii prag
            </CardTitle>
            <CardBody>
              <MilestoneForm submitAction={handleMilestoneSubmit} />
            </CardBody>
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default AddCampaignForm;
