import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row } from 'reactstrap';
import FormikField from '@/components/FormikField';
import useFaq from '@/hooks/useFaq';
import { errorAlert, successAlert } from '@/helpers/alerts';

interface Props {
  onUpdated: () => void;
  initialValues: any;
}
// 1920 Senior Software Engineer
const CreateForm: React.FC<Props> = ({ initialValues, onUpdated }) => {
  const { updateFaq } = useFaq();
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      question: Yup.string().required('Intrebarea este obligatorie'),
      answer: Yup.string().required('Raspunsul este obligatoriu'),
    }),
    onSubmit: (values) => {
      updateFaq(initialValues.id, values)
        .then(() => {
          onUpdated();
          successAlert('Intrebare a fost actualizata cu success');
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <FormikField
            formik={formik}
            label="Intrebare"
            name="question"
            type="text"
          />
        </Col>
        <Col lg={12}>
          <FormikField
            formik={formik}
            label="Raspuns"
            name="answer"
            type="textarea"
          />
        </Col>
        <Col lg={12}>
          <Button
            color="primary"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Creaza
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CreateForm;
