import React, { useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import EmptyState from '@/components/EmptyState';
import { IBonusTransaction } from '@/types/loyalty-campaign';
import { format_currency } from '@/helpers/numbers';
import { format_date } from '@/helpers/dates';
import { Link } from 'react-router-dom';

const DetailsTable = ({ campaignId }: { campaignId: number }) => {
  const { getCampaignTransactions } = useLoyaltyCampaign();
  const [transactionsResponse, setTransactionsResponse] = React.useState({
    data: [] as any,
  });
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    getCampaignTransactions(campaignId)
      .then((res) => {
        setTransactionsResponse(res?.data);
      })
      .finally(() => setLoading(false));
  }, [campaignId]);

  return (
    <Card>
      <CardBody>
        {!loading &&
        !!transactionsResponse &&
        transactionsResponse?.data?.length === 0 ? (
          <EmptyState
            title={'Nu sunt date'}
            description={'Nu sunt inregistrari pentru campania selectata!'}
          />
        ) : (
          <Table responsive={true}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Client</th>
                <th>Suma tranazatie</th>
                <th>Contributie prag</th>
                <th>Numar prag</th>
                <th>Data tranzactiei</th>
              </tr>
            </thead>
            <tbody>
              {transactionsResponse.data?.map(
                (bonusEntry: IBonusTransaction) => (
                  <tr>
                    <td>{bonusEntry.id}</td>
                    <td>
                      <Link to={`/app/users/${bonusEntry.user.uuid}`}>
                        {bonusEntry.user.name}
                      </Link>
                    </td>
                    <td>{format_currency(bonusEntry.transaction.amount)}</td>
                    <td>{format_currency(bonusEntry.progress_amount)}</td>
                    <td>{bonusEntry?.milestone_number}</td>
                    <td>{format_date(bonusEntry.created_at)}</td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
};

export default DetailsTable;
