import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import { ISale } from '@/types/reports';
import dayjs from 'dayjs';
import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getPointsReport } from '@/api/reports';
import PaginationComponent from '@/components/Paginate';
import SalesFilters from 'src/pages/User/Reports/components/PointsFilters';
import Loader from '@/components/Loader';
import { Link } from 'react-router-dom';

const columns = [
  {
    name: 'ID',
    label: 'id',
    selector: (row: ISale) => row.id,
    sortable: true,
    sortField: 'id',
  },
  {
    name: 'Data',
    label: 'created_at',
    selector: (row: ISale) => dayjs(row.created_at).format('DD-MM-YYYY HH:mm'),
    sortable: true,
    sortField: 'created_at',
  },
  {
    name: 'Utilizator',
    label: 'name',
    selector: (row: ISale) => row.name,
    format: (row: ISale) => (
      <Link to={`/app/users/${row.user_uuid}`}>{row.name}</Link>
    ),
    // sortable: true,
  },
  {
    name: 'Tip Utilizator',
    label: 'is_business',
    selector: (row: ISale) => (row.is_business ? 'Companie' : 'P. Fizica'),
    sortable: false,
  },
  {
    name: 'Varsta',
    label: 'birth_date',
    selector: (row: ISale) =>
      row.birth_date ? dayjs().diff(row.birth_date, 'years') + ' ani' : 'n/a',
    sortable: true,
    sortFunction: (a: any, b: any) => {
      const aAge = a.birth_date ? dayjs().diff(a.birth_date, 'years') : 0;
      const bAge = b.birth_date ? dayjs().diff(b.birth_date, 'years') : 0;
      return aAge - bAge;
    },
  },
  {
    name: 'Magazin',
    label: 'location_name',
    selector: (row: ISale) => row.location_name,
  },
  {
    name: 'Puncte',
    label: 'points',
    selector: (row: any) => (row.points > 0 ? '+' + row.points : row.points),
    sortField: 'points',
    sortable: true,
  },
  {
    name: 'Platit',
    label: 'amount',
    selector: (row: ISale) => row.amount,
    sortField: 'amount',
  },
  {
    name: 'Tip Vanzare',
    selector: (row: ISale) =>
      row.type === 'INCOME' ? 'Obtine puncte' : 'Foloseste puncte',
  },
  {
    name: 'Mod Obtinere',
    selector: (row: ISale) => row.description,
    format: (row: ISale) => (
      <button
        role={'status'}
        title={row?.details}
        className={`btn btn-sm text-info ${row?.details ? '' : 'border-0 text-dark'}`}
      >
        {row.description}
      </button>
    ),
  },
];

const PointsReportPage = () => {
  const {
    loading,
    salesData,
    page,
    perPage,
    total,
    changePage,
    changePerPage,
    changeSort,
    salesQuery,
  } = useSalesReportsHook(getPointsReport);

  const handleSort = (column: any, sortDirection: string) => {
    changeSort(column.label, sortDirection);
  };

  useEffect(() => {
    salesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Raport Puncte - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Raport puncte" />
          <SalesFilters />
          <Card>
            <CardHeader className="bg-info">
              <CardTitle className="text-white">Raport Puncte</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
              {loading && <Loader loading={loading} />}

              {!loading && salesData?.length > 0 && (
                <>
                  <DataTable
                    columns={columns}
                    data={salesData}
                    dense
                    onSort={handleSort}
                    sortServer={true}
                  />

                  {/*<CustomTable*/}
                  {/*  columns={columns}*/}
                  {/*  data={salesData}*/}
                  {/*  order={currentSortDirection}*/}
                  {/*  sort={sort}*/}
                  {/*  sortAction={handleSort}*/}
                  {/*/>*/}

                  <div className="d-flex justify-content-center align-items-center p-3">
                    <PaginationComponent
                      page={page}
                      perPage={perPage}
                      total={total}
                      onClick={(p) => changePage(p)}
                    />
                    <div
                      className={`form-group d-flex align-items-center ${
                        total < 11 ? 'd-none' : ''
                      }`}
                    >
                      <select
                        className="form-control m-2"
                        id="perPage"
                        style={{ width: '50px' }}
                        value={perPage}
                        onChange={(e) =>
                          changePerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                      {'   '} - pe pagina din
                      <strong className={'p-1'}>{total}</strong>
                      inregistrari
                    </div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PointsReportPage;
