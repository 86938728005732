import {
  configureStore,
  ThunkAction,
  Action,
  Selector,
  combineReducers,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice from './slices/auth.slice';
import usersSlice from './slices/users.slice';
import locationsSlice from './slices/locations.slice';
import categoriesSlice from './slices/categories.slice';
import productsSlice from './slices/products.slice';
import salesSlice from './slices/sales.slice';
import notificationsSlice from './slices/notifications.slice';
import usersReportSlice from './slices/usersReport.slice';
import appContentSlice from './slices/appContent.slice';
import dashboardSlice from './slices/dashboard.slice';
import faqsSlice from './slices/faqs.slice';
import businessesSlice from './slices/businesses.slice';
import salesReportsSlice from './slices/salesReports.slice';
import loyaltyCampaignSlice from './slices/loyaltyCampaigns.slice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['sales', 'usersReport', 'salesReports', 'users'],
};

const rootReducer = combineReducers({
  auth: authSlice,
  users: usersSlice,
  locations: locationsSlice,
  categories: categoriesSlice,
  products: productsSlice,
  sales: salesSlice,
  usersReport: usersReportSlice,
  notifications: notificationsSlice,
  appContent: appContentSlice,
  dashboard: dashboardSlice,
  faq: faqsSlice,
  businesses: businessesSlice,
  salesReports: salesReportsSlice,
  loyaltyCampaign: loyaltyCampaignSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export type AppSelector = Selector<RootState, RootState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
