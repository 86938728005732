import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSummary } from '@/types/reports';

interface IDashboardSlice {
  loadingSummary: boolean;
  summary: IDashboardSummary;
  overview: {
    series: any[];
    categories: string[];
  };
  productsOverview: any[];
  categoriesOverview: any[];
  suspectedTransactions: any[];
  pointsOverview: any;
  users: any;
  transactions: any;
}

const initialState: IDashboardSlice = {
  loadingSummary: true,
  overview: {
    series: [],
    categories: [],
  },
  summary: {
    users: {
      current: 0,
      past: 0,
    },
    sales: {
      current: 0,
      past: 0,
    },
    generatedPoints: {
      current: 0,
      past: 0,
    },
    redeemedPoints: {
      current: 0,
      past: 0,
    },
  },
  productsOverview: [],
  suspectedTransactions: [],
  categoriesOverview: [],
  pointsOverview: {},
  users: [],
  transactions: [],
};

export const dashboardSlice = createSlice({
  name: 'gesp/dashboard',
  initialState,
  reducers: {
    setSummary: (state, action: PayloadAction<IDashboardSummary>) => {
      state.summary = action.payload;
      state.loadingSummary = false;
    },
    setOverview: (state, action: PayloadAction<any>) => {
      state.overview = action.payload;
    },
    setLoadingSummary: (state, action: PayloadAction<boolean>) => {
      state.loadingSummary = action.payload;
    },
    setProductsOverview: (state, action: PayloadAction<any>) => {
      state.productsOverview = action.payload;
    },
    setCategoriesOverview: (state, action: PayloadAction<any>) => {
      state.categoriesOverview = action.payload;
    },
    setPointsOverview: (state, action: PayloadAction<any>) => {
      state.pointsOverview = action.payload;
    },
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setTransactions: (state, action: PayloadAction<any>) => {
      state.transactions = action.payload;
    },
    setSuspectedTransactions: (state, action: PayloadAction<any>) => {
      state.suspectedTransactions = action.payload;
    },
  },
});

export const {
  setSummary,
  setLoadingSummary,
  setOverview,
  setProductsOverview,
  setCategoriesOverview,
  setPointsOverview,
  setUsers,
  setTransactions,
  setSuspectedTransactions,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
