import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import options from './options';
import useDashboard from '@/hooks/useDashoard';
const PointsOverview = () => {
  const { pointsOverview, getPointsOverview } = useDashboard();
  useEffect(() => {
    getPointsOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const chartData = options(pointsOverview?.labels);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Chart
            options={chartData.options as any}
            series={pointsOverview?.series}
            type="pie"
            height={350}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PointsOverview;
