import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  getDashboardSummary,
  getOverview as getOverviewRequest,
  getProductsOverview as getProductsOverviewRequest,
  getCategoriesOverview as getCategoriesOverviewRequest,
  getPointsOverview as getPointsOverviewRequest,
  getLatestTransactions as getLatestTransactionsRequest,
  getLatestUsers as getLatestUsersRequest,
  getSuspectTransactions as getSuspectTransactionsRequest,
} from '@/api/dashboard';
import { AxiosResponse } from 'axios';
import { IDashboardSummary } from '../types/reports';
import {
  setOverview,
  setSummary,
  setProductsOverview,
  setCategoriesOverview,
  setPointsOverview,
  setTransactions,
  setUsers,
  setSuspectedTransactions,
} from '@/redux/slices/dashboard.slice';

const reducerName = 'dashboard';

const useDashboard = () => {
  const dispatch = useAppDispatch();

  const reducer = useAppSelector((state) => state[reducerName]);

  const getSummary = () =>
    getDashboardSummary().then((res: AxiosResponse<IDashboardSummary>) => {
      dispatch(setSummary(res.data));
    });

  const getOverview = () => {
    getOverviewRequest().then((res: AxiosResponse) => {
      dispatch(setOverview(res.data));
    });
  };

  const getProductsOverview = () => {
    getProductsOverviewRequest().then((res: AxiosResponse) => {
      dispatch(setProductsOverview(res.data));
    });
  };

  const getCategoriesOverview = () => {
    getCategoriesOverviewRequest().then((res: AxiosResponse) => {
      dispatch(setCategoriesOverview(res.data));
    });
  };

  const getPointsOverview = () => {
    getPointsOverviewRequest().then((res: AxiosResponse) => {
      dispatch(setPointsOverview(res.data));
    });
  };

  const getLatestTransactions = () => {
    getLatestTransactionsRequest().then((res: AxiosResponse) => {
      dispatch(setTransactions(res.data));
    });
  };

  const getSuspectedTransactions = () => {
    getSuspectTransactionsRequest().then((res: AxiosResponse) => {
      dispatch(setSuspectedTransactions(res.data.transactions));
    });
  };

  const getLatestUsers = () => {
    getLatestUsersRequest().then((res: AxiosResponse) => {
      dispatch(setUsers(res.data));
    });
  };

  return {
    ...reducer,
    getSummary,
    getOverview,
    getProductsOverview,
    getCategoriesOverview,
    getPointsOverview,
    getLatestTransactions,
    getSuspectedTransactions,
    getLatestUsers,
  };
};

export default useDashboard;
