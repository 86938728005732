import useDashboard from '@/hooks/useDashoard';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

const LatestUsers = () => {
  const { users, getLatestUsers } = useDashboard();

  useEffect(() => {
    getLatestUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Utilizatori Noi</CardTitle>
          <Table responsive>
            <thead>
              <tr>
                <td>#</td>
                <td>Nume</td>
                <td>Email</td>
                <td>Puncte</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0 && (
                <>
                  {users.map((user: any) => (
                    <tr key={`${user.uuid}`}>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{Number(user?.points)?.toFixed(2)}</td>
                      <td>
                        {dayjs(user.created_at).format('DD/MM/YYYY HH:mm')}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-center" colSpan={5}>
                  <Link to="/app/users">Vezi toți utilizatorii</Link>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LatestUsers;
