import dayjs from 'dayjs';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDrag, useDrop } from 'react-dnd';

const TableRow = ({
  index,
  isExpired,
  appContent,
  toggleSection,
  removeSection,
  moveRow,
}: any) => {
  const ref = useRef<any>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'tr',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;

      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveRow(dragIndex, hoverIndex);
      }

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'tr',
    item: () => {
      return { id: appContent.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <tr ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <td>{index + 1}</td>
      <td>{appContent.id}</td>
      <td>
        {isExpired(appContent) ? (
          <div className="red-dot" />
        ) : (
          <div className="green-dot" />
        )}{' '}
        {appContent.name}
      </td>
      <td>
        {appContent.image ? (
          <img src={appContent.image} alt={appContent.name} width="100" />
        ) : (
          'N/A'
        )}
      </td>
      <td>{appContent.show_on}</td>
      <td>{appContent.url}</td>
      <td>{appContent.mode}</td>
      <td>
        {appContent.start_date
          ? dayjs(appContent.start_date).format('DD.MM.YYYY HH:mm')
          : 'N/A'}{' '}
      </td>
      <td>
        {appContent.end_date
          ? dayjs(appContent.end_date).format('DD.MM.YYYY HH:mm')
          : 'N/A'}
      </td>
      <td className="text-center">
        {!isExpired(appContent) ? (
          <span className="btn btn-sm btn-info rounded-5 pt-0 pb-0">Activ</span>
        ) : (
          <span className="btn btn-sm btn-danger rounded-5 pt-0 pb-0">
            {isExpired(appContent)}
            Inactiv
          </span>
        )}
      </td>
      <td className="text-center">
        <Link
          to={`/app/contents/${appContent.id}`}
          className="btn btn-sm btn-primary"
        >
          Modifica
        </Link>
        {appContent.enabled ? (
          <Button
            onClick={() => toggleSection(appContent.id, false)}
            color="warning"
            size="sm"
          >
            Dezactiveaza
          </Button>
        ) : (
          <Button
            onClick={() => toggleSection(appContent.id, true)}
            color="info"
            size="sm"
          >
            Activeaza
          </Button>
        )}
        <Button
          onClick={() => removeSection(appContent.id)}
          size="sm"
          color="danger"
        >
          Sterge
        </Button>
      </td>
    </tr>
  );
};

export default TableRow;
