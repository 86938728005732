import axios from './axios';

export const getThemes = () => {
  return axios.get('/themes');
};

export const createTheme = (data: any) => {
  return axios.post('/themes', data);
};

export const updateTheme = (id: string, data: any) => {
  return axios.post(`/themes/${id}`, data);
};

export const deleteTheme = (id: string) => {
  return axios.delete(`/themes/${id}`);
};

export const setForAdmin = (id: string) => {
  return axios.get(`/themes/${id}/set-admin`);
};

export const setPublic = (id: string) => {
  return axios.get(`/themes/${id}/set-public`);
};

export const viewTheme = (id: string) => {
  return axios.get(`/themes/${id}`);
};

export const getDefaultTheme = () => {
  return axios.get('/themes/default');
};
