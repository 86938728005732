import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import useCurrentUser from '@/hooks/useCurrentUser';

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const { profile } = useCurrentUser();
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={'/assets/user.png'}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">
            {profile?.first_name} {profile?.last_name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/*<DropdownItem tag="a" href="/userprofile">*/}
          {/*  {' '}*/}
          {/*  <i className="ri-user-line align-middle me-2" />*/}
          {/*  Contul Meu*/}
          {/*</DropdownItem>*/}
          <DropdownItem tag="div">
            <Link to={'/app/settings'} className={'text-dark'}>
              <i className="ri-settings-2-line align-middle me-2" />
              Setari
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link
            onClick={() => {
              sessionStorage.removeItem('token');
              window.location.replace('/');
            }}
            to="#"
            className="dropdown-item"
          >
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
