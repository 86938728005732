import React from 'react';

interface IThProps {
  prop: string;
  children?: React.ReactNode;
  sort?: string;
  order?: 'asc' | 'desc';
  onClick?: (prop: string) => void;
  width?: string | number;
  className?: string;
}

const Th = ({
  children,
  prop,
  sort,
  order,
  onClick,
  width,
  className,
}: IThProps) => {
  const determineIcon = () => {
    if (sort === prop) {
      if (order === 'asc') {
        return 'ri-arrow-up-s-line';
      } else {
        return 'ri-arrow-down-s-line';
      }
    } else {
      return 'ri-arrow-up-down-line';
    }
  };

  return (
    <th
      className={`sortable-tr ${className}`}
      style={width ? { width } : {}}
      onClick={() => onClick && onClick(prop)}
    >
      <div className="d-flex justify-content-between">
        {children}
        <i className={determineIcon()}></i>
      </div>
    </th>
  );
};

Th.defaultProps = {
  className: '',
};

export default Th;
