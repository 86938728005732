import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getMarketingDataLog } from '@/api/marketing';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Loader from '@/components/Loader';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Container } from 'reactstrap';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

const columns = [
  {
    name: 'ID',
    label: 'id',
    selector: (row: any) => row.id,
  },
  {
    name: 'Utilizator',
    label: 'user',
    selector: (row: any) => row?.user?.first_name + ' ' + row?.user?.last_name,
  },
  {
    name: 'Tip notificare',
    label: 'type',
    selector: (row: any) => row.type,
  },
  {
    name: 'Status',
    label: 'status',
    selector: (row: any) => row.status,
  },
  {
    name: 'Raspuns',
    label: 'response',
    selector: (row: any) => (
      <div
        style={{
          textOverflow: 'visible',
          overflow: 'visible',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.response}
      </div>
    ),
  },
  {
    name: 'Pornit la',
    label: 'started_at',
    selector: (row: any) =>
      row?.started_at && dayjs(row?.started_at).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    name: 'Finalizat la',
    label: 'updated_at',
    selector: (row: any) =>
      dayjs(row?.updated_at).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    name: 'Creat la',
    label: 'created_at',
    selector: (row: any) => dayjs(row.created_at).format('DD-MM-YYYY HH:mm:ss'),
  },
];

const ViewDetails = () => {
  const { id } = useParams();
  const [notification, setNotification] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  // group notifications by status
  const groupedNotifications = React.useMemo(() => {
    if (notification?.notifications) {
      return notification?.notifications.reduce(
        (acc: any, curr: any) => ({
          ...acc,
          [curr.status]: [...(acc[curr.status] || []), curr],
        }),
        {},
      );
    }
    return {};
  }, [notification?.notifications]);

  useEffect(() => {
    setLoading(true);
    getMarketingDataLog(id).then((res) => {
      setNotification(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Loader loading={loading} />
        {!loading && (
          <Container fluid={true}>
            <Breadcrumbs
              title={brand.shortName}
              bread={[
                { title: 'Marketing', url: '/app/marketing' },
                {
                  title: `Status notificari trimise ${notification.label}`,
                  url: `/app/marketing/view-log/${id}`,
                },
              ]}
              breadcrumbItem={`Marketing campaign details - ${notification?.label}`}
            />
            <div
              className={
                'p-2 bg-white rounded-2 mb-4 d-flex justify-content-start align-content-center'
              }
            >
              <div
                style={{ fontSize: 14, fontWeight: 'bold', lineHeight: '52px' }}
              >
                Statistica notificari trimise
              </div>
              {Object.keys(groupedNotifications).map((key) => (
                <div key={key} className={'bg-info d-block m-2 p-2 rounded-2'}>
                  <div
                    className={'text-black-50'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {key == 'null' ? 'Necunoscut' : key} -{' '}
                    {groupedNotifications[key].length}
                  </div>
                </div>
              ))}
            </div>

            <DataTable columns={columns} data={notification?.notifications} />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewDetails;
