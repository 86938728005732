import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';
import options from './options';
import useDashboard from '@/hooks/useDashoard';

const CategoriesOverview = () => {
  const parentRef = useRef<any>(null);
  const { categoriesOverview, getCategoriesOverview } = useDashboard();

  useEffect(() => {
    getCategoriesOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div
            ref={parentRef}
            style={{ width: '100%' }}
            className="d-flex flex-wrap"
          >
            <Chart
              options={options.options}
              series={[{ data: categoriesOverview }]}
              type="treemap"
              height={300}
              width={parentRef.current?.offsetWidth || '100%'}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CategoriesOverview;
