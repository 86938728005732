import { contestUserParticipants } from '@/api/contest';
import Loader from '@/components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const columns = [
  {
    keyField: 'id',
    name: '#',
    selector: (row: any, index: any) => {
      return index + 1;
    },
    sortable: false,
  },
  {
    keyField: 'user_id',
    name: 'ID User',
    selector: (row: any) => row.user_id,
    sortable: true,
  },
  {
    keyField: 'name',
    name: 'Utilizator',
    selector: (row: any) => row?.name,
    sortable: true,
  },
  {
    keyField: 'email',
    name: 'Email',
    selector: (row: any) => row.email,
    sortable: true,
  },
  {
    keyField: 'phone_no',
    name: 'Telefon',
    selector: (row: any) => row.phone_no,
    sortable: true,
  },
  {
    keyField: 'participations',
    name: 'Total Participari',
    selector: (row: any) => Number(row.participations),

    sortable: true,
  },
];

const ContestTotalParticipators = ({
  contest_id,
  locationId,
  search,
}: {
  contest_id: number;
  locationId: number;
  search: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [perPage] = useState(25);

  const timer = useRef<any>(null);
  const [participants, setParticipants] = React.useState<any>([]);

  const fetchTransactions = async () => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      contestUserParticipants(contest_id, {
        location_id: locationId,
        page,
        perPage: perPage,
        search,
      })
        .then((res) => {
          setParticipants(res.data.participations);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contest_id, page, search, locationId]);

  useEffect(() => {
    setPage(1);
  }, [search, locationId]);

  return (
    <Card>
      <CardHeader className="bg-primary d-flex justify-content-between">
        <CardTitle className="text-white">Participari per user</CardTitle>
      </CardHeader>
      <CardBody>
        {participants && (
          <DataTable
            pagination
            paginationPerPage={30}
            columns={columns}
            data={participants}
            dense
          />
        )}
        {loading && <Loader loading={loading} />}
      </CardBody>
    </Card>
  );
};

export default ContestTotalParticipators;
