/**
 * Convert array of objects to CSV
 * @param array
 * @param keys
 */
export function convertArrayOfObjectsToCSV(array: any[], keys: string[]) {
  let result: string;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      // result += item[key];
      if (key.indexOf('.') > -1) {
        const keys = key.split('.');
        let obj = item;
        keys.forEach((k) => {
          obj = obj?.[k];
        });
        result += obj;
      } else {
        result += item[key];
      }
      // eslint-disable-next-line no-plusplus
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

/**
 * Download CSV file
 * @param array
 * @param keys
 * @param prepend_name
 */
export function downloadCSV(array: any[], keys: string[], prepend_name = '') {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array, keys);
  if (csv == null) return;

  const filename = prepend_name + 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}
