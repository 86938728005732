import React, { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'reactstrap';

import FormikField from '@/components/FormikField';
import { createProduct, updateProduct } from '@/api';
import { successAlert } from '@/helpers/alerts';
import DropFile from '@/components/DropFile';
import CategoryPicker from '@/components/CategoryPicker';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu'),
  code: Yup.string().required('Codul este obligatoriu'),
  price: Yup.number().required('Pretul este obligatoriu'),
  category: Yup.string().required('Categoria este obligatorie'),
});

const ProductForm = ({ initialValues, onSubmit, id }: any) => {
  const [file, setFile] = React.useState<any>(null);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // form data
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('code', values.code);
      formData.append('price', values.price);
      formData.append('category', values.category);
      formData.append('description', values.description);
      if (file) {
        formData.append('image', file);
      }

      if (id) {
        // update
        updateProduct(id, formData).then((res) => {
          onSubmit(res.data.product);
          successAlert('Produsul a fost modificat cu succes');
        });
      } else {
        // create
        createProduct(formData).then((res) => {
          onSubmit(res.data.product);
          successAlert('Produsul a fost creat cu succes');
        });
      }
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const onFileDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    setFile(file);
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <FormikField
                  label="Nume produs"
                  name="name"
                  formik={formik}
                  placeholder="Numele produsului"
                />
              </Col>
              <Col md={6}>
                <FormikField
                  label="Cod"
                  name="code"
                  formik={formik}
                  placeholder="Codul produsului"
                />
              </Col>
              <Col md={6}>
                <FormikField
                  label="Pret"
                  name="price"
                  formik={formik}
                  type="number"
                  placeholder="Pretul produsului"
                />
              </Col>
              <Col md={6}>
                <CategoryPicker
                  onPick={(value: any) =>
                    formik.setFieldValue('category', value)
                  }
                  value={formik.values.category}
                />
              </Col>
              <Col md={12}>
                <FormikField
                  label="Descriere"
                  name="description"
                  formik={formik}
                  placeholder="Descrierea produsului"
                  type="textarea"
                  rows={5}
                />
              </Col>
              <Col md={12}>
                <Button color="primary" type="submit">
                  {id ? 'Modifica' : 'Adauga'}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <p>Imagine</p>
                <DropFile
                  onDropFile={onFileDrop}
                  defaultPreview={initialValues.image}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default ProductForm;
