import React, { useEffect, useState } from 'react';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
} from 'reactstrap';
import {
  getTranslationsRequest,
  updateTranslationRequest,
} from '@/api/translations';
import Swal from 'sweetalert2';

let timeout: any = null;

const TextsPage = () => {
  const [translations, setTranslations] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  useEffect(() => {
    getTranslations(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTranslations = (by: string) => {
    getTranslationsRequest(by).then((res) => {
      setTranslations(res.data.translations);
    });
  };

  const handleSearch = (value: any) => {
    setSearch(value);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      getTranslations(value);
    }, 500);
  };

  const handleSave = (index: number) => {
    const val = translations[index].t_value;
    updateTranslationRequest(translations[index].id, val).then(() => {
      getTranslations(search);
      Swal.fire({
        title: 'Succes',
        text: 'Textul a fost salvat cu succes',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });
    });
  };

  const handleChange = (index: number, value: string) => {
    const newTranslations = [...translations];
    newTranslations[index].t_value = value;
    setTranslations(newTranslations);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Texte aplicatie - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Texte aplicatie"
          />
          <Card>
            <CardHeader className="bg-primary">
              <CardTitle className="text-white d-flex align-items-center justify-content-between">
                Administrare Text aplicatie - {brand.shortName}
                <Input
                  type="text"
                  className="float-right"
                  placeholder="Cauta"
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ maxWidth: '400px' }}
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Cheie</th>
                      <th style={{ minWidth: 600 }}>Text Vizibil</th>
                      <th>Text Original</th>
                      <th>Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {translations.map((translation, index) => (
                      <tr key={translation.id}>
                        <td>{translation.t_key}</td>
                        <td>
                          <Input
                            value={translation.t_value}
                            type="textarea"
                            onChange={(e) => {
                              handleChange(index, e.target.value);
                            }}
                            onBlur={(e) => {
                              translation.t_value = e.target.value;
                            }}
                          />
                        </td>
                        <td>{translation.t_default}</td>
                        <td>
                          <button
                            onClick={() => handleSave(index)}
                            className="btn btn-primary"
                          >
                            Salveaza
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TextsPage;
