import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import update from 'immutability-helper';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@/components/Breadcrumbs';
import brand from '@/brand';
import useAppContent from '@/hooks/useAppContent';
import { IAppContent } from '@/types/appContnent';

import { errorAlert, successAlert } from '@/helpers/alerts';
import Swal from 'sweetalert2';
import Modal from '@/components/Modal';
import AppContentForm from '../components/AppContentForm';
import { changeContentSortOrder, createAppContent } from '@/api';
import dayjs from 'dayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableRow from './TableRow';

const isExpired = (item: IAppContent) => {
  return !item.enabled || dayjs(item.end_date).isBefore(dayjs());
};

let timeout: any = null;
const AppContentPage = () => {
  const navigate = useNavigate();
  const { data, dataQuery, deleteRequest, toggleAppContent } = useAppContent();
  const [content, setContent] = useState<any>(null);
  const [sortedContent, setSorted] = useState<any>([]);
  const [filter, setFilter] = useState<any>('active');
  const [sortChanged, setSortChanged] = useState<number>(0);
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

  useEffect(() => {
    if (filter === 'active') {
      setSorted(
        data?.filter((item: any) => {
          if (!item.enabled) {
            return null;
          } else if (dayjs(item.end_date).isBefore(dayjs())) {
            return null;
          }
          return item;
        }),
      );
    } else if (filter === 'expired') {
      setSorted(
        data?.filter((item: any) => {
          if (!item.enabled) {
            return null;
          } else if (dayjs(item.end_date).isBefore(dayjs())) {
            return item;
          }
          return null;
        }),
      );
    } else if (filter === 'all') {
      setSorted(data);
    }
    if (!filtersChanged) {
      setFiltersChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  useEffect(() => {
    if (filtersChanged) {
      setTimeout(() => {
        setFiltersChanged(false);
      }, 10);
    }
  }, [filtersChanged]);

  const removeSection = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Stergerea este ireversibila!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest(id).then(() => {
          dataQuery();
          successAlert('Continutul a fost sters cu succes');
        });
      }
    });
  };

  const toggleSection = (id: number, state: boolean) => {
    toggleAppContent(id, state).then((res) => {
      if (!res.data.error) {
        dataQuery();
        successAlert('Continutul a fost modificat cu succes');
      } else {
        errorAlert(res.data.message);
      }
    });
  };

  const handleCreate = (values: any) => {
    createAppContent(values)
      .then((res) => {
        navigate(`/app/contents/${res.data.section.id}`);
        successAlert('Sectiunea a fost creat cu succes');
      })
      .catch((err) => {
        if (err.response.status === 422) {
          errorAlert(
            err.response.data.error?.name || err.response.data.error?.key,
          );
        } else {
          errorAlert('A aparut o eroare la crearea sectiunii');
        }
      });
  };

  const moveRow = useCallback((dragIndex: any, hoverIndex: any) => {
    setSorted((prevSorted: any) =>
      update(prevSorted, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSorted[dragIndex]],
        ],
      }),
    );
    syncDb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortChanged > 0) {
      const sortedPositions = sortedContent.map((item: any, index: number) => {
        return {
          id: item.id,
          position: index,
        };
      });
      changeContentSortOrder(sortedPositions);
    }
  }, [sortChanged]);

  const syncDb = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      setSortChanged((prev) => prev + 1);
    }, 1000);
  };

  const renderRows = () => {
    return (
      sortedContent &&
      sortedContent.map((appContent: IAppContent, index: number) => (
        <TableRow
          key={appContent.id + 'xcard'}
          index={index}
          appContent={appContent}
          isExpired={isExpired}
          removeSection={removeSection}
          toggleSection={toggleSection}
          moveRow={moveRow}
        />
      ))
    );
  };

  if (filtersChanged) {
    return null;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Oferte</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                url: '/contents',
                title: 'Oferte',
              },
            ]}
            breadcrumbItem="Oferte"
          />
        </Container>
        <Card>
          <CardHeader className="text-white bg-info">
            <CardTitle>Modificare Oferte</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}></Col>
              <Col md={4}></Col>
              <Col md={4} className="d-flex justify-content-end">
                <Button
                  style={{ marginRight: '10px' }}
                  disabled={filter === 'all'}
                  onClick={() => setFilter('all')}
                  size="sm"
                  color="info"
                >
                  Toate
                </Button>
                <Button
                  disabled={filter === 'active'}
                  style={{ marginRight: '10px' }}
                  onClick={() => setFilter('active')}
                  size="sm"
                  color="success"
                >
                  Active
                </Button>
                <Button
                  disabled={filter === 'expired'}
                  style={{ marginRight: '10px' }}
                  onClick={() => setFilter('expired')}
                  size="sm"
                  color="danger"
                >
                  Expirate
                </Button>
                <Button onClick={() => setContent({})} size="sm" color="info">
                  Adauga <i className="fas fa-plus" />
                </Button>
              </Col>
              <Col md={12}>
                <Table responsive={true} hover={true} striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Nume</th>
                      <th>Imagine</th>
                      <th>Afisat pe</th>
                      <th>URL</th>
                      <th>Tip</th>
                      <th>Început</th>
                      <th>Sfarşit</th>
                      <th>Activ</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <DndProvider backend={HTML5Backend}>
                      {renderRows()}
                    </DndProvider>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      {content && (
        <Modal title="Adauga Oferte" open toggle={() => setContent(null)}>
          <AppContentForm content={content} handleSubmit={handleCreate} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default AppContentPage;
