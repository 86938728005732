import axios from './axios';

export const getTermsRequest = () => {
  return axios.get('/terms');
};

export const updateTermRequest = (id: number, data: { content: string }) => {
  return axios.post(`/terms/${id}`, data);
};

export const createTermRequest = (data: { content: string }) =>
  axios.post('/terms', data);

export const deleteTermRequest = (id: number) => axios.delete(`/terms/${id}`);

export const changeTermOrder = (data: { order: number[] }) => {
  return axios.post('/terms/change-order', data);
};
