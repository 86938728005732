import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Col, Container, Input, Row, Table } from 'reactstrap';
import Th from '@/components/Th';
import PaginationComponent from '@/components/Paginate';
import { successAlert, errorAlert } from '@/helpers/alerts';
import Modal from '@/components/Modal';
import useBusinesses from '@/hooks/useBusinesses';
import Loader from '@/components/Loader';
import BusinessForm from '@/components/BusinessForm';

const AllBusinessesPage = () => {
  const {
    loading,
    data,
    sort,
    page,
    order,
    search,
    changeSort,
    changePerPage,
    perPage,
    total,
    changePage,
    changeSearch,
    createBusiness,
  } = useBusinesses();
  const [business, setBusiness] = useState<any>(null);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    createBusiness(values, function (res: any, err: any) {
      if (!err) {
        successAlert('Compania a fost adaugata cu success');
        setBusiness(null);
      } else {
        errorAlert(err.response.data.message);
      }
      setSubmitting(false);
    });
  };

  const renderRows = () =>
    data.map((business: any, index: number) => (
      <tr key={`${index}-category`}>
        <td>{business.id}</td>
        <td>{business.name}</td>
        <td style={{ width: '40px' }}>
          {business.vat ? 'RO' : ''}
          {business.cif}
        </td>
        <td>{business.reg_com}</td>
        <td>{business.address}</td>
        <td style={{ width: '40px' }}>{business.vat ? 'Da' : 'Nu'}</td>

        <td className="d-flex justify-content-end">
          <button className="btn btn-sm btn-primary">Modifica</button>
        </td>
      </tr>
    ));

  return (
    <React.Fragment>
      <Helmet>
        <title>Companii - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Companii" />
          <div className="card">
            <div className="card-body">
              <div className="card-title">Companii</div>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <button
                      onClick={() => setBusiness({})}
                      className="btn btn-primary"
                    >
                      Adauga
                    </button>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      value={search}
                      placeholder="Cauta..."
                      onChange={(e) => changeSearch(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <Th
                      prop="id"
                      order={order}
                      width="40px"
                      sort={sort}
                      onClick={changeSort}
                    >
                      #
                    </Th>

                    <Th
                      prop="name"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Nume
                    </Th>
                    <Th
                      prop="cif"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      CUI
                    </Th>
                    <Th
                      prop="reg_com"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Reg. Com.
                    </Th>
                    <Th
                      prop="address"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Adresa
                    </Th>
                    <Th
                      prop="vat"
                      order={order}
                      width="40px"
                      sort={sort}
                      onClick={changeSort}
                    >
                      TVA
                    </Th>

                    <th className="d-flex justify-content-end">Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={7}>
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  )}
                  {!loading && data.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={7}>
                        Nu exista date
                      </td>
                    </tr>
                  )}
                  {renderRows()}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center align-items-center p-3">
              <PaginationComponent
                page={page}
                perPage={perPage}
                total={total}
                onClick={(p) => changePage(p)}
              />
              <div
                className={`form-group d-flex align-items-center ${
                  total < 11 ? 'd-none' : ''
                }`}
              >
                <select
                  className="form-control"
                  id="perPage"
                  style={{ width: '40px' }}
                  value={perPage}
                  onChange={(e) => changePerPage(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
                {'   '} - pe pagina din {total}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {business && (
        <Modal
          title="Adauga Companie"
          open={true}
          toggle={() => setBusiness(null)}
        >
          <BusinessForm handleSubmit={handleSubmit} business={business} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default AllBusinessesPage;
