import axios from './axios';

export const getPointsReport = async (params: any = {}) => {
  return await axios.get('/reports/points', { params });
};

export const getUsersReport = async (params: any = {}) => {
  return await axios.get('/reports/users', { params });
};

export const getProductsReports = async (params: any = {}) => {
  return await axios.get('/reports/products', { params });
};

export const getLocationsReports = async (params: any = {}) => {
  return await axios.get('/reports/locations', { params });
};

export const getLocationsCategoriesReports = async (params: any = {}) => {
  return await axios.get('/reports/locations-categories', { params });
};

export const getSalesReport = async (params: any = {}) => {
  return await axios.get('/reports/sales', { params });
};
