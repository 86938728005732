import { FC } from 'react';

interface ILoaderProps {
  loading: boolean;
  fullHeight?: boolean;
}
const Loader: FC<ILoaderProps> = ({ loading, fullHeight }) => {
  if (!loading) return null;
  return (
    <div
      style={
        fullHeight
          ? { height: '100vh', alignItems: 'center', paddingBottom: 200 }
          : {}
      }
      className="d-flex justify-content-center"
    >
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
