import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import { getCalendar } from '@/api/marketing';
import { NavLink } from 'react-router-dom';

const categories = [
  {
    id: 1,
    title: 'Planifica un Mail',
    type: 'bg-success',
    link: '/app/marketing/email',
  },
  {
    id: 2,
    title: 'Planifica un SMS',
    type: 'bg-info',
    link: '/app/marketing/sms',
  },
  {
    id: 3,
    title: 'Planifica o Notificare',
    type: 'bg-warning',
    link: '/app/marketing/push',
  },
];

// const events: any = [
//   // {
//   //   id: 1,
//   //   title: "Sfantul Nicolae - mail",
//   //   start: new Date().setDate(new Date().getDate() + 1),
//   //   className: "bg-success text-white",
//   // },
//   // {
//   //   id: 2,
//   //   title: "SF. Andrei - mail",
//   //   start: new Date().setDate(new Date().getDate() + 3),
//   //   className: "bg-success text-white",
//   // },
//   // {
//   //   id: 3,
//   //   title: "SMS - Promotional",
//   //   start: new Date().setDate(new Date().getDate() + 8),
//   //   className: "bg-info text-white",
//   // },
//   // {
//   //   id: 4,
//   //   title: "SMS - Promotional",
//   //   start: new Date().setDate(new Date().getDate() + 7),
//   //   className: "bg-primary text-white",
//   // },
// ];

const MarketingCalendarPage = () => {
  const [events, setEvents] = React.useState<any>([]);

  useEffect(() => {
    getCalendar().then((res) => {
      setEvents(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Planificare Marketing - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              { title: 'Marketing', url: '/app/marketing' },
              {
                title: 'Planificare Marketing',
                url: '/app/marketing/calendar',
              },
            ]}
            breadcrumbItem="Planificare Marketing"
          />
          <div className="card">
            <div className="card-body">
              <div className="card-title">Marketing - {brand.shortName}</div>
              <Row className="mb-4">
                <Col xl={3}>
                  <Card className="h-100" style={{ background: '#f5f5f5' }}>
                    <CardBody>
                      <NavLink
                        color="primary"
                        className="btn font-16 btn-primary waves-effect waves-light w-100"
                        to="/app/marketing"
                      >
                        Planifica Notificare
                      </NavLink>
                      <div id="external-events">
                        <br />
                        <p className="text-muted">
                          Alege tipul de eveniment pe care vrei sa il creezi
                        </p>
                        {categories &&
                          categories.map((category: any, i: number) => (
                            <NavLink
                              to={category.link}
                              key={'cat-' + category.id}
                              // draggable
                              // onDrag={(event) => onDrag(event, category)}
                            >
                              <div
                                className={`${category.type} external-event fc-event text-white`}
                              >
                                <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                {category.title}
                              </div>
                            </NavLink>
                          ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  <Card className="mb-0" style={{ background: '#f5f5f5' }}>
                    <CardBody>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={'00:15:00'}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        initialView="dayGridMonth"
                        headerToolbar={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'dayGridMonth,dayGridWeek,dayGridDay',
                        }}
                        events={events}
                        editable={false}
                        droppable={false}
                        selectable={true}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketingCalendarPage;
