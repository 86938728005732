import { ISale } from '@/types/reports';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface UserReportFilters {
  startDate: string;
  endDate: string;
  min_points?: number | string;
  max_points?: number | string;
  min_age?: number | string;
  max_age?: number | string;
  sex?: string;
  account_type?: number | string;
  location_id?: number | string;
  user_id?: number;
}

interface ISalesState {
  loading: boolean;
  filters: UserReportFilters;
  data: ISale[];
  page: number;
  perPage: number;
  total: number;
  sort: string;
  order: 'asc' | 'desc';
}

const initialState: ISalesState = {
  loading: true,
  filters: {
    startDate: dayjs().subtract(12, 'months').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    min_points: '',
    max_points: '',
    min_age: '',
    max_age: '',
    account_type: '',
  },
  page: 1,
  perPage: 30,
  total: 0,
  sort: 'id',
  order: 'desc',
  data: [],
};

const usersReports = createSlice({
  name: 'gesp/reports/users',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.loading = false;
      state.total = action.payload.total;
      state.perPage = action.payload.per_page;
      state.page = action.payload.current_page;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload.sort;
      state.order = action.payload.order;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setData,
  setFilters,
  setOrder,
  setPerPage,
  setPage,
  setSort,
  setLoading,
} = usersReports.actions;

export default usersReports.reducer;
