import React from 'react';
import Modal from '@/components/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikField from '@/components/FormikField';

interface IModalGProps {
  open: boolean;
  onToggle: () => void;
  handleSubmit: (values: any) => void;
}

const initialValues = {
  title: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Campul titlu este obligatoriu')
    .min(5, 'Titlul trebuie sa aiba minim 5 caractere'),
  message: Yup.string()
    .required('Campul mesage este obligatoriu')
    .min(5, 'Mesajul trebuie sa aiba minim 5 caractere'),
});

const NotificationModal: React.FC<IModalGProps> = ({
  open,
  onToggle,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal title="Trimite Notificare" open={open} toggle={() => onToggle()}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <FormikField
              formik={formik}
              name="title"
              type="text"
              label="Titlu"
            />

            <FormikField
              formik={formik}
              name="message"
              label="Continut Notificare"
              type="textarea"
            />
          </div>
          <div className="col-md-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary py-1 mt-4">
              Trimite
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NotificationModal;
