import React, { useEffect, useState } from 'react';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import { createTheme, getThemes, updateTheme, viewTheme } from '@/api/appTheme';
import Swal from 'sweetalert2';
import CreateForm from './components/CreateForm';
import { useNavigate, useParams } from 'react-router-dom';

const initialValues = {
  name: '',
  colors: {
    primary: '#0095DA',
    onPrimary: '#FFFFFF',
    secondary: '#FFDB00',
    onSecondary: '#000000',
    background: '#FFFFFF',
    surface: '#0095DA',
  },
  others: {
    card_puncte: {
      start: '#0095DA',
      end: '#19A7CE',
    },
    icon_oferte: {
      start: '#FC9000',
      end: '#FFCC00',
    },
    icon_magazine: {
      start: '#0095DA',
      end: '#19A7CE',
    },
    castiga_puncte: {
      start: '#FFB75E',
      end: '#ED8F03',
      titleColor: '#0095DA',
      subtitleColor: '#000',
      iconColor: '#ffffff',
    },
    masculin: {
      avatar: '#19A7CE',
      culoare: '#ffffff',
    },
    feminin: {
      avatar: '#19A7CE',
      culoare: '#ffffff',
    },
    profile: {
      nameColor: '#ffffff',
    },
    headers: {
      start: '#0095DA',
      end: '#19A7CE',
    },
  },
};

const EditThemePage = () => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [theme, setTheme] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      viewTheme(id)
        .then((res) => {
          setTheme(res.data.theme);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    if (id) {
      updateTheme(id, values).then((res) => {
        navigation('/app/theme');
        Swal.fire({
          title: 'Tema a fost modificata!',
          text: 'Tema a fost modificata cu succes!',
        });
      });
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Modifica tema aplicatie - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Modificare tema aplicatie"
          />
          <Card>
            <CardHeader className="bg-primary">
              <CardTitle className="text-white">{theme.name}</CardTitle>
            </CardHeader>
            <CardBody>
              {!loading && (
                <CreateForm initialValues={theme} handleSubmit={handleSubmit} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditThemePage;
