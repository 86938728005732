import React, { useEffect, useState } from 'react';
import CardWidget from '@/components/CardWidget';
import { Row, Col } from 'reactstrap';
import { usersStatistics } from '@/api';
import { AxiosResponse } from 'axios';

interface IUsersSummary {
  totalUsers: number;
  todayRegistred: number;
  lastWeekRegistred: number;
  lastMonthRegistred: number;
}

const UserSummary = () => {
  const [data, setData] = useState<IUsersSummary>({} as IUsersSummary);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      usersStatistics().then((res: AxiosResponse<IUsersSummary>) => {
        setData(res.data);
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Total utilizatori"
            value={`${data.totalUsers} utilizatori`}
            icon="fas fa-users"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="In ultimele 30 zile"
            value={`${data.lastMonthRegistred} utilizatori`}
            icon="fas fa-users"
          />
        </Col>
        <Col xl={3} md={6}>
          <CardWidget
            title="In ultimele 7 zile"
            value={`${data.lastWeekRegistred} utilizatori`}
            icon="fas fa-users"
            loading={loading}
          />
        </Col>
        <Col xl={3} md={6}>
          <CardWidget
            title="In ultimele 24h"
            value={`${data.todayRegistred} utilizatori`}
            icon="fas fa-users"
            loading={loading}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserSummary;
