import React, { useCallback, useRef } from 'react';
import { useFormik } from 'formik';
import { Form, Modal } from 'reactstrap';
import FormikField from '@/components/FormikField';
import DropFile from '@/components/DropFile';
import { addContestPrize, editContestPrize } from '@/api/contest';
import { errorAlert, successAlert } from '@/helpers/alerts';
import { Prize } from '@/types/contests';
import * as Yup from 'yup';

interface ContestPrizeModalProps {
  handleClose: () => void;
  record: Prize | null;
  onSubmit: (data: any) => void;
  contestId: number;
}

const validationRules = Yup.object().shape({
  name: Yup.string().required('Numele premiului este obligatoriu'),
  description: Yup.string().required('Descrierea premiului este obligatorie'),
  quantity: Yup.number()
    .min(1, 'Numarul de produse este obligatoriu')
    .required('Numarul de produse este obligatoriu'),
  position: Yup.number()
    .min(1, 'Poziția este obligatorie')
    .required('Poziția este obligatorie'),
});

const ContestPrizeModal = ({
  handleClose,
  record,
  onSubmit,
  contestId,
}: ContestPrizeModalProps) => {
  const file = useRef<any>(null);

  const handleSubmit = (values: any) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('quantity', values.quantity);
    formData.append('position', values.position);

    if (values.id) {
      formData.append('id', values.id);
    }

    if (file.current) {
      formData.append('image', file.current);
    }
    if (record.id) {
      editContestPrize(contestId, formData)
        .then((res) => {
          if (typeof onSubmit === 'function') {
            onSubmit(res.data.contest);
          }
          successAlert('Premiul a fost modificat cu succes!');
        })
        .catch((err) => {
          errorAlert(err.response.data.message);
        });
    } else {
      addContestPrize(contestId, formData)
        .then((res) => {
          if (typeof onSubmit === 'function') {
            onSubmit(res.data.contest);
          }
          successAlert('Premiul a fost adaugat cu succes!');
        })
        .catch((err) => {
          errorAlert(err.response.data.message);
        });
    }
  };

  const onFileDrop = useCallback((acceptedFiles: any) => {
    file.current = acceptedFiles[0];
  }, []);

  const formik = useFormik({
    initialValues: record
      ? record
      : {
          name: '',
          description: '',
          quantity: 0,
          position: 0,
          id: null,
          image: null,
        },
    validationSchema: validationRules,
    onSubmit: handleSubmit,
  });

  return (
    <Modal isOpen={true} size="md">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Adauga Premiu</h5>
        <button type="button" className="close" onClick={() => handleClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={formik.handleSubmit}>
          <FormikField
            name="name"
            label="Premiu"
            type="text"
            formik={formik}
            placeholder="Premiu"
          />
          <FormikField
            name="description"
            label="Descriere"
            type="textarea"
            formik={formik}
            placeholder="Descriere"
          />
          <FormikField
            name="quantity"
            label="Cantitate"
            formik={formik}
            placeholder="Cantitate"
            type="number"
            min={0}
            max={100}
          />

          <FormikField
            name="position"
            label="Pozitie"
            formik={formik}
            placeholder="Pozitie"
            type="number"
            min={0}
            max={100}
          />

          <DropFile onDropFile={onFileDrop} defaultPreview={''} />

          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-success waves-effect waves-light"
            >
              Salveaza
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ContestPrizeModal;
