import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ICategory } from '@/types/category';
import FormikField from '@/components/FormikField';

interface ICategoryFormProps {
  category: ICategory;
  onSubmit: (category: ICategory, formikProps: any) => void;
}

const CategoryFormSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu'),
  code: Yup.string().required('Codul este obligatoriu'),
});

const CategoryForm: React.FC<ICategoryFormProps> = ({ category, onSubmit }) => {
  const formik = useFormik({
    initialValues: category,
    onSubmit,
    validationSchema: CategoryFormSchema,
  });

  useEffect(() => {
    formik.setValues(category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikField
        label="Nume"
        name="name"
        formik={formik}
        placeholder="Nume"
      />
      <FormikField label="Cod" name="code" formik={formik} placeholder="Cod" />
      <button type="submit" className="btn btn-primary">
        Salveaza
      </button>
    </form>
  );
};

export default CategoryForm;
