import axios from './axios';
import { ILocation } from '@/types/location';

export const getLocationsRequest = (params: any = {}) => {
  return axios.get('/locations', { params });
};

export const getLocationRequest = (uuid: string) => {
  return axios.get(`/locations/${uuid}`);
};

export const updateLocationRequest = (uuid: string, data: ILocation) => {
  return axios.post(`/locations/${uuid}`, data);
};

export const createLocationRequest = (data: ILocation) => {
  return axios.post('/locations', data);
};

export const syncCalendar = () => {
  return axios.post('/locations/sync-calendar');
};
