import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
} from 'reactstrap';
import {
  createTermRequest,
  deleteTermRequest,
  getTermsRequest,
  updateTermRequest,
} from '@/api/terms';
import Loader from '@/components/Loader';
import { errorAlert, successAlert } from '@/helpers/alerts';
import Swal from 'sweetalert2';

interface ITerm {
  id: number;
  content: string;
}

const AllTermsPage = () => {
  const [terms, setTerms] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<ITerm>({} as ITerm);
  const [newItem, setNew] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    getTermsRequest().then((res) => {
      setTerms(res.data.terms);
      setLoading(false);
    });
  }, []);

  const handleSave = () => {
    updateTermRequest(editing.id, editing).then((res) => {
      setTerms(res.data.terms);
      setEditing({} as ITerm);
      successAlert('Articolul a fost salvat cu succes');
    });
  };

  const handleCreate = () => {
    if (newItem === '') return errorAlert('Introduceti un articol');

    createTermRequest({ content: newItem })
      .then((res) => {
        setTerms(res.data.terms);
        setNew('');
        successAlert('Articolul a fost creat cu succes');
      })
      .catch(() => {
        errorAlert('A aparut o eroare');
      });
  };

  const handleCancel = () => {
    setEditing({} as ITerm);
  };

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Articolul va fi sters permanent!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Anuleaza',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTermRequest(id).then((res) => {
          setTerms(res.data.terms);
          successAlert('Articolul a fost sters cu succes');
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Termeni si conditii - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Termeni si conditii"
          />
          <Card>
            <CardHeader className="bg-primary">
              <CardTitle className="text-white">
                Administrare Termeni si conditii {brand.shortName}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading && <Loader loading={loading} />}
              <ul className="list-group">
                {terms.map((term: any) => (
                  <li
                    key={`term-${term.id}`}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div className="mr-3">
                      <i className="fas fa-bars"></i>
                    </div>
                    <div
                      style={{
                        maxWidth: '90%',
                        textAlign: 'left',
                        flexGrow: 1,
                      }}
                      className="mr-3 text-align-left"
                    >
                      {editing.id === term.id ? (
                        <div className="form-group">
                          <Input
                            type="textarea"
                            name="content"
                            id="content"
                            value={editing.content}
                            onChange={(e) =>
                              setEditing({
                                ...editing,
                                content: e.target.value,
                              })
                            }
                          />
                        </div>
                      ) : (
                        term.content
                      )}
                    </div>
                    {editing.id === term.id ? (
                      <div>
                        <Button
                          onClick={handleSave}
                          className="btn-small btn-success m-1"
                        >
                          <i className="fas fa-check"></i>
                        </Button>
                        <Button
                          onClick={handleCancel}
                          className="btn-small btn-danger"
                        >
                          <i className="fas fa-times"></i>
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          onClick={() => setEditing(term)}
                          className="btn-small btn-info m-1"
                        >
                          <i className="fas fa-edit"></i>
                        </Button>
                        <Button
                          onClick={() => handleDelete(term.id)}
                          className="btn-small btn-danger"
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                    )}
                  </li>
                ))}
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className="mr-3">
                    <i className="fas fa-plus"></i>
                  </div>
                  <div
                    style={{
                      maxWidth: '90%',
                      textAlign: 'left',
                      flexGrow: 1,
                    }}
                    className="mr-3 text-align-left"
                  >
                    <div className="form-group">
                      <Input
                        type="textarea"
                        name="content"
                        id="content"
                        value={newItem}
                        onChange={(e) => setNew(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={handleCreate}
                      className="btn-small btn-success m-1"
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  </div>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllTermsPage;
