import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-pattern');
    return () => {
      document.body.classList.remove('bg-overlay');
    };
  }, []);

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};

export default AuthLayout;
