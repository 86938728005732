import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import PushNotification from '@/components/Notifications/PushNotification';
import MailNotification from '@/components/Notifications/MailNotification';
import SmsNotification from '@/components/Notifications/SmsNotification';

const CreateNotificationPage = () => {
  const { type } = useParams();

  const handleSaveNotification = () => {
    console.log('save notification');
  };

  return (
    <React.Fragment>
      <Helmet>
        <title> - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              { title: 'Marketing', url: '/app/marketing' },
              {
                title: `Notificari ${type}`,
                url: `/app/marketing/${type}`,
              },
            ]}
            breadcrumbItem={`Notificare noua - ${type}`}
          />
          {type === 'push' && (
            <PushNotification onSuccess={handleSaveNotification} />
          )}
          {type === 'email' && <MailNotification />}
          {type === 'sms' && <SmsNotification />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNotificationPage;
