import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

const UserGroupList = ({ groups, onEdit, handleDelete, handleCopy }: any) => {
  const RenderActions = (row: any) => {
    return (
      <div className="d-flex">
        <button
          onClick={() => onEdit(row)}
          color="info"
          className="btn text-success"
        >
          <i className="fas fa-edit"></i>
        </button>
        <Link to={`/app/groups/${row.id}`} className="p-2 btn text-info">
          <i className="fas fa-eye"></i>
        </Link>
        <button
          onClick={() => handleDelete(row.id)}
          className={'btn p-2 text-danger cursor-pointer ms-2'}
        >
          <i className="fas fa-trash"></i>
        </button>
        <button
          onClick={() => handleCopy(row.id)}
          className="btn p-2 ms-2 text-warning"
        >
          <i className={'fas fa-copy'}></i>
        </button>
      </div>
    );
  };
  const columns = useMemo(() => {
    return [
      {
        keyField: 'id',
        name: '#',
        width: '50px',
        selector: (row: any, index: any) => {
          return index + 1;
        },
        sortable: false,
      },
      {
        keyField: 'name',
        name: 'Nume Grup',
        selector: (row: any) => row.name,
        sortable: true,
      },
      {
        keyField: 'type',
        name: 'Tip Grup',
        selector: (row: any) => row.type,
        sortable: false,
      },
      {
        keyField: 'criteria',
        name: 'Criterii',
        selector: (row: any) => {
          if (row.criteria?.length) {
            return row.criteria.map((c: any) => {
              return (
                <>
                  <span>
                    {c.attribute} / {c.operator} / {c.value}
                  </span>{' '}
                  <br />
                </>
              );
            });
          }
        },
        sortable: false,
      },
      {
        keyField: 'members',
        name: 'Nr. Membri',
        selector: (row: any) => row?.users?.length,
        sortable: true,
        width: '200px',
      },
      {
        keyField: 'actions',
        name: 'Actiuni',
        selector: RenderActions,
        sortable: false,
        width: '200px',
      },
    ];
  }, []);

  return (
    <div className={'row'}>
      <DataTable
        pagination
        paginationPerPage={30}
        columns={columns}
        data={groups}
        dense
      />
    </div>
  );
};

export default UserGroupList;
