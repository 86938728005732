import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import brand from '@/brand';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import { useParams } from 'react-router-dom';
import NotFoundPage from '@/pages/User/NotFound';
import { IMilestone } from '@/types/loyalty-campaign';
import MilestoneCard from '@/pages/User/LoyaltyCampaigns/components/MilestoneCard';
import { format_date } from '@/helpers/dates';
import MilestoneModal from '@/pages/User/LoyaltyCampaigns/components/MilestoneModal';
import Swal from 'sweetalert2';
import CampaignModal from '@/pages/User/LoyaltyCampaigns/components/CampaignModal';
import DataTable from '@/pages/User/LoyaltyCampaigns/components/DetailsTable';
import DetailsTable from '@/pages/User/LoyaltyCampaigns/components/DetailsTable';

const ViewLoyaltyCampaign = () => {
  const {
    getCampaignDetails,
    deleteMilestoneRequest,
    createMilestone,
    updateMilestone,
    updateCampaign,
  } = useLoyaltyCampaign();
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [displayAddModal, setDisplayAddModal] = useState<boolean>(false);
  const [displayEditModal, setDisplayEditModal] = useState<IMilestone | null>(
    null,
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  useEffect(() => {
    // get the campaign by id
    setLoading(true);
    getCampaignDetails(id)
      .then((res) => setCampaign(res?.campaign))
      .finally(() => setLoading(false));
  }, [id]);

  // display 404 if campaign is not found
  if (!campaign && !loading) {
    return <NotFoundPage />;
  }

  const handleDeleteMilestone = (milestoneId: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Datele vor fi sterse definitiv!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMilestoneRequest(milestoneId).then(() => {
          getCampaignDetails(id).then((res) => setCampaign(res?.campaign));
        });
      }
    });
  };

  const handleEditCampaignAction = async (values: any) => {
    updateCampaign(campaign.id, values).then(() => {
      updateCampaignDetails(id, setShowEditModal, false);
    });
  };

  const showErrorMessage = (
    message = 'Acest prag exista deja. Alege un alt numar de prag!',
  ) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const updateCampaignDetails = (
    id: string,
    setDisplayModal: React.Dispatch<React.SetStateAction<IMilestone | boolean>>,
    modalValue: IMilestone | null | boolean,
  ) => {
    getCampaignDetails(id).then((res) => {
      setCampaign(res?.campaign);
      setDisplayModal(modalValue);
    });
  };

  const handleCreateMilestone = async (values: IMilestone) => {
    const response = await createMilestone(campaign.id, values);
    if (response?.error) {
      showErrorMessage(response.message);
      return response;
    }
    updateCampaignDetails(id, setDisplayAddModal, false);
  };

  const handleEditMilestone = async (values: IMilestone) => {
    const response = await updateMilestone(displayEditModal?.id, values);
    if (response?.error) {
      showErrorMessage(response.message);
      return response;
    }
    updateCampaignDetails(id, setDisplayEditModal, null);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Bonus lunar - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                title: 'Bonus lunar',
                url: '/app/loyalty-campaigns',
              },
            ]}
            breadcrumbItem={campaign?.name}
          />
        </Container>
        <Row>
          <Col md={4}>
            <Card>
              <CardHeader className={'bg-info d-flex justify-content-between'}>
                <CardTitle className={'text-white'}>Detalii</CardTitle>
                <Button
                  size={'sm'}
                  color={'warning'}
                  onClick={() => setShowEditModal(true)}
                >
                  Modifica
                </Button>
              </CardHeader>
              <CardBody>
                <CardText>{campaign?.description}</CardText>
                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>Data de inceput:</strong> <br />{' '}
                      {format_date(campaign?.start_date)}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>Data de sfarsit:</strong> <br />{' '}
                      {format_date(campaign?.end_date)}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <CardHeader className={'bg-secondary'}>
                <Row>
                  <Col>
                    <CardTitle className={'text-white'}>Praguri</CardTitle>
                  </Col>
                  <Col md={2}>
                    {campaign?.milestones?.length < 3 && (
                      <Button
                        size={'sm'}
                        color={'warning'}
                        onClick={() => setDisplayAddModal(true)}
                      >
                        Adauga prag
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {campaign?.milestones?.map((milestone: IMilestone) => (
                    <Col md={4} key={milestone?.id}>
                      <MilestoneCard
                        milestone={milestone}
                        deleteAction={handleDeleteMilestone}
                        editAction={() => setDisplayEditModal(milestone)}
                        disableActions={campaign.status === 'active'}
                      />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!loading && !!campaign && (
              <DetailsTable campaignId={campaign?.id} />
            )}
          </Col>
        </Row>
      </div>
      <MilestoneModal
        isOpen={displayAddModal}
        submitAction={handleCreateMilestone}
        onClose={() => setDisplayAddModal(false)}
      />
      <MilestoneModal
        milestone={displayEditModal}
        isOpen={!!displayEditModal}
        submitAction={handleEditMilestone}
        onClose={() => setDisplayEditModal(null)}
      />
      <CampaignModal
        campaign={campaign}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        submitAction={handleEditCampaignAction}
      />
    </React.Fragment>
  );
};

export default ViewLoyaltyCampaign;
