import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'react-data-table-component';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';

import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getLocationsCategoriesReports } from '@/api/reports';
import { format_currency } from '@/helpers/numbers';
import { downloadCSV } from '@/helpers/csv-utils';
import PaginationComponent from '@/components/Paginate';
import DatesFilter from '@/pages/User/Reports/components/DatesFilter';

const columns = [
  // {
  //   name: 'ID',
  //   selector: (row: any) => row.location_id,
  //   sortable: true,
  // },
  {
    name: 'Categorie',
    label: 'category',
    selector: (row: any) => row.category_name,
  },
  {
    name: 'Magazin',
    selector: (row: any) => row.location_name,
    sortable: true,
  },
  {
    name: 'Cumparaturi medii',
    selector: (row: any) => format_currency(row.avg_amount),
    sortable: true,
  },
  {
    name: 'Minim cumparat',
    selector: (row: any) => format_currency(row.min_amount),
    sortable: true,
  },
  {
    name: 'Maxim cumparat',
    selector: (row: any) => format_currency(row.max_amount),
    sortable: true,
  },
  {
    name: 'Total cumparat',
    selector: (row: any) => format_currency(row.total_amount),
    sortable: true,
  },
  {
    name: 'Total tranzactii',
    selector: (row: any) => row.total_transactions,
    sortable: true,
  },
];

const exportColumns = [
  'location_id',
  'location.name',
  'avg_amount',
  'min_amount',
  'max_amount',
  'total_amount',
  'total_transactions',
];

const ExportButton = ({ onExport }) => (
  <Button color={'warning'} onClick={(e: any) => onExport(e.target?.value)}>
    Export
  </Button>
);
const LocationsCategoriesReportPage = () => {
  const {
    page,
    perPage,
    changePage,
    changePerPage,
    total,
    salesData,
    salesQuery,
    filtersToString,
  } = useSalesReportsHook(getLocationsCategoriesReports);

  useEffect(() => {
    salesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Raport Magazine / Categorii- {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Raport Magazine"
          />
          <DatesFilter />
          <Card>
            <CardHeader className="bg-info d-flex flex-row justify-content-between">
              <CardTitle className="text-white">Rapoarte magazine</CardTitle>
              <ExportButton
                onExport={() =>
                  downloadCSV(salesData, exportColumns, filtersToString())
                }
              />
            </CardHeader>
            <CardBody className="card-body">
              <DataTable columns={columns} data={salesData} dense />

              <div className="d-flex w-100 align-items-center p-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <PaginationComponent
                    page={page}
                    perPage={perPage}
                    total={total}
                    onClick={(p) => changePage(p)}
                  />
                  <div
                    className={`form-group d-flex align-items-center justify-content-between ${
                      total < 11 ? 'd-none' : ''
                    }`}
                  >
                    <span>Afiseaza</span>
                    <select
                      className="form-control m-0 ml-2 mr-2"
                      id="perPage"
                      style={{ width: '50px' }}
                      value={perPage}
                      onChange={(e) => changePerPage(parseInt(e.target.value))}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                    <span>
                      {'   '} - pe pagina din
                      <strong className={'p-1'}>{total}</strong>
                      inregistrari
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LocationsCategoriesReportPage;
