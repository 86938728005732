import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Pusher from 'pusher-js';
import Swal from 'sweetalert2';

const UserLayout = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isConnected = useRef(false);
  useEffect(() => {
    document.body.classList.remove('bg-pattern');
    var pusher = new Pusher('25c16972a81f7505cdba', {
      cluster: 'eu',
      forceTLS: true,
    });
    if (!isConnected.current) {
      isConnected.current = true;
      var channel = pusher.subscribe('purchase');
      pusher.connection.bind('state_change', function (states: any) {
        if (states.current === 'connected') {
          channel.bind('new-purchase', function (data: any) {
            Swal.fire({
              title: 'Achizitie noua!',
              text: data.message,
              icon: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
            });
          });
        }
      });
    }
    return () => {
      document.body.classList.add('bg-pattern');
      try {
        channel.unbind();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar isMobile={isMobile} />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default UserLayout;
