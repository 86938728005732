import React, { useEffect } from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import brand from '@/brand';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import Loader from '@/components/Loader';
import { getSettings, updateSettings } from '@/api/settings';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { successAlert } from '@/helpers/alerts';
import FormikField from '@/components/FormikField';

const validationSchema = Yup.object().shape({
  points_to_ron: Yup.number()
    .max(100, 'Maxim 100 pct per ron')
    .min(1, 'Minim 1 pct per ron')
    .required('Punctele sunt obligatorii'),
  points_reword_rate: Yup.number()
    .min(0, 'Minim 0 reward rate') // @TODO - clarify this on the form
    .max(1, 'Maxim 1 reward rate')
    .required('Procentul este obligatoriu'),
  affiliation_recommendation: Yup.number()
    .min(0, 'Minim 0 puncte per recomandara')
    .max(100, 'Maxim 100 per recomandare')
    .required('Valoarea este obligatorie'),
  birth_date: Yup.number()
    .min(0, 'Minim 0 puncte per zi de nastere')
    .max(100, 'Maxim 100 per zi de nastere')
    .required('Valoarea este obligatorie'),
  social_share: Yup.number()
    .min(0, 'Minim 0 puncte per share')
    .max(100, 'Maxim 100 per share')
    .required('Valoarea este obligatorie'),
  anniversary: Yup.number()
    .min(0, 'Minim 0 puncte per aniversare')
    .max(100, 'Maxim 100 per aniversare')
    .required('Valoarea este obligatorie'),
  maintenance_mode: Yup.boolean().nullable(),
  google_maps_api_key: Yup.string().nullable(
    'Cheia pentru GoogleMaps este obligatorie',
  ),
  sms_advert_api_auth_token: Yup.string().nullable('Tokenul este obligatoriu'),
  open_ro_key: Yup.string().nullable('Cheia pentru OpenRo este obligatorie'),
});

const SettingsPage = () => {
  const [settings, setSettings] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const initialValues = {
    points_to_ron: settings?.points_to_ron || 0,
    points_reword_rate: settings?.points_reword_rate || 0,
    affiliation_recommendation: settings?.affiliation_recommendation || 0,
    birth_date: settings?.birth_date || 0,
    social_share: settings?.social_share || 0,
    social_like: settings?.social_like || 0,
    anniversary: settings?.anniversary || 0,
    google_maps_api_key: settings?.google_maps_api_key || '',
    sms_advert_api_auth_token: settings?.sms_advert_api_auth_token || '',
    open_ro_key: settings?.open_ro_key || '',
    maintenance_mode: settings?.maintenance_mode || 0,
  };

  const handleSubmit = (values: any) => {
    updateSettings(settings.id, values).then((res) => {
      setSettings(res.data);
      successAlert('Setari actualizate');
    });
  };

  useEffect(() => {
    setLoading(true);
    getSettings().then((res) => {
      setSettings(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.resetForm({
      values: initialValues,
    });
  }, [settings]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!formik.values.maintenance_mode) {
      formik.setFieldValue('run_at', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.maintenance_mode]);

  return (
    <React.Fragment>
      <div className={'page-content'}>
        <Container fluid>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                url: '/settings',
                title: 'Setari',
              },
            ]}
            breadcrumbItem="Setari"
          />
          <div>
            {loading && <Loader loading={loading} />}
            {!loading && settings && (
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={5}>
                    <Card>
                      <CardHeader className="bg-info">
                        <CardTitle className="text-white">
                          Setari puncte si recompense
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormikField
                          formik={formik}
                          name="points_to_ron"
                          label="Paritate puncte - ron"
                          type="number"
                          readonly={true}
                          disabled={true}
                          min={1}
                          max={100}
                        />
                        <FormikField
                          formik={formik}
                          name="points_reword_rate"
                          label="Procent recompensa 1 = 100% - 0 = 0%"
                          type="number"
                          readonly={true}
                          disabled={true}
                          step={0.01}
                          min={0}
                          max={1}
                        />
                        <FormikField
                          formik={formik}
                          name="affiliation_recommendation"
                          label="Puncte per recomandare"
                          type="number"
                          min={0}
                          max={100}
                        />
                        <FormikField
                          formik={formik}
                          name="birth_date"
                          label="Puncte per zi de nastere"
                          type="number"
                          min={0}
                          max={100}
                        />

                        <FormikField
                          formik={formik}
                          name="social_like"
                          label="Puncte per like"
                          type="number"
                          min={0}
                          max={100}
                        />
                        <FormikField
                          formik={formik}
                          name="anniversary"
                          label="Puncte per aniversare"
                          type="number"
                          min={0}
                          max={100}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={5}>
                    <Card>
                      <CardHeader className="bg-info">
                        <CardTitle className="text-white">
                          Setari externe
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormikField
                          formik={formik}
                          name="google_maps_api_key"
                          label="Cheie Google Maps"
                          type="text"
                        />
                        <FormikField
                          formik={formik}
                          name="sms_advert_api_auth_token"
                          label="Token SMS Advert"
                          type="text"
                        />
                        <FormikField
                          formik={formik}
                          name="open_ro_key"
                          label="Cheie OpenRo"
                          type="text"
                        />

                        <div
                          className={
                            'pt-4 bg-warning d-flex flex-column align-items-center'
                          }
                        >
                          <FormikField
                            formik={formik}
                            style={{ marginLeft: 10 }}
                            name="maintenance_mode"
                            label="Mod mentenanta"
                            checked={formik.values.maintenance_mode}
                            type="checkbox"
                          />
                          <div className={'alert alert-dark '}>
                            <p>
                              Daca activati modul de mentenanta, aplicatia va fi
                              indisponibila pentru utilizatori.
                            </p>
                          </div>
                        </div>
                        <div className={'mt-4'}>
                          <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            className="btn-info"
                          >
                            Salveaza
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader className={'bg-info'}>
                        <CardTitle className={'text-white'}>
                          Setari recompense
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        {settings.rewards &&
                          settings.rewards.map((reward) => {
                            return (
                              <div key={reward.id} className={'mt-3'}>
                                <label className="form-label">
                                  {reward.title}
                                </label>
                                <Input value={reward.points} disabled={true} />
                              </div>
                            );
                          })}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </form>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingsPage;
