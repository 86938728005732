import React, { useEffect } from 'react';
import CardWidget from '@/components/CardWidget';
import { Row, Col } from 'reactstrap';
import useDashboard from '@/hooks/useDashoard';

const calculatePercentage = (current: number, previous: number) => {
  if (previous === 0) {
    return 0;
  }
  return Math.round(((current - previous) / previous) * 100);
};

const DashboardSummary = () => {
  const { getSummary, summary: data, loadingSummary: loading } = useDashboard();
  useEffect(() => {
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Utilizatori luna curenta"
            value={`${data.users?.current} utilizatori`}
            icon="fas fa-users"
            percentage={calculatePercentage(
              data.users?.current,
              data.users?.past,
            )}
            note="Fata de luna trecuta"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Vanzari luna curenta"
            value={`${data.sales?.current} vanzari`}
            icon="fas fa-shopping-cart"
            percentage={calculatePercentage(
              data.sales?.current,
              data.sales?.past,
            )}
            note="Fata de luna trecuta"
          />
        </Col>
        <Col xl={3} md={6}>
          <CardWidget
            title="Puncte generare luna curenta"
            value={`${data.generatedPoints?.current} puncte`}
            icon="fas fa-map-marker-alt"
            loading={loading}
            percentage={calculatePercentage(
              data.generatedPoints?.current,
              data.generatedPoints?.past,
            )}
            note="Fata de luna trecuta"
          />
        </Col>
        <Col xl={3} md={6}>
          <CardWidget
            title="Puncte consumate luna curenta"
            value={`${data.redeemedPoints?.current} puncte`}
            icon="fas fa-map-marker-alt"
            loading={loading}
            percentage={calculatePercentage(
              data.redeemedPoints?.current,
              data.redeemedPoints?.past,
            )}
            note="Fata de luna trecuta"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DashboardSummary;
