import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ISalesState } from '@/redux/slices/salesReports.slice';

const initialState: ISalesState = {
  loading: true,
  filters: {
    startDate: dayjs().subtract(12, 'months').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    min_points: '',
    max_points: '',
    min_value: '',
    max_value: '',
  },
  page: 1,
  perPage: 30,
  total: 0,
  sort: 'id',
  sortDirection: 'desc',
  salesData: [],
};

const salesSlice = createSlice({
  name: 'gesp/points-report',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.salesData = action.payload.data;
      state.loading = false;
      state.total = action.payload.total;
      state.perPage = action.payload.per_page;
      state.page = action.payload.current_page;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload.sort;
      state.sortDirection = action.payload.order;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.sortDirection = action.payload;
    },
  },
});

export const { setData, setFilters, setSort, setOrder, setPage, setPerPage } =
  salesSlice.actions;

export default salesSlice.reducer;
