import AsyncSelect from 'react-select/async';
import { searchLocations } from '@/api/autocomplete';
import { useEffect, useRef, useState } from 'react';

interface LocationPickerProps {
  onPick: (value: number) => void;
  hideLabel?: boolean;
  selectedLocation: string | number; // or whatever type it actually is
}

const LocationPicker = ({
  onPick,
  hideLabel = false,
  selectedLocation,
}: LocationPickerProps) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const handleChange = (value: any) => {
    onPick(value?.value);
    setSelectedOption(value);
  };

  useEffect(() => {
    if (!selectedLocation) {
      setSelectedOption(null);
    }
  }, [selectedLocation]);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []); // add this line

  return (
    <div className="form-group">
      {!hideLabel && <label htmlFor="locatie">Magazin</label>}
      <AsyncSelect
        id="locatie"
        cacheOptions
        defaultOptions
        isClearable
        loadOptions={(inputValue: any, callback: any) => {
          if (inputValue && timeout.current) {
            clearTimeout(timeout.current);
          }
          timeout.current = setTimeout(() => {
            searchLocations({ search: inputValue }).then((response: any) => {
              callback(response.data);
            });
          }, 200);
        }}
        isLoading={false}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
};

export default LocationPicker;
