import dayjs from 'dayjs';
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

const PreviewNotification = ({ notification }: any) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Card>
            <CardHeader className="bg-info">
              <CardTitle className="text-white">Detalii Notificare</CardTitle>
            </CardHeader>
            <CardBody>
              <p>
                <b>Denumire:</b> {notification.label}
              </p>
              <p>
                <b>Tip:</b> {notification.type}
              </p>
              <p>
                <b>Status:</b> {notification.status}
              </p>
              <p>
                <b>Programata:</b> {!!notification.run_at ? 'Da' : 'Nu'}
              </p>
              {notification.run_at && (
                <p>
                  <b>Data:</b>{' '}
                  {dayjs(notification.run_at).format('DD/MM/YYYY HH:mm')}
                </p>
              )}
              <div>
                <h5 className="border-bottom border-3 mb-2">Primit de</h5>
                {notification.notifications &&
                  notification.notifications
                    .filter((entry) => entry.user !== null)
                    .map((notif: any) => (
                      <div
                        className="d-flex justify-content-between font-size-14"
                        key={notif.id}
                      >
                        <span>
                          <i className="fa fa-user mr-2" />{' '}
                          <b>
                            {notif.user?.first_name} {notif.user?.last_name}
                          </b>{' '}
                          -{' '}
                          {notification.type === 'sms'
                            ? notif.user?.phone_no
                            : notif.user?.email}
                        </span>
                        <i className="fa fa-check-circle text-success ml-2 " />
                      </div>
                    ))}
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <CardHeader className="bg-info">
              <CardTitle className="text-white">Notificare SMS</CardTitle>
            </CardHeader>
            <CardBody>
              <p>
                <b>Denumire:</b> {notification.label}
              </p>
              {notification.type !== 'sms' && (
                <p>
                  <b>Titlu:</b> {notification.title}
                </p>
              )}
              {['sms', 'push'].includes(notification.type) ? (
                <p>
                  <b>Mesaj:</b> {notification.message}
                </p>
              ) : (
                <div>
                  <p>Mesaj</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PreviewNotification;
