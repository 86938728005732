import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface IDropFileProps {
  onDropFile: (acceptedFiles: File[]) => void;
  defaultPreview?: string;
}

const DropFile: React.FC<IDropFileProps> = ({ onDropFile, defaultPreview }) => {
  const [preview, setPreview] = useState<string | undefined>(defaultPreview);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]; // Get the first file

      onDropFile(acceptedFiles);

      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result as string);
      reader.readAsDataURL(file);
    },
    [onDropFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <React.Fragment>
      {preview && (
        <div className="text-center">
          <img src={preview} alt="Preview" className="img-fluid rounded" />
        </div>
      )}
      <div
        {...getRootProps()}
        className="border border-3 border-dashed rounded text-center p-4 my-2"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="mb-0">Eliberati imagina aici...</p>
        ) : (
          <p className="mb-0">
            Trageti si lasati imaginea aici, sau click pentru a selecta imaginea
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default DropFile;
