import { Routes, Route } from 'react-router-dom';
import Login from './Auth/Login';
import AuthLayout from './Auth/Layout';
import UserLayout from './User/Layout/index';
import Dashboard from './User/Dashboard';
import Marketing from './User/Marketing';
import { AuthProtected } from '@/components/AuthProtected';
import AllUsersPage from './User/Users/All';
import AllLocationsPage from './User/Locations/All';
import AllCategoriesPage from './User/Categories/All';
import ShowUserPage from './User/Users/Show';
import NotFoundPage from './User/NotFound';
import MarketingCalendarPage from './User/Marketing/Calendar/index';
import AllProductsPage from './User/Products/All';
import ProductViewPage from './User/Products/View';
import PointsReportPage from './User/Reports/Points';
import ViewLocationPage from './User/Locations/View';
import CreateLocationPage from './User/Locations/Create';
import AllNotificationsPage from './User/Notifications/All';
import AppContentPage from './User/AppContent/All';
import AllTermsPage from './User/Terms/All';
import MarketingPage from './User/Marketing/All';
import CreateNotificationPage from './User/Marketing/Create';
import UsersReportPage from './User/Reports/Users';
import DiscountPage from './User/Discount';
import ViewAppContentPage from './User/AppContent/View';
import FaqPage from './User/Faq/All';
import AllBusinessesPage from './User/Businesses/All';
import SalesReportPage from './User/Reports/Sales';
import EditNotificationPage from './User/Marketing/Edit';
import ThemePage from './User/Theme';
import CreateThemePage from './User/Theme/create';
import EditThemePage from './User/Theme/view';
import TextsPage from './User/TextApp';
import ContestPage from './User/Contest';
import CreateContestPage from './User/Contest/create';
import ViewContestPage from './User/Contest/view';
import ContestInfo from './User/Contest/ContestInfo';
import AllGroupsPage from './User/Groups/All';
import GroupPreviewPage from './User/Groups/Preview';
import ViewDetails from '@/pages/User/Marketing/ViewDetails';
import SettingsPage from '@/pages/User/Settings';
import LocationUsers from 'src/pages/User/Reports/LocationUsers';
import LoyaltyCampaignsPage from '@/pages/User/LoyaltyCampaigns';
import AddLoyaltyCampaign from '@/pages/User/LoyaltyCampaigns/AddLoyaltyCampaing';
import ViewLoyaltyCampaign from '@/pages/User/LoyaltyCampaigns/components/ViewLoyaltyCampaign';
import LocationsReportPage from '@/pages/User/Reports/Locations';
import LocationsCategoriesReportPage from '@/pages/User/Reports/LocationCategories';

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route path="/" index element={<Login />} />
      </Route>
      <Route
        path="/app"
        element={
          <AuthProtected>
            <UserLayout />
          </AuthProtected>
        }
      >
        <Route path="/app" index element={<Dashboard />} />
        <Route path="/app/marketing" element={<Marketing />} />
        <Route path="/app/users" element={<AllUsersPage />} />
        <Route path="/app/users/:uuid" element={<ShowUserPage />} />

        <Route path="/app/companies" element={<AllBusinessesPage />} />

        <Route path="/app/locations" element={<AllLocationsPage />} />
        <Route path="/app/locations/new" element={<CreateLocationPage />} />
        <Route path="/app/locations/:uuid" element={<ViewLocationPage />} />

        <Route path="/app/categories" element={<AllCategoriesPage />} />

        <Route path="/app/products" element={<AllProductsPage />} />
        <Route path="/app/products/:id" element={<ProductViewPage />} />

        <Route path="/app/contents" element={<AppContentPage />} />
        <Route path="/app/contents/:id" element={<ViewAppContentPage />} />
        <Route
          path="/app/marketing/calendar"
          element={<MarketingCalendarPage />}
        />
        <Route path="/app/marketing" element={<MarketingPage />} />

        <Route
          path="/app/marketing/:type/:id"
          element={<EditNotificationPage />}
        />
        <Route path="/app/marketing/view-log/:id" element={<ViewDetails />} />
        <Route path="/app/theme" element={<ThemePage />} />
        <Route path="/app/theme/new" element={<CreateThemePage />} />
        <Route path="/app/theme/edit/:id" element={<EditThemePage />} />
        <Route
          path="/app/marketing/:type"
          element={<CreateNotificationPage />}
        />

        <Route path="/app/discounts" element={<DiscountPage />} />

        <Route path="/app/reports/points" element={<PointsReportPage />} />
        <Route path="/app/reports/users" element={<UsersReportPage />} />
        <Route path="/app/reports/sales" element={<SalesReportPage />} />

        <Route path="/app/contest/:id/info" element={<ContestInfo />} />
        <Route path="/app/contest/:id" element={<ViewContestPage />} />
        <Route path="/app/contest" element={<ContestPage />} />
        <Route path="/app/contest/create" element={<CreateContestPage />} />

        <Route path="/app/app-text" element={<TextsPage />} />

        <Route
          path={'/app/loyalty-campaigns'}
          element={<LoyaltyCampaignsPage />}
        />
        <Route
          path={'/app/loyalty-campaigns/add'}
          element={<AddLoyaltyCampaign />}
        />
        <Route
          path={'/app/loyalty-campaigns/:id'}
          element={<ViewLoyaltyCampaign />}
        />

        <Route path="/app/faq" element={<FaqPage />} />

        <Route path="/app/groups" element={<AllGroupsPage />} />
        <Route path="/app/groups/:id" element={<GroupPreviewPage />} />

        <Route path={'/app/location-users'} element={<LocationUsers />} />
        <Route
          path="/app/reports/locations"
          element={<LocationsReportPage />}
        />
        <Route
          path="/app/reports/location-categories"
          element={<LocationsCategoriesReportPage />}
        />

        <Route
          path="/app/notification-templates"
          element={<AllNotificationsPage />}
        />

        <Route path="/app/terms" element={<AllTermsPage />} />
        <Route path="/app/settings" element={<SettingsPage />} />

        <Route path="/app/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
