import React from 'react';
import { removeContestPrize } from '@/api/contest';
import Swal from 'sweetalert2';

const ContestPrizes = ({ prizes, onChange, setPrize }: any) => {
  const handlePrizeDelete = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Stergerea premiului este ireversibila!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.value) {
        removeContestPrize(id)
          .then(() => {
            Swal.fire(
              'Stergere premiu!',
              'Premiul a fost sters cu succes.',
              'success',
            );
            onChange();
          })
          .catch((err) => {
            Swal.fire(
              'Eroare!',
              'A aparut o eroare la stergerea premiului.',
              'error',
            );
          });
      }
    });
  };
  const renderPrizes = () => {
    return prizes.map((prize: any, index: number) => (
      <tr key={`prize-${index}`}>
        <td>{index + 1}</td>
        <td>
          <img
            src={prize.image}
            alt={prize.name}
            className="img-fluid"
            style={{ maxWidth: '100px' }}
          />
        </td>
        <td>{prize.name}</td>
        <td>{prize.description}</td>
        <td>x{prize.quantity}</td>
        <td>{prize.position}</td>
        <td>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setPrize(prize)}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            className="btn btn-sm btn-danger ml-2"
            onClick={() => handlePrizeDelete(prize.id)}
          >
            <i className="fa fa-trash" />
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Imagine</th>
              <th>Premiu</th>
              <th>Descriere</th>
              <th>Cantitate</th>
              <th>Pozitie</th>
              <th>Actiuni</th>
            </tr>
          </thead>
          <tbody>
            {prizes.length > 0 ? (
              renderPrizes()
            ) : (
              <tr>
                <td colSpan={6}>Nu exista premii</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default ContestPrizes;
