import React from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { FormikProps } from 'formik';
import { format_date } from '@/helpers/dates';

interface FormikFieldProps {
  formik: FormikProps<any>;
  name: string;
  label: string;
  multiline?: boolean;
  [x: string]: any;
}

const FormikField: React.FC<FormikFieldProps> = ({
  formik,
  name,
  type,
  label,
  multiline,
  ...other
}) => {
  const { handleChange, handleBlur, errors } = formik;
  const isError = Boolean(errors[name]);
  let fieldValue = formik.values[name];

  if (type === 'date') {
    fieldValue = format_date(fieldValue, 'YYYY-MM-DD');
  }

  if (type === 'checkbox') {
    return (
      <FormGroup>
        <Input
          id={name}
          name={name}
          type={type}
          value={formik.values[name] || ''}
          // defaultValue={formik.values[name] || 0}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isError}
          {...other}
        />
        <Label htmlFor={name} className={'ms-1'}>
          {' '}
          {label}
        </Label>
        {isError && <FormFeedback>{errors[name] + ''}</FormFeedback>}
      </FormGroup>
    );
  }

  if (multiline) {
    return (
      <FormGroup>
        <Label htmlFor={name}>{label}</Label>
        <textarea
          onChange={handleChange}
          onBlur={handleBlur}
          id={name}
          name={name}
          className="form-control"
          defaultValue={fieldValue || ''}
          // defaultValue={formik.values[name] || ''}
        ></textarea>
        {isError && <FormFeedback>{errors[name] + ''}</FormFeedback>}
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Input
        id={name}
        name={name}
        type={type}
        value={fieldValue || ''}
        defaultValue={fieldValue || 0}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={isError}
        {...other}
      />
      {isError && <FormFeedback>{errors[name] + ''}</FormFeedback>}
    </FormGroup>
  );
};

export default FormikField;
