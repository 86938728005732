import { useEffect, useCallback, useRef } from 'react';
import {
  setPage,
  setSort,
  setUsers,
  setPerPage,
  setSearch,
  setFilters,
} from '@/redux/slices/users.slice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getUsersRequest } from '@/api';

const useUsers = () => {
  const dispatch = useAppDispatch();
  const timer = useRef<any>(null);
  const data = useAppSelector((state) => state.users);

  const getUsers = useCallback((params: any) => getUsersRequest(params), []);

  const userQuery = (others: any = {}) => {
    return getUsers({ ...getFilters(), ...others }).then((res) =>
      dispatch(setUsers(res.data.users)),
    );
  };

  const getFilters = () => {
    return {
      search: data.search,
      filters: data.filters,
      page: data.page,
      sort: data.sort,
      order: data.order,
      perPage: data.perPage,
    };
  };

  useEffect(() => {
    userQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSort = (sort: string) => {
    const order =
      data.sort === sort ? (data.order === 'asc' ? 'desc' : 'asc') : 'asc';
    dispatch(
      setSort({
        sort,
        order,
      }),
    );
    userQuery({ sort, order });
  };

  const changePage = (page: number) => {
    dispatch(setPage(page));
    userQuery({ page });
  };

  const changePerPage = (perPage: number) => {
    dispatch(setPerPage(perPage));
    userQuery({ perPage, page: 1 });
  };

  const changeFilters = (key: string, value: string) => {
    const newFilters = { ...data.filters, [key]: value };
    dispatch(setFilters(newFilters));

    userQuery({ filters: newFilters, page: 1 });
  };
  // this should be debounced
  const changeSearch = (search: string) => {
    dispatch(setSearch(search));
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      userQuery({ search, page: 1 });
    }, 500);
  };

  return {
    ...data,
    changeSort,
    getUsers,
    changePage,
    changePerPage,
    changeSearch,
    changeFilters,
  };
};

export default useUsers;
