import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { removeUserBusiness, updateOrCreateUserBusiness } from '@/api/users';
import { errorAlert, successAlert } from '@/helpers/alerts';
import BusinessForm from '@/components/BusinessForm';

interface IUsersBusinessFormProps {
  user: any;
  business: any;
  setBusiness: (business: any) => void;
}

const UsersBusinessForm = ({
  user,
  business,
  setBusiness,
}: IUsersBusinessFormProps) => {
  const handleSubmit = (values: any, { setSubmitting }: any) => {
    updateOrCreateUserBusiness(user.uuid, values)
      .then((res) => {
        setBusiness(res.data.business);
        successAlert('Datele au fost actualizate cu succes');
      })
      .catch((e) => {
        errorAlert(e.response.data.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRemove = () => {
    removeUserBusiness(user.uuid)
      .then(() => {
        successAlert('Utilizatorul nu mai este asociat acestei companii');
        setBusiness(null);
      })
      .catch(() => {
        errorAlert('Ceva nu a mers! incearca mai tarziu');
      });
  };

  const renderBusiness = () => {
    if (business) {
      return (
        <BusinessForm
          business={business}
          onRemove={handleRemove}
          handleSubmit={handleSubmit}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Date Firma</h4>
          {business && renderBusiness()}
          {!business && (
            <div className="alert alert-warning" role="alert">
              <p className="text-muted">
                Nu exista nicio firma asociata acestui utilizator
              </p>
              <Button color="primary" onClick={() => setBusiness({})}>
                Adauga o firma
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UsersBusinessForm;
