import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import FormikField from '@/components/FormikField';
import NotificationFilters from '../NotificationFilters';
import Swal from 'sweetalert2';
import { saveMarketingData } from '@/api/marketing';
import { useNavigate } from 'react-router-dom';

const customFields = ['{{first_name}}', '{{last_name}}', '{{points}}'];

const PushNotification = ({ onSuccess }: any) => {
  let navigate = useNavigate();
  const [filters, setOnFilterChange] = React.useState<any>({
    type: 'all',
  });
  const setFilters = (filter: any) => {
    setOnFilterChange({ ...filters, ...filter });
  };

  const createNotification = (
    values: any,
    { setFieldError, setSubmitting }: any,
  ) => {
    saveMarketingData(values)
      .then((res) => {
        Swal.fire({
          title: 'Succes!',
          text: 'Notificarea a fost salvata cu succes!',
          icon: 'success',
        });
        navigate('/app/marketing');
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          for (const key in err.response.data.errors) {
            setFieldError(key, err.response.data.errors[key][0]);
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const onSubmit = useCallback(
    (values: any, formikProps: any) => {
      const request = {
        ...values,
        rules: filters,
      };
      if (!values.scheduled && values.status !== 'DRAFT') {
        Swal.fire({
          title: 'Esti sigur?',
          text: 'Notificarea va fi trimisa tuturor utilizatorilor care se incadreaza in filtrele selectate!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Da, trimite!',
          cancelButtonText: 'Nu, renunta!',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            createNotification(request, formikProps);
          }
        });
      } else {
        createNotification(request, formikProps);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const formik = useFormik({
    initialValues: {
      label: '',
      title: '',
      subject: '',
      message: '',
      type: 'push',
      status: 'DRAFT',
      scheduled: false,
    },
    onSubmit,
  });

  useEffect(() => {
    if (!formik.values.scheduled) {
      formik.setFieldValue('run_at', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.scheduled]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle className="text-white">Notificare Push</CardTitle>
        </CardHeader>
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={3}>
                <NotificationFilters
                  filters={filters}
                  setFilters={setFilters}
                  resetFilters={() => setOnFilterChange({ type: 'all' })}
                  type="push"
                />
              </Col>
              <Col md={9}>
                <FormikField
                  label="Denumire *"
                  required
                  name="label"
                  formik={formik}
                  placeholder="Denumire"
                />
                <FormikField
                  label="Titlu *"
                  name="title"
                  required
                  formik={formik}
                  placeholder="Titlu"
                />

                <FormikField
                  label="Mesaj *"
                  name="message"
                  required
                  formik={formik}
                  placeholder="Mesaj"
                  type="textarea"
                  rows={4}
                />
                <p>
                  Text inlocuit dinamic cu informatii utilizator:{' '}
                  {customFields.map((field) => ` ${field} `)}{' '}
                </p>
                <div className="mt-4">
                  <FormikField
                    label="Notificare Programata"
                    name="scheduled"
                    formik={formik}
                    checked={formik.values.scheduled}
                    type="checkbox"
                  />
                </div>

                {formik.values.scheduled && (
                  <FormikField
                    label="Data trimitere *"
                    name="run_at"
                    required
                    formik={formik}
                    placeholder="Data trimitere"
                    type="datetime-local"
                  />
                )}
                <div className="form-group">
                  <FormikField
                    label="Status"
                    name="status"
                    formik={formik}
                    placeholder="Status"
                    type="select"
                  >
                    <option value="DRAFT">Ciorna</option>
                    {formik.values.scheduled ? (
                      <option value="SCHEDULED">Programat</option>
                    ) : (
                      <option value="PENDING">Trimite</option>
                    )}
                  </FormikField>
                </div>
              </Col>
              <Col md={12} className="d-flex justify-content-end">
                <div>
                  <Button type="submit" className="btn-info">
                    Salveaza
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PushNotification;
