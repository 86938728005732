import axios from './axios';

export const index = () => {
  return axios.get('/faq');
};

export const show = (id: number) => {
  return axios.get(`/faq/${id}`);
};

export const store = (params: any = {}) => {
  return axios.post('/faq', params);
};

export const update = (id: number, params: any = {}) => {
  return axios.put(`/faq/${id}`, params);
};

export const destroy = (id: number) => {
  return axios.delete(`/faq/${id}`);
};

export const sort = (params: any = {}) => {
  return axios.post('/faq/sort', params);
};
