import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../../components/Breadcrumbs';
import brand from '../../../brand';
import DashboardSummary from './components/Summary';
import Overview from './components/Overview';
import ProductsOverview from './components/ProductsOverview';
import CategoriesOverview from './components/CategoriesOverview';
import PointsOverview from './components/PointsOverview';
import LatestUsers from './components/LatestUsers';
import LatestTransactions from './components/LatestTransactions';
import SuspectActivity from './components/SuspectActivity';

const Dashboard = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="page-content">
        <Breadcrumbs
          title={brand.shortName}
          bread={[]}
          breadcrumbItem="Dashboard"
        />
        <Container fluid={true}>
          <DashboardSummary />
          <Row>
            <Col lg={9}>
              <Overview />
            </Col>
            <Col lg={3}>
              <PointsOverview />
            </Col>
            <Col lg={6}>
              <ProductsOverview />
            </Col>
            <Col lg={6}>
              <CategoriesOverview />
            </Col>
            <Col lg={6}>
              <LatestUsers />
            </Col>
            <Col lg={6}>
              <SuspectActivity />
              <LatestTransactions />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
