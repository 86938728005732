import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../types/user';

interface UserState {
  token: string | null;
  user: IUser | null;
  isAuthenticated: boolean;
}

const initialState: UserState = {
  token: localStorage.getItem('token'),
  user: null,
  isAuthenticated: !!localStorage.getItem('token'),
};

export const authSlice = createSlice({
  name: 'gesp/auth',
  initialState,
  reducers: {
    authenticate: (
      state,
      action: PayloadAction<{ token: string | null | undefined; user: IUser }>,
    ) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      if (action.payload.token) {
        state.token = action.payload.token;
        localStorage.setItem('token', action.payload.token);
      }
    },
  },
});

export const { authenticate } = authSlice.actions;

export default authSlice.reducer;
