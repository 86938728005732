import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';
import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getLocationsReports } from '@/api/reports';

const LocationsFilter = () => {
  const { changeFilter, resetFilters, filters, applyFilters, loading } =
    useSalesReportsHook(getLocationsReports);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="card-primary">
          <CardTitle tag="h4">
            <i className="mdi mdi-filter" /> Filtre
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="data">De la</label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => changeFilter('startDate', e.target.value)}
                  className="form-control"
                  id="data"
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="end_date">Pana la</label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => changeFilter('endDate', e.target.value)}
                  className="form-control"
                  id="end_date"
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="is_business">Firma/PF</label>
                <select
                  className="form-control"
                  id="is_business"
                  value={filters.account_type}
                  defaultValue={filters.account_type}
                  onChange={(e) => changeFilter('account_type', e.target.value)}
                >
                  <option value="">Toate</option>
                  <option value="0">Persoana Fizica</option>
                  <option value="1">Persoana Juridica</option>
                </select>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="sex">Sex</label>
                <Input
                  type="select"
                  name="sex"
                  value={filters.sex}
                  onChange={(e) => changeFilter('sex', e.target.value)}
                >
                  <option value="">Toate</option>
                  <option value="feminin">Feminin</option>
                  <option value="masculin">Masculin</option>
                  <option value="nespecificat">Nespecificat</option>
                </Input>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="min_age">Varsta Minima</label>
                <InputGroup>
                  <InputGroupText>{'<'}</InputGroupText>
                  <Input
                    onChange={(e) => changeFilter('min_age', e.target.value)}
                    value={filters.min_age}
                    placeholder="Varsta Minima"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="puncte_max">Varsta Maxima</label>
                <InputGroup>
                  <InputGroupText>{'>'}</InputGroupText>
                  <Input
                    onChange={(e) => changeFilter('max_age', e.target.value)}
                    value={filters.max_age}
                    placeholder="Varsta Maxima"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col />
            <Col md={2}>
              <Button
                onClick={applyFilters}
                color="primary"
                block
                disabled={loading}
              >
                Aplica Filtre
              </Button>
            </Col>
            <Col md={2}>
              <Button onClick={resetFilters} color="primary" outline block>
                Sterge Filtre
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LocationsFilter;
