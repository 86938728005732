import React from 'react';
import Helmet from 'react-helmet';
import brand from '@/brand';
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import AddCampaignForm from '@/pages/User/LoyaltyCampaigns/components/AddCampaignForm';
import CampaignMilestones from '@/pages/User/LoyaltyCampaigns/components/CampaingMilestones';

const AddLoyaltyCampaign = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Bonus Lunar - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                title: 'Bonus Lunar',
                url: '/app/loyalty-campaigns',
              },
              {
                title: 'Adauga Bonus lunar',
              },
            ]}
            breadcrumbItem="Bonus lunar"
          />
        </Container>
        <Card>
          <CardHeader className="bg-info">
            <CardTitle className="text-white">
              <div className="d-flex justify-content-between">
                <div>Bonus lunar</div>
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <AddCampaignForm />
            <hr />
            <h4>Praguri</h4>
            <CampaignMilestones />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AddLoyaltyCampaign;
