import axios from './axios';

export const getAllContests = async () => {
  return await axios.get('/contests');
};

export const viewContest = async (id: number) => {
  return await axios.get('/contests/' + id);
};

export const createContest = async (data: any) => {
  return await axios.post(`/contests`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateContest = async (id: number, data: any) => {
  return await axios.post(`/contests/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addContestPrize = async (id: number, data: any) => {
  return await axios.post(`/contests/${id}/prize`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editContestPrize = async (id: number, data: any) => {
  return await axios.post(`/contests/${id}/prize/edit`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const removeContestPrize = async (id: number) => {
  return await axios.delete(`/contests/prize/${id}`);
};

export const toggleStatus = async (id: number, data: any) => {
  return await axios.post(`/contests/${id}/toggle-status`, data);
};

export const toggleSchedule = async (id: number, data: any) => {
  return await axios.post(`/contests/${id}/schedule`, data);
};

export const contestInfo = async (id: any, search?: any) => {
  return await axios.get(`/contests/${id}/info`, {
    params: search,
  });
};

export const contestTransactions = async (id: any, search?: any) => {
  return await axios.get(`/contests/${id}/transactions`, {
    params: search,
  });
};

export const contestParticipants = async (id: any, search?: any) => {
  return await axios.get(`/contests/${id}/participations`, {
    params: search,
  });
};

export const contestUserParticipants = async (id: any, search?: any) => {
  return await axios.get(`/contests/${id}/total-participations`, {
    params: search,
  });
};

export const contestClone = async (id: number) => {
  return await axios.get(`/contests/${id}/clone`);
};
