import React from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { FormikProps, getIn } from 'formik';

interface FormikFieldProps {
  formik: FormikProps<any>;
  name: string;
  label: string;
  [x: string]: any;
}

const FormikFieldObj: React.FC<FormikFieldProps> = ({
  formik,
  name,
  type,
  label,
  ...other
}) => {
  const { handleBlur, touched, errors } = formik;
  const value = getIn(formik.values, name) || '';
  const isError = Boolean(getIn(touched, name) && getIn(errors, name));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, e.target.value);
  };

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={isError}
        {...other}
      />
      {isError && <FormFeedback>{getIn(errors, name) + ''}</FormFeedback>}
    </FormGroup>
  );
};

export default FormikFieldObj;
