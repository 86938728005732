import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { IAdminNotification, INotificationStatus } from '@/types/notifications';
import dayjs from 'dayjs';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
  copyMarketingData,
  deleteMarketingCampaign,
  getMarketingData,
  cancelMarketingCampaign,
} from '@/api/marketing';
import Loader from '@/components/Loader';
import Swal from 'sweetalert2';

const types = {
  push: 'push',
  mail: 'email',
  sms: 'sms',
  email: 'mail',
};

const NotificationsTable = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const timer = useRef<any>(null);
  const [filters, setFilters] = useState<any>({
    status: '',
    type: '',
    search: '',
  });

  const changeFilters = (filter: any) => {
    const newFilters = { ...filters, ...filter };
    setFilters(newFilters);
    return newFilters;
  };

  const getData = (data: any) => {
    setLoading(true);
    getMarketingData(data)
      .then((res) => {
        setNotifications(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeFilter = (filter: any) => {
    const newValues = changeFilters(filter);
    getData(newValues);
  };

  const changeSearch = (search: string) => {
    changeFilters({ search });
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      getData({ search });
    }, 500);
  };

  useEffect(() => {
    getData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = (id: number) => {
    copyMarketingData(id).then((res) => {
      const type = res.data.type;
      //@ts-ignore
      navigate(`/app/marketing/${types[type]}/${res.data.id}`);
    });
  };

  const handleCancel = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Campania va fi oprita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, opreste!',
      cancelButtonText: 'Nu, renunta!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        cancelMarketingCampaign(id).then((res) => {
          if (res?.error) {
            Swal.fire({ title: 'Eroare', text: res?.message, icon: 'error' });
          } else {
            Swal.fire({
              title: 'Succes',
              text: 'Campania a fost oprita cu succes',
              icon: 'success',
            }).then(() => {
              getData(filters);
            });
          }
        });
      }
    });
  };

  const handleDeleteCampaign = (id: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Campania va fi stearsa definitiv!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMarketingCampaign(id).then((res) => {
          if (res?.error) {
            Swal.fire({ title: 'Eroare', text: res?.error, icon: 'error' });
          } else {
            Swal.fire({
              title: 'Succes',
              text: 'Campania a fost stearsa cu succes',
              icon: 'success',
            }).then(() => {
              getData(filters);
            });
          }
        });
      }
    });
  };

  const renderStatus = (status: INotificationStatus) => {
    switch (status) {
      case INotificationStatus.DRAFT:
        return <span className="btn btn-sm btn-outline-dark">Ciorna</span>;
      case INotificationStatus.SENT:
        return <span className="btn btn-sm btn-success">Trimis</span>;
      case INotificationStatus.FAILED:
        return <span className="btn btn-sm btn-danger">Eroare</span>;
      case INotificationStatus.SCHEDULED:
        return <span className="btn btn-sm btn-info">Programat</span>;
      case INotificationStatus.PROCESSING:
        return <span className="btn btn-sm btn-info">In procesare</span>;
      case INotificationStatus.CANCELLED:
        return <span className="btn btn-sm btn-dark">Oprita</span>;
      default:
        return <span className="btn btn-sm btn-warning">In asteptare</span>;
    }
  };
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle className="text-white">
            <div className="d-flex justify-content-between">
              <div>Notificari</div>
              <div>
                <Link
                  to="/app/marketing/sms"
                  className="btn btn-sm btn-success"
                >
                  <i className="fas fa-phone"></i> Adauga SMS
                </Link>{' '}
                <Link to="/app/marketing/email" className="btn btn-sm btn-dark">
                  <i className="fas fa-envelope"></i> Adauga Email
                </Link>{' '}
                <Link
                  to="/app/marketing/push"
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-mobile"></i> Adauga Push
                </Link>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            {/*<Col className="d-flex  align-items-end" md={3}>*/}
            {/*  <div className="w-100">*/}
            {/*    <Button className="btn btn-md btn-success w-100">*/}
            {/*      STATUS: ACTIV*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*</Col>*/}
            <Col md={3}>
              <Label>Status</Label>
              <Input
                onChange={(e) => changeFilter({ status: e.target.value })}
                type="select"
                name="select"
              >
                <option value="">Tot ce urmeaza</option>
                <option value="DRAFT">Ciorna</option>
                <option value="PENDING">In asteptare</option>
                <option value="SCHEDULED">Programat</option>
                <option value="SENT">Trimis</option>
                <option value="PROCESSING">In procesare</option>
                <option value="FAILED">Eroare</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label>Tip Notificare</Label>
              <Input
                onChange={(e) => changeFilter({ type: e.target.value })}
                type="select"
                name="select"
              >
                <option value="">Toate</option>
                <option value="push">Push</option>
                <option value="email">Email</option>
                <option value="sms">SMS</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label>Cautare</Label>
              <Input
                value={filters.search}
                onChange={(e) => changeSearch(e.target.value)}
                type="text"
                name="search"
              />
            </Col>
          </Row>
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Denumire</th>
                <th>Numar utilizatori</th>
                <th>Tip</th>
                <th>Status</th>
                <th>Programat La</th>
                <th>Autor</th>
                <th>Actiuni</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={9} className="text-center">
                    <Loader loading={loading} />
                  </td>
                </tr>
              ) : (
                <>
                  {notifications.length === 0 && (
                    <tr>
                      <td colSpan={9} className="text-center">
                        Nu exista date
                      </td>
                    </tr>
                  )}
                  {notifications.map(
                    (notification: IAdminNotification, index: number) => (
                      <tr key={`${index}-notification`}>
                        <td>{notification.id}</td>
                        <td>{notification.label}</td>
                        <td>{notification?.notifications_count}</td>
                        <td>{notification.type}</td>
                        <td>{renderStatus(notification.status)}</td>
                        <td>
                          {notification.run_at
                            ? dayjs(notification.run_at).format(
                                'DD/MM/YYYY HH:mm',
                              )
                            : '-'}
                        </td>
                        <td>
                          {notification.user.first_name}{' '}
                          {notification.user.last_name}
                        </td>
                        <td>
                          {notification.status === INotificationStatus.SENT ? (
                            <>
                              <NavLink
                                to={`/app/marketing/${types[notification.type]}/${
                                  notification.id
                                }`}
                                className="btn btn-sm btn-info"
                              >
                                View
                              </NavLink>
                              <Button
                                onClick={() => handleCopy(notification.id)}
                                className="btn btn-sm btn-success ml-2"
                                style={{ marginLeft: 4 }}
                              >
                                Duplica
                              </Button>
                              <NavLink
                                className="btn btn-sm btn-info m-md-2"
                                to={`/app/marketing/view-log/${notification.id}`}
                              >
                                Status notificari
                              </NavLink>
                            </>
                          ) : (
                            <>
                              <NavLink
                                to={`/app/marketing/${types[notification.type]}/${
                                  notification.id
                                }`}
                                className="btn btn-sm btn-primary"
                              >
                                Editeaza
                              </NavLink>{' '}
                              <Button
                                className="btn btn-sm btn-danger"
                                onClick={() =>
                                  handleDeleteCampaign(notification.id)
                                }
                              >
                                Sterge
                              </Button>
                              <NavLink
                                className="btn btn-sm btn-info m-md-2"
                                to={`/app/marketing/view-log/${notification.id}`}
                              >
                                Status notificari
                              </NavLink>
                              {[
                                INotificationStatus.PROCESSING,
                                INotificationStatus.PENDING,
                              ].indexOf(notification.status) !== -1 && (
                                <Button
                                  onClick={() => handleCancel(notification.id)}
                                  className="btn btn-sm btn-error ml-2"
                                  style={{ marginLeft: 4 }}
                                >
                                  Opreste
                                </Button>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ),
                  )}
                </>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default NotificationsTable;
