import React, { useCallback } from 'react';
import UserPicker from './SearchUsers';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from 'reactstrap';
import LocationPicker from '@/components/LocationPicker';
import { filterUsers } from '@/api/marketing';
import Modal from '@/components/Modal';
import SearchGroup from './SearchGroup';

const NotificationFilters = ({
  setFilters,
  filters,
  resetFilters,
  type,
}: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [whoIsReceiving, setWhoIsReceiving] = React.useState<any>(null);
  const [showInfo, setShowInfo] = React.useState<boolean>(false);

  const handleFilterData = useCallback(() => {
    setLoading(true);
    filterUsers(filters, type)
      .then((res) => {
        setWhoIsReceiving(res.data.users);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters, type]);
  const onPick = (users: any) => {
    setFilters({
      users,
    });
  };
  const onPickGroup = (data: any) => {
    setFilters({
      groups: data,
    });
  };

  return (
    <Card>
      <CardHeader className="bg-default">
        <CardTitle className="color-white">
          <i className="fas fa-filter"></i> Filtre Utilizatori
        </CardTitle>
      </CardHeader>
      <CardBody className="bg-light">
        <div className="form-group">
          <label htmlFor="submission_type">Tip de trimitere</label>
          <Input
            type="select"
            name="submission_type"
            id="submission_type"
            value={filters.type}
            onChange={(e) => {
              resetFilters();
              if (e.target.value === 'user') {
                setFilters({ type: e.target.value, users: [] });
              } else if (e.target.value === 'filters') {
                setFilters({
                  type: e.target.value,
                  filters: {
                    role_id: '',
                  },
                });
              } else if (e.target.value === 'aniversar') {
                setFilters({
                  type: e.target.value,
                  filters: {
                    first_name: '',
                  },
                });
              } else {
                setFilters({ type: e.target.value });
              }
            }}
          >
            <option value="all">Toti utilizatorii</option>
            <option value="user">Selecteaza utilizatori</option>
            <option value="filters">Filtreaza utilizatori</option>
            <option value="groups">Grupuri</option>
            {type === 'push' && <option value="aniversar">Aniversari</option>}
          </Input>
        </div>
        {filters.type === 'user' && (
          <UserPicker value={filters.users} onPick={onPick} />
        )}
        {filters.type === 'groups' && (
          <SearchGroup value={filters.groups} onPick={onPickGroup} />
        )}
        {filters.type === 'aniversar' && (
          <>
            <div className="form-group mt-3">
              <label htmlFor="first_name">Prenume</label>
              <Input
                type="text"
                name="first_name"
                id="first_name"
                placeholder="Separa prin virgula"
                value={filters.first_name}
                required
                onChange={(e) => {
                  setFilters({ first_name: e.target.value });
                }}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="points">Puncte Primite</label>
              <Input
                type="number"
                name="anniversary_points"
                id="anniversary_points"
                placeholder="Puncte Primite"
                value={filters.anniversary_points}
                max={20}
                required
                onChange={(e) => {
                  setFilters({ anniversary_points: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {filters.type === 'filters' && (
          <div className="form-group mt-3">
            <div className="form-group mt-3">
              <label htmlFor="role_id">Rol</label>
              <Input
                type="select"
                name="role_id"
                id="role_id"
                value={filters.filters.role_id}
                onChange={(e) => {
                  setFilters({
                    filters: {
                      ...filters.filters,
                      role_id: e.target.value,
                    },
                  });
                }}
              >
                <option value="">Toate</option>
                <option value="ADMIN">Administrator</option>
                <option value="USER">Utilizator</option>
              </Input>
            </div>
            <div className="form-group mt-3">
              <div className="form-group">
                <label htmlFor="sex">Gen</label>
                <Input
                  type="select"
                  name="sex"
                  value={filters.filters.sex}
                  onChange={(e) => {
                    setFilters({
                      filters: {
                        ...filters.filters,
                        sex: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="">Toate</option>
                  <option value="masculin">Masculin</option>
                  <option value="feminin">Feminin</option>
                  <option value="nespecificat">Nespecificat</option>
                </Input>
              </div>
            </div>
            <div className="form-group mt-3">
              <div className="form-group">
                <label htmlFor="account_type">Tip Cont</label>
                <Input
                  type="select"
                  name="account_type"
                  id="account_type"
                  value={filters.filters.account_type}
                  onChange={(e) => {
                    setFilters({
                      filters: {
                        ...filters.filters,
                        account_type: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="">Toate</option>
                  <option value="business">Business</option>
                  <option value="persoana_fizica">Persoana Fizica</option>
                </Input>
              </div>
            </div>
            <div className="mt-3">
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="min_age">Varsta Minima</label>
                    <Input
                      type="number"
                      name="min_age"
                      id="min_age"
                      value={filters.filters.min_age}
                      placeholder="Varsta Minima"
                      onChange={(e) => {
                        setFilters({
                          filters: {
                            ...filters.filters,
                            min_age: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="max_age">Varsta Maxima</label>
                    <Input
                      type="number"
                      name="max_age"
                      id="max_age"
                      value={filters.filters.max_age}
                      placeholder="Varsta Maxima"
                      onChange={(e) => {
                        setFilters({
                          filters: {
                            ...filters.filters,
                            max_age: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="form-group mt-3">
              <LocationPicker
                selectedLocation={filters.filters.location_id}
                onPick={(location_id: number) => {
                  setFilters({
                    filters: {
                      ...filters.filters,
                      location_id,
                    },
                  });
                }}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="profile">Status Profil</label>
              <Input
                type="select"
                name="profile"
                id="profile"
                value={filters.filters.profile}
                onChange={(e) => {
                  setFilters({
                    filters: {
                      ...filters.filters,
                      profile: e.target.value,
                    },
                  });
                }}
              >
                <option value="">Toate</option>
                <option value="complete">Complet</option>
                <option value="incomplete">Incomplet</option>
              </Input>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="activity">Activitate</label>
              <Input
                type="select"
                name="activity"
                value={filters.filters.activity}
                id="activity"
                onChange={(e) => {
                  setFilters({
                    filters: {
                      ...filters.filters,
                      activity: e.target.value,
                    },
                  });
                }}
              >
                <option value="">Toate</option>
                <option value="30">In ultmele 30 de zile</option>
                <option value="60">In ultmele 60 de zile</option>
                <option value="90">In ultmele 90 de zile</option>
                <option value="-1">Fara activitate</option>
              </Input>
            </div>
            <div className="mt-3">
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="t_min">Achizitie medie minima</label>
                    <Input
                      type="number"
                      name="t_min"
                      id="t_min"
                      value={filters.filters.t_min}
                      placeholder="Achizitie Minima"
                      onChange={(e) => {
                        setFilters({
                          filters: {
                            ...filters.filters,
                            t_min: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="t_max">Achizitie medie maxima</label>
                    <Input
                      type="number"
                      name="t_max"
                      id="t_max"
                      value={filters.filters.t_max}
                      placeholder="Achizitie Maxima"
                      onChange={(e) => {
                        setFilters({
                          filters: {
                            ...filters.filters,
                            t_max: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </CardBody>
      <CardFooter className="d-flex justify-content-between">
        <Button
          type="button"
          color="danger"
          onClick={() => {
            resetFilters();
          }}
        >
          Reset
        </Button>
        <Button
          disabled={loading}
          type="button"
          color="primary"
          onClick={handleFilterData}
        >
          Vezi cine primeste {loading && <i className="fas fa-spinner"></i>}
        </Button>
      </CardFooter>
      {whoIsReceiving && (
        <Modal
          title={`Cine Primeste?`}
          open={true}
          toggle={() => setWhoIsReceiving(null)}
        >
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Button
              className="mb-3"
              color={showInfo ? 'danger' : 'primary'}
              onClick={() => setShowInfo(!showInfo)}
            >
              {showInfo ? (
                <i className="fas fa-times-circle" />
              ) : (
                <i className="fas fa-info-circle"></i>
              )}
            </Button>
            <div
              className={'mb-6'}
              style={{
                marginBottom: 15,
                fontSize: 14,
                color: '#060',
                fontWeight: 'bold',
              }}
            >
              Notificare trimisa catre: {whoIsReceiving?.length} utilizatori
            </div>
            {showInfo && (
              <ul className="border-bottom border-4">
                <li>
                  Bazat pe filtrarea curenta si preferintele utilizatorilor
                  acestea sunt persoanele ce o sa fie notificate:
                </li>
                <li>
                  Daca notificarea este programata, si preferintele
                  utilizatorilor se schimba intre timp atunci lista de mai jos
                  nu va mai fi valabila.
                </li>
                <li>
                  Filtrele selectate de dvs. se aplica la momentul trimiterii
                  notificarii.
                </li>
                <li>
                  Daca anumiti utilizatori nu se regasesc in lista de mai jos
                  inseamna ca nu respecta criteriile de filtrare sau nu au optat
                  pentru a primi notificari.
                </li>
              </ul>
            )}
            {whoIsReceiving.map((user: any, index: number) => (
              <div key={user.id}>
                <h5 className="d-flex justify-content-between border-bottom border-3">
                  <span>
                    {index + 1}. {user.first_name} {user.last_name} -{' '}
                    {type === 'sms' ? user.phone_no : user.email}{' '}
                  </span>
                  <i className="fas fa-check-circle text-success"></i>
                </h5>
              </div>
            ))}
            {whoIsReceiving.length === 0 && (
              <p className="text-center">
                Nu exista utilizatori conform criteriilor selectate
              </p>
            )}
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default NotificationFilters;
