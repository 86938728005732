import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { FormikProps } from 'formik';
import Select from 'react-select';

interface FormikFieldProps {
  formik: FormikProps<any>;
  name: string;
  label: string;
  multiline?: boolean;
  [x: string]: any;
}

const FormikSelect: React.FC<FormikFieldProps> = ({
  formik,
  name,
  type,
  label,
  selected,
  data,
  multiple,
  ...other
}) => {
  const { setFieldValue, errors } = formik;
  const isError = Boolean(errors[name]);
  const handleSelectChange = (value: any) => {
    setFieldValue(name, value);
  };
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Select
        closeMenuOnSelect={!multiple}
        isMulti={multiple}
        id={name}
        name={name}
        onChange={handleSelectChange}
        // onBlur={handleBlur}
        options={data}
        defaultValue={selected}
        className="form-control"
        {...other}
      />
      {isError && <FormFeedback>{errors[name] + ''}</FormFeedback>}
    </FormGroup>
  );
};

export default FormikSelect;
