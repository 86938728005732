import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import useUsersReports from '@/hooks/useUsersReports';

import UsersReportFilter from '../components/UsersFilters';
import PaginationComponent from '@/components/Paginate';
import Loader from '@/components/Loader';
import { ISale } from '@/types/reports';
import { Link } from 'react-router-dom';
import EmptyState from '@/components/EmptyState';

const columns = [
  {
    name: 'Internal ID',
    label: 'id',
    selector: (row: any) => row.id,
    sortable: true,
  },
  {
    name: 'Data',
    label: 'created_at',
    selector: (row: any) => dayjs(row.created_at).format('DD-MM-YYYY HH:mm'),
    sortable: true,
  },
  {
    name: 'Utilizator',
    label: 'name',
    selector: (row: any) => row.first_name + ' ' + row.last_name,
    format: (row: ISale) => (
      <Link to={`/app/users/${row.user_uuid}`}>{row.name}</Link>
    ),
    sortable: true,
  },
  {
    name: 'Email',
    label: 'email',
    selector: (row: any) => row.email,
    sortable: true,
  },
  {
    name: 'Tip Utilizator',
    label: 'business_id',
    selector: (row: any) => (row.business_id ? 'Companie' : 'P. Fizica'),
    sortable: true,
  },
  {
    name: 'Varsta',
    label: 'birth_date',
    selector: (row: any) =>
      row.birth_date ? dayjs().diff(row.birth_date, 'years') + ' ani' : 'n/a',
    sortable: true,
  },
  {
    name: 'Gen',
    label: 'sex',
    selector: (row: any) => row.sex,
    sortable: true,
  },
  {
    name: 'Magazin Favorit',
    label: 'location_id',
    selector: (row: any) => row.location_name,
    // sortField: 'location_name',
    sortable: true,
  },
  {
    name: 'P. disp.',
    label: 'points',
    selector: (row: any) => Number(row.points).toFixed(2),
    // sortField: 'points',
    // sortFunction: (a: any, b: any) => {
    //   const aPoints = a.points ? a.points * 10 : 0;
    //   const bPoints = b.points ? b.points * 10 : 0;
    //   return aPoints - bPoints;
    // },
  },
  {
    name: 'P. tot.',
    label: 'total_earned',
    selector: (row: any) => Number(row.total_earned).toFixed(2),
    // sortField: 'total_earned',
    sortable: true,
  },
  {
    name: 'P. fol.',
    label: 'total_redeemed',
    selector: (row: any) => Number(row.total_redeemed).toFixed(2),
    // sortField: 'total_redeemed',
    sortable: true,
  },
];

const UsersReportPage = () => {
  const {
    data,
    loading,
    page,
    perPage,
    changePage,
    changeSort,
    changePerPage,
    total,
    dataQuery,
  } = useUsersReports();

  const handleSort = (column: (typeof columns)[0], sortDirection: string) => {
    changeSort(column.label, sortDirection);
  };

  useEffect(() => {
    dataQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Raport Utilizatori - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Raport Utilizatori"
          />
          <UsersReportFilter />
          <Card>
            <CardHeader className="bg-info">
              <CardTitle className="text-white">Rapoarte Utilizatori</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
              {loading && <Loader loading={loading} />}
              {!loading && data?.length === 0 && (
                <EmptyState
                  title="Nu exista date"
                  description="Nu s-au gasit date pentru filtrele selectate."
                />
              )}

              {!loading && data?.length > 0 && (
                <>
                  <DataTable
                    columns={columns}
                    data={data}
                    dense
                    sortServer={true}
                    onSort={handleSort}
                    progressComponent={<Loader loading={true} />}
                  />
                  <div className="d-flex justify-content-center align-items-center p-3">
                    <PaginationComponent
                      page={page}
                      perPage={perPage}
                      total={total}
                      onClick={(p) => changePage(p)}
                    />
                    <div
                      className={`form-group d-flex align-items-center ${
                        total < 11 ? 'd-none' : ''
                      }`}
                    >
                      <select
                        className="form-control m-2"
                        id="perPage"
                        style={{ width: '50px' }}
                        value={perPage}
                        onChange={(e) =>
                          changePerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                      {'   '} - pe pagina din
                      <strong className={'p-1'}>{total}</strong>
                      utilizatori
                    </div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersReportPage;
