import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import {
  deleteLoyaltyCampaign,
  getLoyaltyCampaigns,
} from '@/api/loyalty-campaigns';
import { Link } from 'react-router-dom';
import Loader from '@/components/Loader';
import EmptyState from '@/components/EmptyState';
import TableRow from '@/pages/User/LoyaltyCampaigns/components/LoyaltyCampaignsTable/components/TableRow';
import { errorAlert } from '@/helpers/alerts';
import CampaignModal from '@/pages/User/LoyaltyCampaigns/components/CampaignModal';
import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import Swal from 'sweetalert2';

const LoyaltyCampaignsTable = () => {
  const [loading, setLoading] = React.useState(true);
  const [campaigns, setCampaigns] = React.useState([]);
  const [editCampaign, setEditCampaign] = React.useState<any>(null);

  const { updateCampaign, activateCampaign, deactivateCampaign } =
    useLoyaltyCampaign();

  const showErrorMessage = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message || 'Eroare necunoscuta!',
    });
  };

  const getData = (filters?: { status: string }) => {
    setLoading(true);
    getLoyaltyCampaigns(filters)
      .then((response) => {
        setCampaigns(() => response.data.campaigns);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeFilter = (filter: any) => {
    getData(filter);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteAction = async (campaignId: number) => {
    const response = await deleteLoyaltyCampaign(campaignId);
    if (response.status === 204) {
      getData();
    } else {
      errorAlert('A aparut o eroare la stergerea campaniei');
    }
  };

  const handleSelectCampaign = (campaign: any) => {
    setEditCampaign(campaign);
  };

  const handleEditCampaignAction = async (values: any) => {
    updateCampaign(editCampaign.id, values).then(() => {
      getData();
      setEditCampaign(null);
    });
  };

  const handleActivateCampaignAction = async (campaignId: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Campania va fi activata! Va fi vizibila pentru utilizatori!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, activeaza!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        const response = activateCampaign(campaignId).then((response) => {
          if (response?.error) {
            showErrorMessage(response?.message);
            return response;
          }
          getData();
        });
      }
    });
  };

  const handleDeactivateCampaignAction = async (campaignId: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Campania va fi dezactivata! Nu va mai fi vizibila pentru utilizatori!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, dezactiveaza!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        const response = deactivateCampaign(campaignId).then((response) => {
          if (response?.error) {
            showErrorMessage(response?.message);
            return response;
          }
          getData();
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle className="text-white">
            <div className="d-flex justify-content-between">
              <div></div>
              <div>
                <Link
                  to="/app/loyalty-campaigns/add"
                  className="btn btn-sm btn-success"
                >
                  <i className="fas fa-bullhorn"></i> Adauga campanie noua
                </Link>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col md={2}>
              <Label>Status</Label>
              <Input
                onChange={(e) => handleChangeFilter({ status: e.target.value })}
                type="select"
                name="select"
              >
                <option value="">Toate campaniile</option>
                <option value="DRAFT">Ciorna</option>
                <option value="PENDING">In asteptare(sa se activeze)</option>
                <option value="ACTIVE">Activ</option>
              </Input>
            </Col>
          </Row>
          {loading && <Loader loading={loading} />}
          {!loading && campaigns.length === 0 ? (
            <EmptyState
              title={'Nu sunt Bonusuri lunare adaugate'}
              description={''}
              buttonUrl={'/app/loyalty-campaigns/add'}
              actionLabel={'Adauga bonus nou'}
            />
          ) : (
            <Table responsive={true}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Denumire</th>
                  <th>Data inceput</th>
                  <th>Data sfarsit</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign) => (
                  <TableRow
                    key={campaign.id}
                    campaign={campaign}
                    onDeleteAction={() => handleDeleteAction(campaign.id)}
                    onActivateAction={() =>
                      handleActivateCampaignAction(campaign.id)
                    }
                    onPauseAction={() =>
                      handleDeactivateCampaignAction(campaign.id)
                    }
                    onEditAction={() => handleSelectCampaign(campaign)}
                  />
                ))}
                <tr></tr>
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      <CampaignModal
        campaign={editCampaign}
        isOpen={!!editCampaign}
        onClose={() => setEditCampaign(null)}
        submitAction={handleEditCampaignAction}
      />
    </React.Fragment>
  );
};

export default LoyaltyCampaignsTable;
