import { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import UserTransactions from '../../Users/Show/components/UserTransactions';
import { contestTransactions } from '@/api/contest';
import Loader from '@/components/Loader';
import PaginationComponent from '@/components/Paginate';

const ContestTransactions = ({
  contest_id,
  locationId,
  search,
}: {
  contest_id: number;
  locationId: number;
  search: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [perPage] = useState(25);
  const [participations, setParticipations] = useState<number>(0);
  const [sort, setSort] = useState<any>('id');
  const [order, setOrder] = useState<any>('desc');
  const [total, setTotal] = useState(0);

  const timer = useRef<any>(null);

  const fetchTransactions = async () => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      contestTransactions(contest_id, {
        location_id: locationId,
        page,
        perPage: perPage,
        search,
        sort: sort,
        order: order,
      })
        .then((res) => {
          setData(res.data.transactions.data);
          setTotal(res.data.transactions.total);
          setParticipations(res.data?.total_participation);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contest_id, page, search, locationId, sort, order]);

  useEffect(() => {
    setPage(1);
  }, [search, locationId, sort, order]);

  const handleChangeSort = (s: string) => {
    if (s === sort) {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    } else {
      setSort(s);
      setOrder('desc');
    }
  };

  return (
    <Card>
      <CardHeader className="bg-primary d-flex justify-content-between">
        <CardTitle className="text-white">Tranzactii Concurs</CardTitle>
      </CardHeader>
      <CardBody>
        {!loading && data && (
          <>
            <p>
              {participations} participari in {total} tranzactii
            </p>
            <UserTransactions
              showUser
              transactions={data}
              sort={sort}
              order={order}
              changeSort={handleChangeSort}
            />
            <PaginationComponent
              page={page}
              perPage={perPage}
              total={total}
              onClick={(p) => setPage(p)}
            />
          </>
        )}
        {loading && <Loader loading={loading} />}
      </CardBody>
    </Card>
  );
};

export default ContestTransactions;
