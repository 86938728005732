import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from 'reactstrap';
import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getLocationsReports } from '@/api/reports';

const DatesFilter = () => {
  const { changeFilter, resetFilters, filters, applyFilters, loading } =
    useSalesReportsHook(getLocationsReports);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="card-primary">
          <CardTitle tag="h4">
            <i className="mdi mdi-filter" /> Filtre
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className={'align-items-end'}>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="data">De la</label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => changeFilter('startDate', e.target.value)}
                  className="form-control"
                  id="data"
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="end_date">Pana la</label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => changeFilter('endDate', e.target.value)}
                  className="form-control"
                  id="end_date"
                />
              </div>
            </Col>

            <Col md={2}>
              <Button
                onClick={applyFilters}
                color="primary"
                block
                disabled={loading}
              >
                Aplica Filtre
              </Button>
            </Col>
            <Col md={2}>
              <Button onClick={resetFilters} color="primary" outline block>
                Sterge Filtre
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DatesFilter;
