import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const Referrals = ({ referrals }: any) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle className="text-white">Recomandari Facute</CardTitle>
        </CardHeader>
        <CardBody
          style={{ height: 300, overflow: 'scroll', position: 'relative' }}
        >
          {referrals.length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100%' }}
            >
              Nu exista recomandari
            </div>
          ) : (
            <ul className="list-group">
              {referrals
                .filter((entry) => entry.email)
                .map((referral: any) => (
                  <li
                    onClick={() => navigate(`/app/users/${referral.uuid}`)}
                    key={`referral-${referral.uuid}`}
                    style={{ cursor: 'pointer' }}
                    className="list-group-item"
                  >
                    <div className="media d-flex">
                      <div style={{ flexGrow: 1 }} className="media-body">
                        <h5 className="mt-0">{referral.name}</h5>
                        {referral.email}
                      </div>
                      <div className="">
                        <span className="primary">
                          {referral.points} puncte
                        </span>
                        <div>
                          {referral.has_acquisition ? 'Validat' : 'Ne validat'}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Referrals;
