import axios from './axios';

export const getBusinessRequest = async (params: any = {}) => {
  return await axios.get('/business', { params });
};

export const updateBusinessRequest = async (id: number, data: any) => {
  return await axios.post(`/business/${id}`, data);
};

export const createBusinessRequest = async (data: any) => {
  return await axios.post('/business', data);
};
