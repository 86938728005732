import { getProductsRequest } from './../api/products';
import { useEffect, useCallback, useRef } from 'react';
import {
  setPage,
  setSort,
  setData,
  setPerPage,
  setSearch,
  setFilters,
} from '@/redux/slices/products.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';

const reducerName = 'products';

const useProducts = () => {
  const dispatch = useAppDispatch();
  const timer = useRef<any>(null);
  const reducer = useAppSelector((state) => state[reducerName]);

  const getData = useCallback((params: any) => getProductsRequest(params), []);

  const dataQuery = (others: any = {}) => {
    return getData({ ...getFilters(), ...others }).then((res) =>
      dispatch(setData(res.data[reducerName])),
    );
  };

  const getFilters = useCallback(() => {
    return {
      search: reducer.search,
      page: reducer.page,
      sort: reducer.sort,
      order: reducer.order,
      perPage: reducer.perPage,
      filters: reducer.filters,
    };
  }, [reducer]);

  useEffect(() => {
    dataQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFilter = (key: string, value: any) => {
    const newFilters = { ...reducer.filters, [key]: value };
    dispatch(setFilters(newFilters));
    dataQuery({ filters: newFilters, page: 1 });
  };

  const changeSort = (sort: string) => {
    const order =
      reducer.sort === sort
        ? reducer.order === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch(
      setSort({
        sort,
        order,
      }),
    );
    dataQuery({ sort, order });
  };

  const changePage = (page: number) => {
    dispatch(setPage(page));
    dataQuery({ page });
  };

  const changePerPage = (perPage: number) => {
    dispatch(setPerPage(perPage));
    dataQuery({ perPage, page: 1 });
  };
  // this should be debounced
  const changeSearch = (search: string) => {
    dispatch(setSearch(search));
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dataQuery({ search, page: 1 });
    }, 500);
  };

  return {
    ...reducer,
    changeSort,
    getData,
    changePage,
    changePerPage,
    changeSearch,
    changeFilter,
  };
};

export default useProducts;
