import { getProduct } from '@/api';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import ProductForm from '../components/Summary/ProductForm';

const ProductViewPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getProduct(id)
        .then((res) => {
          setProduct(res.data.product);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Modificare - {product?.name ?? ''}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem={product?.name} />
          <Card>
            <CardBody>
              {loading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <ProductForm
                  initialValues={product}
                  onSubmit={(prod: any) => {
                    setProduct(prod);
                    navigate('/app/products');
                  }}
                  id={product.id}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductViewPage;
