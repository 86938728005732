import brand from '@/brand';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

const Breadcrumbs = (props: any) => {
  const { bread } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
              {props.breadcrumbItem || props.title}
            </h4>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem>
                  <Link to="/app">{brand.shortName}</Link>
                </BreadcrumbItem>
                {bread &&
                  bread.map((item: any, index: number) => {
                    return (
                      <BreadcrumbItem key={index}>
                        <Link to={item.url}>{item.title}</Link>
                      </BreadcrumbItem>
                    );
                  })}
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Breadcrumbs;
