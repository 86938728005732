import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'react-data-table-component';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';

import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getLocationsReports } from '@/api/reports';
import { format_currency } from '@/helpers/numbers';
import LocationsFilter from '@/pages/User/Reports/components/LocationsFilter';
import { downloadCSV } from '@/helpers/csv-utils';

const columns = [
  {
    name: 'ID',
    selector: (row: any) => row.location_id,
    sortable: true,
  },
  {
    name: 'Nume',
    selector: (row: any) => row?.location?.name,
    sortable: true,
  },
  {
    name: 'Cumparaturi medii',
    selector: (row: any) => format_currency(row.avg_amount),
    sortable: true,
  },
  {
    name: 'Minim cumparat',
    selector: (row: any) => format_currency(row.min_amount),
    sortable: true,
  },
  {
    name: 'Maxim cumparat',
    selector: (row: any) => format_currency(row.max_amount),
    sortable: true,
  },
  {
    name: 'Total cumparat',
    selector: (row: any) => format_currency(row.total_amount),
    sortable: true,
  },
  {
    name: 'Total tranzactii',
    selector: (row: any) => row.total_transactions,
    sortable: true,
  },
];

const exportColumns = [
  'location_id',
  'location.name',
  'avg_amount',
  'min_amount',
  'max_amount',
  'total_amount',
  'total_transactions',
];

const ExportButton = ({ onExport }) => (
  <Button color={'warning'} onClick={(e: any) => onExport(e.target?.value)}>
    Export
  </Button>
);
const LocationsReportPage = () => {
  const { salesData, filtersToString, salesQuery } =
    useSalesReportsHook(getLocationsReports);

  useEffect(() => {
    salesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Raport Magazine - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Raport Magazine"
          />
          <LocationsFilter />
          <Card>
            <CardHeader className="bg-info d-flex flex-row justify-content-between">
              <CardTitle className="text-white">Rapoarte magazine</CardTitle>
              <ExportButton
                onExport={() =>
                  downloadCSV(salesData, exportColumns, filtersToString())
                }
              />
            </CardHeader>
            <CardBody className="card-body">
              <DataTable columns={columns} data={salesData} dense />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LocationsReportPage;
