import axios from './axios';
import { ILoyaltyCampaign, IMilestone } from '@/types/loyalty-campaign';
import { AxiosResponse } from 'axios';

/**
 * Retrieves the loyalty campaigns from the server.
 *
 * @async
 * @function getLoyaltyCampaigns
 * @returns {Promise} A promise that resolves to the loyalty campaigns data.
 */
export const getLoyaltyCampaigns = async (filters?: {
  status: string;
}): Promise<any> => {
  return await axios.get('/loyalty-campaigns', {
    params: filters,
  });
};

/**
 * Retrieves a loyalty campaign by its ID.
 *
 * @param {number} id - The ID of the loyalty campaign to retrieve.
 * @returns {Promise<Object>} - A promise that resolves with the loyalty campaign data.
 * @throws {Error} - If an error occurs during the retrieval process.
 */
export const getLoyaltyCampaignById = async (
  id: number,
): Promise<AxiosResponse> => {
  return await axios.get(`/loyalty-campaigns/${id}`);
};

/**
 * Creates a new loyalty campaign.
 *
 * @async
 * @param {ILoyaltyCampaign} data - The data for the loyalty campaign.
 * @returns {Promise<AxiosResponse>} The response from the server.
 */
export const createLoyaltyCampaign = async (
  data: ILoyaltyCampaign,
): Promise<AxiosResponse> => {
  return await axios.post('/loyalty-campaigns', data);
};

/**
 * Updates a loyalty campaign with the provided data.
 *
 * @async
 * @param {number} id - The ID of the loyalty campaign to update.
 * @param {ILoyaltyCampaign} data - The updated data for the loyalty campaign.
 * @returns {Promise} - A Promise that resolves to the updated loyalty campaign.
 */
export const updateLoyaltyCampaign = async (
  id: number,
  data: ILoyaltyCampaign,
): Promise<any> => {
  return await axios.put(`/loyalty-campaigns/${id}`, data);
};

/**
 *
 * @param {number} id
 */
export const activateLoyaltyCampaign = async (id: number): Promise<any> => {
  try {
    return await axios.put(`/loyalty-campaigns/${id}/activate`);
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      return Promise.reject(new Error(error.response.data.message)); // Reject with validation error message
    } else {
      return Promise.reject(error); // Reject other errors
    }
  }
};

/**
 *
 * @param {number} id
 */
export const deactivateLoyaltyCampaign = async (id: number): Promise<any> => {
  return await axios.put(`/loyalty-campaigns/${id}/deactivate`);
};

/**
 * Deletes a loyalty campaign.
 *
 * @async
 * @param {number} id - The ID of the loyalty campaign to delete.
 * @returns {Promise} A promise that resolves when the deletion is successful.
 */
export const deleteLoyaltyCampaign = async (id: number): Promise<any> => {
  return await axios.delete(`/loyalty-campaigns/${id}`);
};

/**
 * Creates a milestone for a campaign.
 *
 * @async
 * @param {number} campaignId - The ID of the campaign.
 * @param {IMilestone} data - The data for the milestone.
 * @returns {Promise} - A promise that resolves to the created milestone.
 * @throws {Error} - If there is a validation error, it rejects with the validation error message. Otherwise, it rejects with the error object.
 */
export const createMilestoneForCampaign = async (
  campaignId: number,
  data: IMilestone,
): Promise<any> => {
  try {
    return await axios
      .post(`/loyalty-campaigns/${campaignId}/milestones`, data)
      .then((res) => res.data);
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      return Promise.reject(new Error(error.response.data.message)); // Reject with validation error message
    } else {
      return Promise.reject(error); // Reject other errors
    }
  }
};

export const updateMilestoneForCampaign = async (
  milestoneId: number,
  data: IMilestone,
): Promise<any> => {
  try {
    return await axios
      .put(`/loyalty-campaigns/milestones/${milestoneId}`, data)
      .then((res) => res.data);
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      return Promise.reject(new Error(error.response.data.message)); // Reject with validation error message
    } else {
      return Promise.reject(error); // Reject other errors
    }
  }
};

/**
 * Deletes a milestone for a campaign.
 *
 * @param {number} milestoneId - The ID of the milestone.
 * @returns {Promise} A promise that resolves when the milestone is deleted successfully.
 */
export const deleteMilestoneForCampaign = async (
  milestoneId: number,
): Promise<any> => {
  return await axios.delete(`/loyalty-campaigns/milestones/${milestoneId}`);
};

/**
 * Retrieves the transactions for a given campaign.
 *
 * @param {number} campaignId - The ID of the campaign.
 * @returns {Promise<AxiosResponse>} - A promise that resolves to the response data containing the transactions.
 */
export const getTransactionsForCampaign = async (
  campaignId: number,
): Promise<AxiosResponse> => {
  return await axios.get(`/loyalty-campaigns/${campaignId}/transactions`);
};
