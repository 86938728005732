import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../../components/Breadcrumbs';
import brand from '../../../brand';

const DiscountPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Discount - </title>
      </Helmet>
      <div className="page-content">
        <Breadcrumbs title={brand.shortName} breadcrumbItem="Discount Page" />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  Sectiune in test. Va rugam sa reveniti mai tarziu.
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DiscountPage;
