import React from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormikField from '@/components/FormikField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ILoyaltyCampaign } from '@/types/loyalty-campaign';
import { format_date } from '@/helpers/dates';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele campaniei este obligatoriu'),
  description: Yup.string().required('Descrierea campaniei este obligatorie'),
  start_date: Yup.string().required('Data de inceput este obligatorie'),
  end_date: Yup.string().required('Data de sfarsit este obligatorie'),
  status: Yup.string().required('Statusul campaniei este obligatoriu'),
});

const CampaignForm = ({
  formAction,
  campaign,
}: {
  formAction: (values: ILoyaltyCampaign) => void;
  campaign?: ILoyaltyCampaign | null;
}) => {
  const formik = useFormik({
    initialValues: {
      name: campaign?.name || '',
      description: campaign?.description || '',
      start_date: campaign?.start_date
        ? format_date(campaign.start_date, 'YYYY-MM-DDTHH:mm:ss')
        : '',
      end_date: campaign?.end_date
        ? format_date(campaign.end_date, 'YYYY-MM-DDTHH:mm:ss')
        : '',
      status: campaign?.status || 'DRAFT',
    },
    validationSchema,
    onSubmit: (values) => {
      formAction(values);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <FormikField
          label={'Numele campaniei'}
          id="campaignName"
          name="name"
          type="text"
          formik={formik}
        />
      </FormGroup>
      <FormGroup>
        <FormikField
          label={'Descriere'}
          id="description"
          name="description"
          type="textarea"
          formik={formik}
        />
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup>
            <FormikField
              label={'Data inceput'}
              id="start_date"
              name="start_date"
              type="datetime-local"
              formik={formik}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormikField
              label={'Data sfarsit'}
              id="end_date"
              name="end_date"
              type="datetime-local"
              formik={formik}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <FormikField
          label={'Status'}
          id="status"
          name="status"
          type="select"
          formik={formik}
        >
          <option value={'draft'}>Ciorna</option>
          <option value={'pending'}>In asteptare</option>
        </FormikField>
      </FormGroup>

      <FormGroup>
        <Col>
          <Button color={'primary'} type={'submit'}>
            Salveaza campanie
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default CampaignForm;
