import { getAllCategoriesRequest } from '@/api';
import { createContest, updateContest } from '@/api/contest';
import DropFile from '@/components/DropFile';
import FormikField from '@/components/FormikField';
import FormikSelect from '@/components/FormikField/Select';
import { errorAlert, successAlert } from '@/helpers/alerts';
import { useFormik } from 'formik';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';

const validationRules = Yup.object().shape({
  name: Yup.string().required('Numele concursului este obligatoriu'),
  description: Yup.string().required('Descrierea concursului este obligatorie'),
  categories: Yup.array().required('Categoria concursului este obligatorie'),
  start_date: Yup.date().required('Data de inceput este obligatorie'),
  end_date: Yup.date().required('Data de sfarsit este obligatorie'),
  date_range: Yup.string().required('Perioada de desfasurare este obligatorie'),
  participation_amount: Yup.number().required(
    'Suma de participare este obligatorie',
  ),
});

const ContestForm = ({ initialValues, onSubmit }: any) => {
  const [categories, setCategories] = useState<any[]>([]);
  const file = useRef<any>(null);

  useLayoutEffect(() => {
    getAllCategoriesRequest().then((res) => {
      setCategories(res.data);
    });
  }, []);

  const handleSubmit = (values: any) => {
    const categories = values.categories.map((c: any) => c.value);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('start_date', values.start_date);
    formData.append('end_date', values.end_date);
    formData.append('participation_amount', values.participation_amount);
    formData.append('categories', categories);
    formData.append('date_range', values.date_range);
    formData.append('rules_link', values.rules_link);
    formData.append('winners_list', values.winners_list);
    // formData.append('status', values.status.value);

    if (file.current) {
      formData.append('image', file.current);
    }

    if (initialValues.id) {
      // update
      updateContest(initialValues.id, formData)
        .then((res) => {
          if (typeof onSubmit === 'function') {
            onSubmit(res.data.contest);
          }
          successAlert('Concursul a fost modificat cu succes!');
        })
        .catch((err) => {
          errorAlert(err.response.data.message);
        });
    } else {
      // create
      createContest(formData)
        .then((res) => {
          if (typeof onSubmit === 'function') {
            onSubmit(res.data.contest);
          }
          successAlert('Concursul a fost adaugat cu succes!');
        })
        .catch((err) => {
          errorAlert(err.response.data.message);
        });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationRules,
  });

  const onFileDrop = (acceptedFiles: any) => {
    file.current = acceptedFiles[0];
  };

  // @NOTE: this was used for contest automation - not needed anymore since the client didn't want it
  // const availableStatuses = [
  //   { label: 'Activ', value: 'active' },
  //   { label: 'Ciorna', value: 'pending' },
  //   { label: 'Finalizat', value: 'ended' },
  //   { label: 'Programat', value: 'scheduled' },
  // ];
  // const selectedStatus = availableStatuses.find(
  //   (s) => s.value === formik.values.status,
  // );
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={8}>
            <FormikField
              name="name"
              formik={formik}
              label="Nume Concurs"
              placeholder="Nume Concurs"
            />
            <FormikField
              name="description"
              formik={formik}
              label="Descriere Concurs"
              placeholder="Descriere Concurs"
              multiline={true}
              rows={3}
            />

            <FormikSelect
              name="categories"
              formik={formik}
              label="Categorie Concurs"
              placeholder="Categorie Concurs"
              multiple={true}
              data={categories}
              selected={formik.values.categories}
            />

            <FormikField
              name="start_date"
              formik={formik}
              label="Data Inceput Concurs"
              placeholder="Data Inceput Concurs"
              type="datetime-local"
            />

            <FormikField
              name="end_date"
              formik={formik}
              label="Data Sfarsit Concurs"
              placeholder="Data Sfarsit Concurs"
              type="datetime-local"
            />

            <FormikField
              name="participation_amount"
              formik={formik}
              label="Suma/Participare"
              placeholder="Suma/Participare"
              type="number"
              min={0}
              max={500}
            />

            <FormikField
              name="date_range"
              formik={formik}
              label="Perioada de desfasurare afisata"
              placeholder="Perioada de desfasurare afisata"
              type="text"
            />

            <FormikField
              name="rules_link"
              label="Link catre regulament"
              formik={formik}
              placeholder="Link catre regulament"
              type="text"
            />

            {/*<FormikSelect*/}
            {/*  name="status"*/}
            {/*  formik={formik}*/}
            {/*  label="Status concurs"*/}
            {/*  placeholder="Status concurs"*/}
            {/*  multiple={false}*/}
            {/*  data={availableStatuses}*/}
            {/*  selected={selectedStatus}*/}
            {/*/>*/}

            <FormikField
              name="winners_list"
              formik={formik}
              label="Link Lista Castigatori"
              placeholder="Lista de Castigatori"
              type="text"
            />
          </Col>
          <Col md={4}>
            <p>Imagine</p>
            <DropFile
              onDropFile={onFileDrop}
              defaultPreview={initialValues.image}
            />
          </Col>
          <Col md={12} className="d-flex justify-content-end">
            <Button type="submit" className="btn btn-md" color="primary">
              {initialValues.id ? 'Modifica' : 'Adauga'}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default ContestForm;
