import React from 'react';
import { Table } from 'reactstrap';
import { format_number } from '@/helpers/numbers';

const TransactionDisplay = ({ transaction }: any) => {
  return (
    <React.Fragment>
      <Table className="mb-0" responsive striped>
        <thead>
          <tr>
            <th>Produs</th>
            <th>Cantitate</th>
            <th>Puncte</th>
            <th>Discount</th>
            <th>Pret</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {transaction.products.map((item: any) => (
            <tr key={`${item.id}-prod`}>
              <td>{item.product.name}</td>
              <td>{item.quantity}</td>
              <td>{format_number(item.points)}p</td>
              <td>{item.discount_percent * 100}%</td>
              <td>{item.amount} RON</td>
              <td>{format_number(item.amount * item.quantity)} RON</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>
              <strong>Total puncte</strong>
            </td>
            <td>{transaction.points}p</td>
            <td></td>
            <td>
              <strong>Total Platit</strong>
            </td>
            <td>
              <strong>{transaction.amount} RON</strong>
            </td>
          </tr>
        </tfoot>
      </Table>
    </React.Fragment>
  );
};

export default TransactionDisplay;
