import React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';

import Breadcrumbs from '@/components/Breadcrumbs';
import brand from '@/brand';

const MarketingPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Marketing</title>
      </Helmet>
      <div className="page-content">
        <Breadcrumbs title={brand.shortName} breadcrumbItem="Marketing" />
        <Container fluid={true}>xx</Container>
      </div>
    </React.Fragment>
  );
};

export default MarketingPage;
