import React from 'react';

interface IProfileMenuProps {
  activeStep: number;
  setStep: (step: number) => void;
}

const ProfileMenu: React.FC<IProfileMenuProps> = ({ activeStep, setStep }) => {
  return (
    <div className="p-2">
      <div className="list-group">
        <button
          className={`list-group-item list-group-item-action ${
            activeStep === 0 ? 'active' : ''
          }`}
          onClick={() => setStep(0)}
        >
          <i className="mdi mdi-account-circle mr-2" /> Date Personale
        </button>
        <button
          className={`list-group-item list-group-item-action ${
            activeStep === 1 ? 'active' : ''
          }`}
          onClick={() => setStep(1)}
        >
          <i className="mdi mdi-briefcase mr-2" />
          Informatii Companie
        </button>
        <button
          className={`list-group-item list-group-item-action ${
            activeStep === 2 ? 'active' : ''
          }`}
          onClick={() => setStep(2)}
        >
          <i className="mdi mdi-cash-multiple mr-2" /> Tranzactii
        </button>
        <button
          className={`list-group-item list-group-item-action ${
            activeStep === 3 ? 'active' : ''
          }`}
          onClick={() => setStep(3)}
        >
          <i className="mdi mdi-alert mr-2" /> Notificari
        </button>
      </div>
    </div>
  );
};

export default ProfileMenu;
