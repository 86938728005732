import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { ILocation } from '@/types/location';
import brand from '@/brand';
import { createLocationRequest } from '@/api';
import { Container } from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import LocationForm from '../components/Summary/LocationForm';
import Loader from '@/components/Loader';
import { successAlert } from '@/helpers/alerts';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../../helpers/alerts';

const CreateLocationPage = () => {
  const navigate = useNavigate();
  const [location] = useState<ILocation>({
    longitude: 24.141839538761033,
    latitude: 45.78955562624801,
  } as ILocation);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (loc: ILocation) => {
    setLoading(true);
    createLocationRequest(loc)
      .then((res) => {
        if (res.data) {
          navigate(`/app/locations/${res.data.location.uuid}`);
          successAlert('Magazinul a fost modificat cu succes!');
        }
      })
      .catch((err) => {
        console.log(err);
        errorAlert('Eroare la salvarea magazinului!');
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {'Magazin Nou'} - {brand.name}
        </title>
      </Helmet>
      <div className="page-content text-white">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem={'Adauga Magazin'}
          />
          <LocationForm
            loading={loading}
            location={location}
            handleSubmit={handleSubmit}
          />
          <Loader loading={loading} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateLocationPage;
