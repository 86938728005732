import React, { useEffect } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
import brand from '../../../brand';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
interface IFormValues {
  email: string;
  password: string;
}

const validation = Yup.object().shape({
  email: Yup.string()
    .email('Email-ul nu este valid')
    .required('Email-ul este obligatoriu'),
  password: Yup.string(),
});

const Login = () => {
  const [error, setError] = React.useState('');
  const { login, setUser } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(''), 3000);
    }
  }, [error]);

  const handleSubmit = (
    values: IFormValues,
    { setFieldError, setSubmitting }: FormikHelpers<IFormValues>,
  ) => {
    setSubmitting(true);
    setError('');
    login(values.email, values.password)
      .then((res) => {
        setUser(res.data.user, res.data.token);
        navigate('/app');
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setError(err.response.data.message);
          for (const key in err.response.data.errors) {
            setFieldError(key, err.response.data.errors[key][0]);
          }
        } else {
          setError(err.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: process.env.REACT_APP_DEFAULT_EMAIL || '',
      password: process.env.REACT_APP_DEFAULT_PASS || '',
    },
    validationSchema: validation,
    onSubmit: handleSubmit,
  });

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <a href="/">
                        <img
                          src={brand.logo}
                          alt=""
                          height="40"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </a>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Bun venit!
                    </h4>
                    <p className="mb-5 text-center">
                      Trebuie sa te loghezi pentru a accesa {brand.name}.
                    </p>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit(e);
                        return false;
                      }}
                    >
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <FormFeedback
                                style={{ display: 'block' }}
                                type="invalid"
                              >
                                {formik.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>{formik.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                            <br />
                            <Row>
                              <Col>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-label form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </Col>
                              <Col className="col-7">
                                <div className="text-md-end mt-3 mt-md-0">
                                  <a
                                    href="/auth-recovery"
                                    className="text-muted"
                                  >
                                    <i className="mdi mdi-lock"></i> Forgot your
                                    password?
                                  </a>
                                </div>
                              </Col>
                              <div className="d-grid mt-4">
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                  disabled={formik.isSubmitting}
                                >
                                  Log In
                                </button>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
