import React from 'react';
import Helmet from 'react-helmet';
import brand from '@/brand';
import { Container } from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import LoyaltyCampaignsTable from '@/pages/User/LoyaltyCampaigns/components/LoyaltyCampaignsTable';

const LoyaltyCampaigns = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Bonus lunar - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                title: 'Bonus lunar',
                url: '/app/loyalty-campaigns',
              },
            ]}
            breadcrumbItem="Bonus lunar"
          />
        </Container>
        <LoyaltyCampaignsTable />
      </div>
    </React.Fragment>
  );
};

export default LoyaltyCampaigns;
