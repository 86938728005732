import React from 'react';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import { createTheme } from '@/api/appTheme';
import Swal from 'sweetalert2';
import CreateForm from './components/CreateForm';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  name: '',
  colors: {
    primary: '#0095DA',
    onPrimary: '#FFFFFF',
    secondary: '#FFDB00',
    onSecondary: '#000000',
    background: '#FFFFFF',
    surface: '#0095DA',
  },
  others: {
    card_puncte: {
      start: '#0095DA',
      end: '#19A7CE',
    },
    icon_oferte: {
      start: '#FC9000',
      end: '#FFCC00',
    },
    icon_magazine: {
      start: '#0095DA',
      end: '#19A7CE',
    },
    castiga_puncte: {
      start: '#FFB75E',
      end: '#ED8F03',
      titleColor: '#0095DA',
      subtitleColor: '#000',
      iconColor: '#ffffff',
    },
    masculin: {
      avatar: '#19A7CE',
      culoare: '#ffffff',
    },
    feminin: {
      avatar: '#19A7CE',
      culoare: '#ffffff',
    },
    profile: {
      nameColor: '#ffffff',
    },
    headers: {
      start: '#0095DA',
      end: '#19A7CE',
    },
  },
};

const CreateThemePage = () => {
  const navigation = useNavigate();
  const handleSubmit = (values: any) => {
    createTheme(values).then((res) => {
      navigation('/app/theme');
      Swal.fire({
        title: 'Tema creata!',
        text: 'Tema a fost creata cu succes.',
      });
    });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Adauga tema aplicatie - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Creare tema aplicatie"
          />
          <Card>
            <CardHeader className="bg-primary">
              <CardTitle className="text-white">Tema Noua</CardTitle>
            </CardHeader>
            <CardBody>
              <CreateForm
                initialValues={initialValues}
                handleSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateThemePage;
