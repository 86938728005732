import React from 'react';
import { IUser } from '@/types/user';
import { useFormik } from 'formik';
import { Col, Row } from 'reactstrap';
import FormikField from '@/components/FormikField';
import * as Yup from 'yup';
import { updateUserRequest } from '@/api';
import { successAlert } from '@/helpers/alerts';

interface IUserDetailsFormProps {
  user: IUser;
  setUser: (user: IUser) => void;
}

const validationSchema = Yup.object().shape({
  last_name: Yup.string().required('Numele este obligatoriu'),
  first_name: Yup.string().required('Prenumele este obligatoriu'),
  email: Yup.string()
    .email('Email invalid')
    .required('Email-ul este obligatoriu'),
});

const UserDetailsForm: React.FC<IUserDetailsFormProps> = ({
  user,
  setUser,
}) => {
  const formik = useFormik({
    initialValues: user,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserRequest(user.uuid, values)
        .then((res) => {
          setUser(res.data.user);
          successAlert('Datele au fost actualizate cu succes');
        })
        .catch((err) => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Date Personale</h4>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormikField
                      name="last_name"
                      formik={formik}
                      label="Nume"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="first_name"
                      formik={formik}
                      label="Prenume"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="email"
                      formik={formik}
                      label="Email"
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="business_email"
                      formik={formik}
                      label="Email companie"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="phone_no"
                      formik={formik}
                      label="Telefon"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="birth_date"
                      type="date"
                      formik={formik}
                      label="Data Nasterii"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="address"
                      formik={formik}
                      label="Adresa"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="sex"
                      formik={formik}
                      label="Sex"
                      type="select"
                    >
                      <option value="nespecificat">Nespecificat</option>
                      <option value="feminin">Feminin</option>
                      <option value="masculin">Masculin</option>
                    </FormikField>
                  </Col>
                </Row>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn btn-primary"
                >
                  Salveaza
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
