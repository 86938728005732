import React from 'react';
import { ILocation } from '@/types/location';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Button,
  CardFooter,
} from 'reactstrap';
import { useFormik } from 'formik';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as Yup from 'yup';
import FormikField from '../../../../../components/FormikField/index';
import LocationSchedule from './LocationSchedule';
// import "leaflet/dist/leaflet.css";

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Denumirea magazinului este obligatorie'),
  city: Yup.string().required('Orasul este obligatoriu'),
  latitude: Yup.number().required('Latitudinea este obligatorie'),
  longitude: Yup.number().required('Longitudinea este obligatorie'),
});

interface ILocationFormProps {
  location: ILocation;
  handleSubmit: (location: ILocation) => void;
  loading: boolean;
}

const LocationForm: React.FC<ILocationFormProps> = ({
  location,
  handleSubmit,
  loading,
}) => {
  const formik = useFormik({
    initialValues: location,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <React.Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <CardHeader className="bg-info">
              <CardTitle>Modifica Locatie</CardTitle>
            </CardHeader>
            <CardBody className="text-black">
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={12}>
                    <FormikField
                      name="name"
                      formik={formik}
                      label="Denumire Magazin"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="address"
                      formik={formik}
                      label="Adresa Magazin"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField name="city" formik={formik} label="Oras" />
                  </Col>

                  <Col md={6}>
                    <FormikField name="email" formik={formik} label="Email" />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="phone_no"
                      formik={formik}
                      label="Telefon"
                    />
                  </Col>

                  <Col md={12}>
                    <FormikField
                      name="description"
                      formik={formik}
                      label="Descriere"
                      type="textarea"
                      rows={4}
                    />
                  </Col>

                  <Col md={6}>
                    <FormikField
                      name="latitude"
                      formik={formik}
                      label="Latitudine"
                      type="number"
                      step={0.0001}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="longitude"
                      formik={formik}
                      label="Longitudine"
                      type="number"
                      step={0.0001}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikField
                      name="place_id"
                      formik={formik}
                      label="Google Place ID"
                      type="text"
                    />
                    <p className="text-black">
                      <a href="https://developers.google.com/maps/documentation/places/web-service/place-id">
                        Obtine Google Place ID
                      </a>
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end" md={12}>
                    <Button
                      disabled={loading}
                      type="submit"
                      className="btn btn-success"
                    >
                      Salvează
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardHeader className="bg-danger">
              <CardTitle>Pozitie Harta</CardTitle>
            </CardHeader>
            <CardBody style={{ minHeight: 400 }} className="text-black">
              <MapContainer
                // @ts-ignore
                center={
                  [formik.values.latitude, formik.values.longitude] as any
                }
                zoom={15}
                style={{ height: '400px', width: '100%' }}
              >
                <TileLayer
                  // @ts-ignore
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[formik.values.latitude, formik.values.longitude]}
                  eventHandlers={{
                    dragend: (e: any) => {
                      const lat = e.target._latlng.lat;
                      const lng = e.target._latlng.lng;
                      formik.setFieldValue('latitude', lat, false);
                      formik.setFieldValue('longitude', lng, false);
                    },
                  }}
                  // @ts-ignore
                  draggable={true}
                >
                  <Popup>
                    {formik.values.name} {formik.values.address}
                  </Popup>
                </Marker>
              </MapContainer>
            </CardBody>
            <CardFooter>
              <p className="text-black">
                Muta markerul pe harta pentru a seta locatia
              </p>
            </CardFooter>
          </Card>
        </Col>
        <Col md={3}>
          <LocationSchedule schedule={location.opening_hours} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LocationForm;
