import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import SalesFilters from '@/pages/User/Reports/components/SalesFilters';
import {
  Card,
  CardHeader,
  CardTitle,
  Container,
  CardBody,
  Button,
} from 'reactstrap';
import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getTransactionsReport } from '@/api/customReports';
import Loader from '@/components/Loader';
import EmptyState from '@/components/EmptyState';
import DataTable from 'react-data-table-component';
import PaginationComponent from '@/components/Paginate';
import { format_currency } from '@/helpers/numbers';
import { downloadCSV } from '@/helpers/csv-utils';
import { Link } from 'react-router-dom';

const columns = [
  {
    name: 'ID',
    label: 'user_id',
    selector: (row: any) => row.user_id,
    sortable: true,
  },
  {
    name: 'Nume utilizator',
    label: 'user_name',
    selector: (row: any) => row?.user_name,
    // row?.user ? row?.user?.first_name + ' ' + row?.user?.last_name : row.name,
    format: (row: any) => (
      <Link to={`/app/users/${row.user?.uuid}`}>{row?.user_name}</Link>
    ),
    sortable: true,
  },
  {
    name: 'Average spent',
    label: 'avg_amount',
    selector: (row: any) => format_currency(row.avg_amount),
  },
  {
    name: 'Min spent',
    label: 'min_amount',
    selector: (row: any) => format_currency(row.min_amount),
  },
  {
    name: 'Max spent',
    label: 'max_amount',
    selector: (row: any) => format_currency(row.max_amount),
    sortable: true,
  },
  {
    name: 'Total spent',
    label: 'total_amount',
    selector: (row: any) => format_currency(row.total_amount),
    sortable: true,
  },
  {
    name: 'Tranzactii',
    label: 'total_transactions',
    selector: (row: any) => row.total_transactions,
    sortable: true,
  },
  {
    name: 'Magazin',
    selector: (row: any) => row?.location_name,
  },
];

const exportColumns = [
  'user_id',
  'user_name',
  'avg_amount',
  'min_amount',
  'max_amount',
  'total_amount',
  'total_transactions',
  'location_name',
];

const ExportButton = ({ onExport }) => (
  <Button color={'warning'} onClick={(e: any) => onExport(e.target?.value)}>
    Export
  </Button>
);

const LocationUsers = () => {
  const {
    loading,
    salesData,
    page,
    perPage,
    total,
    changePage,
    changePerPage,
    changeSort,
    getDataForExport,
    filtersToString,
    salesQuery,
  } = useSalesReportsHook(getTransactionsReport);

  const handleSort = (column: (typeof columns)[0], sortDirection: string) => {
    changeSort(column.label, sortDirection);
  };

  const exportReportData = async () => {
    const response = await getDataForExport();
    downloadCSV(response.data.sales.data, exportColumns, filtersToString());
  };

  useEffect(() => {
    salesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Raport vanzari - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Raport tranzactii"
          />
          <SalesFilters getDataMethod={getTransactionsReport} />
          <Card>
            <CardHeader className="bg-info d-flex justify-content-between">
              <CardTitle className="text-white">Raport tranzactii</CardTitle>
              <ExportButton onExport={() => exportReportData()} />
            </CardHeader>
            <CardBody className="card-body">
              {loading && <Loader loading={loading} />}
              {!loading && salesData?.length === 0 && (
                <EmptyState
                  title="Nu exista date"
                  description="Nu s-au gasit date pentru filtrele selectate."
                />
              )}
              {!loading && salesData?.length > 0 && (
                <>
                  <DataTable
                    columns={columns}
                    data={salesData}
                    dense
                    onSort={handleSort}
                    sortServer={true}
                    defaultSortFieldId={'user_id'}
                  />
                  <div className="d-flex w-100 align-items-center p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <PaginationComponent
                        page={page}
                        perPage={perPage}
                        total={total}
                        onClick={(p) => changePage(p)}
                      />
                      <div
                        className={`form-group d-flex align-items-center justify-content-between ${
                          total < 11 ? 'd-none' : ''
                        }`}
                      >
                        <span>Afiseaza</span>
                        <select
                          className="form-control m-0 ml-2 mr-2"
                          id="perPage"
                          style={{ width: '50px' }}
                          value={perPage}
                          onChange={(e) =>
                            changePerPage(parseInt(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                        <span>
                          {'   '} - pe pagina din
                          <strong className={'p-1'}>{total}</strong>
                          inregistrari
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LocationUsers;
