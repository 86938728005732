import { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';

const ImportGroup = ({
  initialValues,
  onSubmit,
  id,
  open,
  handleClose,
}: any) => {
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleFileChange = (ev: any) => {
    setFile(ev.target.files[0]);
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    onSubmit(formData).then(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <Modal isOpen={open} toggle={() => handleClose()}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Importa Grup</h5>
        <Button type="button" className="close" onClick={() => handleClose()}>
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
      <div className="modal-body">
        <i>
          Fisierul de import trebuie sa fie csv. Prima line din csv o sa fie
          considerata numele grupului, iar celelalte linii or sa fie considerate
          utilizatori.
        </i>
        <input
          type="file"
          onChange={handleFileChange}
          name="file"
          title="File"
        />
      </div>
      <div className="modal-footer">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          type="button"
          className="btn btn-info"
        >
          Importa
        </Button>
        <Button
          onClick={() => handleClose()}
          disabled={loading}
          type="button"
          className="btn btn-secondary"
        >
          Inchide
        </Button>
      </div>
    </Modal>
  );
};

ImportGroup.defaultProps = {
  initialValues: {
    name: '',
  },
  onSubmit: () => {},
};
export default ImportGroup;
