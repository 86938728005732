import AsyncSelect from 'react-select/async';
import { searchGroup } from '@/api/autocomplete';
import { useEffect, useState } from 'react';

let timeout: any = null;
const SearchGroup = ({ onPick, value }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>(value);

  useEffect(() => {
    if (options.length > 0 && value !== selected)
      setSelected(options.find((o: any) => o.value === value));
  }, [options, value, selected]);
  return (
    <div className="form-group">
      <label htmlFor="group">Alege grup</label>
      <AsyncSelect
        cacheOptions
        isClearable
        defaultOptions
        isMulti
        value={value}
        loadOptions={(inputValue: any, callback: any) => {
          if (inputValue && timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            searchGroup({ search: inputValue }).then((response: any) => {
              callback(response.data);
              setOptions(response.data);
            });
          }, 500);
        }}
        onChange={(value: any) => onPick(value)}
      />
    </div>
  );
};

export default SearchGroup;
