import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import useDashboard from '@/hooks/useDashoard';
import dayjs from 'dayjs';

const LatestTransactions = () => {
  const { transactions, getLatestTransactions } = useDashboard();

  useEffect(() => {
    getLatestTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Ultimele Tranzactii</CardTitle>
          <Table responsive>
            <thead>
              <tr>
                <td>#</td>
                <td>Utilizator</td>
                <td>Location</td>
                <td>Amount</td>
                <td>Points</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {transactions?.length > 0 ? (
                <>
                  {transactions.map((transaction: any) => (
                    <tr key={`transaction-${transaction.id}`}>
                      <td>{transaction.id}</td>
                      <td>
                        <Link to={`/app/users/${transaction.user_uuid}`}>
                          {transaction.user}
                        </Link>
                      </td>
                      <td>{transaction.location}</td>
                      <td>{transaction.amount} Ron</td>
                      <td>{transaction.points} Puncte</td>
                      <td>
                        {dayjs(transaction.created_at).format(
                          'DD/MM/YYYY HH:mm',
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>Nu exista tranzactii</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-center" colSpan={6}>
                  <Link to="/app/">Vezi toate tranzactiile</Link>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LatestTransactions;
