import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

const GroupUsersTable = ({ users, onRemove }: any) => {
  const RenderActions = (row: any) => {
    return (
      <div className="d-flex">
        <Link
          to={`/app/users/${row.uuid}`}
          className="me-2 p-2 align-items-center"
          target="_blank"
        >
          <i className="fas fa-eye"></i>
        </Link>
        <a
          role={'button'}
          onClick={() => onRemove(row.id)}
          className={'text-danger p-2'}
        >
          <i className="fas fa-trash"></i>
        </a>
      </div>
    );
  };
  const columns = useMemo(() => {
    return [
      {
        keyField: 'id',
        name: '#',
        width: '5%',
        selector: (row: any, index: any) => {
          return index + 1;
        },
        sortable: false,
      },
      {
        keyField: 'name',
        name: 'name',
        selector: (row: any) => row.first_name + ' ' + row.last_name,
        sortable: true,
        width: '30%',
      },
      {
        keyField: 'members',
        name: 'Email',
        selector: (row: any) => row.email,
        sortable: true,
        width: '30%',
      },
      {
        keyField: 'members',
        name: 'Phone',
        selector: (row: any) => row.phone_no,
        sortable: true,
        width: '25%',
      },
      {
        keyField: 'actions',
        name: 'Actiuni',
        selector: RenderActions,
        sortable: false,
        width: '10%',
      },
    ];
  }, []);

  return (
    <DataTable
      pagination
      paginationPerPage={30}
      columns={columns}
      data={users}
    />
  );
};

export default GroupUsersTable;
