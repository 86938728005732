import React from 'react';
import { Button, FormGroup, Form, Alert, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import FormikField from '@/components/FormikField';
import * as Yup from 'yup';
import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import { IMilestone } from '@/types/loyalty-campaign';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele pragului este obligatoriu'),
  number: Yup.number().required('Numarul pragului este obligatoriu'),
  reward_description: Yup.string().required(
    'Descrierea premiului este obligatorie',
  ),
  required_amount: Yup.number()
    .min(
      10,
      'Pragul necesar pentru premiu trebuie sa fie mai mare sau egal cu 10',
    )
    .required('Pragul necesar pentru premiu este obligatoriu'),
  reward_points: Yup.number()
    .min(0, 'Punctele bonus trebuie sa fie mai mari sau egale cu 0')
    .max(10000) // @TODO Ask about the max value
    .required('Punctele bonus sunt obligatorii'),
});

const MilestoneForm = ({
  submitAction,
  milestone,
}: {
  milestone?: IMilestone | null;
  submitAction: (value: any) => any; // @TODO find a way to type this
}) => {
  const initialValues: IMilestone = {
    name: milestone?.name || '',
    number: milestone?.number || 1,
    reward_description: milestone?.reward_description || '',
    required_amount: milestone?.required_amount || 10,
    reward_points: milestone?.reward_points || 0,
  };
  const { milestones } = useLoyaltyCampaign();
  const disableButton = milestones.length === 3;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // dispatch to add milestone to state
      const response = await submitAction(values);
      if (!response?.error) {
        resetForm();
      }
      setSubmitting(false);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Row>
          <Col>
            <FormikField
              id="milestoneName"
              name="name"
              type="text"
              label={'Numele pragului'}
              formik={formik}
            />
          </Col>
          <Col md={3}>
            <FormikField
              formik={formik}
              name={'number'}
              label={'Numar prag'}
              type={'number'}
              id={'number'}
              min={1}
              max={3}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <FormikField
          id="reward_description"
          name="reward_description"
          label={'Descriere premiu'}
          type="textarea"
          formik={formik}
        />
      </FormGroup>
      <FormGroup>
        <FormikField
          id="required_amount"
          name="required_amount"
          type="number"
          label={'Pragul necesar pentru premiu (RON)'}
          formik={formik}
        />
      </FormGroup>
      <FormGroup>
        <FormikField
          label={'Puncte bonus la atingerea pragului'}
          id="reward_points"
          name="reward_points"
          type="number"
          formik={formik}
        />
      </FormGroup>

      <Button type={'submit'} color={'primary'} disabled={disableButton}>
        Salveaza
      </Button>

      {disableButton && (
        <Alert color={'info'} className="mt-2">
          Ai adaugat deja 3 praguri
        </Alert>
      )}
    </Form>
  );
};

export default MilestoneForm;
