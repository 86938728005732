import React from 'react';
import {
  Button,
  Modal as ModalBootstrap,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

interface IModalGProps {
  open: boolean;
  title: string;
  toggle: () => void;
  children: React.ReactNode;
  className?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  successLabel?: string;
  cancelLabel?: string;
  fullscreen?: any;
}

const Modal: React.FC<IModalGProps> = ({
  className,
  children,
  open,
  title,
  toggle,
  onCancel,
  onConfirm,
  successLabel,
  cancelLabel,
  fullscreen,
}) => {
  return (
    <React.Fragment>
      <ModalBootstrap
        fullscreen={fullscreen}
        isOpen={open}
        toggle={toggle}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {onConfirm && (
            <Button color="primary" onClick={onConfirm}>
              {successLabel || 'Confirma'}
            </Button>
          )}{' '}
          {onCancel && (
            <Button color="secondary" onClick={onCancel}>
              {cancelLabel || 'Anuleaza'}
            </Button>
          )}
        </ModalFooter>
      </ModalBootstrap>
    </React.Fragment>
  );
};

export default Modal;
