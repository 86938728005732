import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import Swal from 'sweetalert2';
import GroupModalForm from '../Components/GroupModalForm';
import {
  copyGroupRequest,
  createGroupRequest,
  deleteGroupRequest,
  getGroupsRequest,
  importFromCSVRequest,
  updateGroupRequest,
} from '../api';
import UserGroupList from '../Components/UserGroupList';
import { successAlert } from '@/helpers/alerts';
import ImportGroup from '../Components/ImportGroup';

const AllGroupsPage = () => {
  const [groups, setGroups] = useState<any>([]);
  const [group, setGroup] = useState<any>(null);

  const [impOpen, setImpOpen] = useState<boolean>(false);

  const getData = () => {
    getGroupsRequest().then((res) => {
      setGroups(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (values: any) => {
    if (values.id) {
      updateGroupRequest(values.id, values).then(() => {
        getData();
        setGroup(null);
        successAlert('Grupul a fost modificat cu succes');
      });
    } else {
      createGroupRequest(values).then(() => {
        getData();
        setGroup(null);
        successAlert('Grupul a fost adaugat cu succes');
      });
    }
  };

  const handleDelete = (id: any) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Grupul va fi sters',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((res) => {
      if (res.isConfirmed) {
        // delete
        deleteGroupRequest(id).then(() => {
          getData();
          successAlert('Grupul a fost sters cu succes');
        });
      }
    });
  };

  const handleImport = async (data: any) => {
    return new Promise((resolve, reject) => {
      importFromCSVRequest(data)
        .then(() => {
          resolve(true);
          successAlert('Grupul a fost importat cu succes');
          getData();
        })
        .catch(() => {
          reject(false);
        });
    });
  };

  const handleCopy = (id: any) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Grupul va fi copiat',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((res) => {
      if (res.isConfirmed) {
        copyGroupRequest(id).then(() => {
          getData();
          successAlert('Grupul a fost copiat cu succes');
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Grupuri - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem={`Grupuri - ${brand.shortName}`}
            bread={[{ url: '/app/groups', title: `Grupuri` }]}
          />
          <Card>
            <CardHeader className="bg-secondary d-flex justify-content-between align-items-center">
              <CardTitle className="text-white">Adauga Grup</CardTitle>
              <div>
                <Button
                  onClick={() => setImpOpen(true)}
                  className="btn btn-warning mr-2"
                >
                  Importa Grup
                </Button>
                <Button
                  onClick={() => {
                    setGroup({
                      name: '',
                    });
                  }}
                  className="btn btn-info ms-2"
                >
                  Adauga Group
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <UserGroupList
                onEdit={(g: any) => setGroup(g)}
                groups={groups}
                handleDelete={handleDelete}
                handleCopy={handleCopy}
              />
            </CardBody>
          </Card>
        </Container>
        {group !== null && (
          <GroupModalForm
            dbData={group}
            onSubmit={handleSubmit}
            open={true}
            handleClose={() => setGroup(null)}
            id={group?.id}
          />
        )}

        {impOpen && (
          <ImportGroup
            open={impOpen}
            onSubmit={handleImport}
            handleClose={() => setImpOpen(false)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AllGroupsPage;
