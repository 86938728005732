import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Col, Container, Input, Modal, Row, Table } from 'reactstrap';
import Th from '@/components/Th';
import PaginationComponent from '@/components/Paginate';
import { IProduct } from '@/types/product';
import useProducts from '@/hooks/useProducts';
import { Link } from 'react-router-dom';
import ProductForm from '../components/Summary/ProductForm';
import CategoryPicker from '@/components/CategoryPicker';

const AllProductsPage = () => {
  const {
    loading,
    data,
    sort,
    page,
    order,
    changeSort,
    changePerPage,
    perPage,
    total,
    search,
    changePage,
    changeSearch,
    filters,
    changeFilter,
  } = useProducts();
  const [open, setOpen] = React.useState(false);
  const renderRows = () =>
    data.map((product: IProduct, index: number) => (
      <tr key={`${index}-product`}>
        <td>{product.id}</td>
        <td>{product.code}</td>
        <td>{product.name}</td>
        <td>{product.price} RON</td>
        <td>
          {product.category_data ? product.category_data.name : ''} (
          {product.category})
        </td>
        <td>{product?.transaction_products?.[0]?.purchased_count}</td>
        <td className="d-flex justify-content-end">
          <Link
            to={`/app/products/${product.id}`}
            className="btn btn-sm btn-primary"
          >
            Modificare
          </Link>
        </td>
      </tr>
    ));

  return (
    <React.Fragment>
      <Helmet>
        <title>Produse - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Produse" />
          <div className="card">
            <div className="card-body">
              <div className="card-title">Produse Sory</div>
              <Row>
                <Col>
                  <div className="form-group">
                    <button
                      onClick={() => setOpen(true)}
                      className="btn btn-primary"
                    >
                      Adauga
                    </button>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <CategoryPicker
                      value={filters.category}
                      onPick={(value: any) => changeFilter('category', value)}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label>Cautare</label>
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Cauta..."
                      value={search}
                      onChange={(e) => changeSearch(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <Th
                      prop="name"
                      order={order}
                      width="40px"
                      sort={sort}
                      onClick={changeSort}
                    >
                      Cod
                    </Th>
                    <Th
                      prop="name"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Nume
                    </Th>
                    <Th
                      prop="price"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Pret
                    </Th>
                    <Th
                      prop="category"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Categorie
                    </Th>
                    <th>Numar vanzari</th>
                    <th className="d-flex justify-content-end">Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={6}>Loading...</td>
                    </tr>
                  )}
                  {renderRows()}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center align-items-center p-3">
              <PaginationComponent
                page={page}
                perPage={perPage}
                total={total}
                onClick={(p) => changePage(p)}
              />
              <div
                className={`form-group d-flex align-items-center ${
                  total < 11 ? 'd-none' : ''
                }`}
              >
                <select
                  className="form-control"
                  id="perPage"
                  style={{ width: '40px' }}
                  value={perPage}
                  onChange={(e) => changePerPage(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
                {'   '} - pe pagina din {total}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Adauga produs</h5>
          <button
            type="button"
            className="close"
            onClick={() => setOpen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ProductForm
            initialValues={{}}
            onSubmit={() => {
              setOpen(false);
              changeSearch('');
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AllProductsPage;
