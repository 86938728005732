import React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import FormikFieldObj from '@/components/FormikFieldObj';

const CreateForm = ({ initialValues, handleSubmit }: any) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={4}>
            <Card outline={true} className="">
              <CardHeader className="card-info">
                <CardTitle>Setari Tema</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Denumire tema"
                    type="text"
                    name="name"
                  />
                  {formik.values.colors.primary}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Culoare Primara"
                    type="color"
                    name="colors.primary"
                  />
                  {formik.values.colors.primary}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Text peste culoarea primara"
                    type="color"
                    name="colors.onPrimary"
                  />
                  {formik.values.colors.onPrimary}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Culoare Secundara"
                    type="color"
                    name="colors.secondary"
                  />
                  {formik.values.colors.secondary}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Text peste culoarea secundara"
                    type="color"
                    name="colors.onSecondary"
                  />
                  {formik.values.colors.onSecondary}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Background"
                    type="color"
                    name="colors.background"
                  />
                  {formik.values.colors.background}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    label="Surface"
                    required={true}
                    type="color"
                    name="colors.surface"
                  />
                  {formik.values.colors.surface}
                </div>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-info">
                <CardTitle>Culoare Headere (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="Start"
                    type="color"
                    name="others.headers.start"
                  />
                  {formik.values.others.headers.start}
                </div>
                <div className="mb-3">
                  <FormikFieldObj
                    formik={formik}
                    required={true}
                    label="End"
                    type="color"
                    name="others.headers.end"
                  />
                  {formik.values.others.headers.end}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card outline={true} className="">
              <CardHeader className="card-success">
                <CardTitle>Card de puncte - home (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Inceput"
                      type="color"
                      name="others.card_puncte.start"
                    />
                    {formik.values.others.card_puncte.start}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Sfarsit"
                      type="color"
                      name="others.card_puncte.end"
                    />
                    {formik.values.others.card_puncte.end}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-success">
                <CardTitle>Card de puncte - home (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Inceput"
                      type="color"
                      name="others.card_puncte.start"
                    />
                    {formik.values.others.card_puncte.start}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Sfarsit"
                      type="color"
                      name="others.card_puncte.end"
                    />
                    {formik.values.others.card_puncte.end}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-success">
                <CardTitle>Recompense - home (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Inceput"
                      type="color"
                      name="others.castiga_puncte.start"
                    />
                    {formik.values.others.castiga_puncte.start}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Sfarsit"
                      type="color"
                      name="others.castiga_puncte.end"
                    />
                    {formik.values.others.castiga_puncte.end}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Culoare Titlu"
                      type="color"
                      name="others.castiga_puncte.titleColor"
                    />
                    {formik.values.others.castiga_puncte.titleColor}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Culoare Subtitlu si desc. taskuri"
                      type="color"
                      name="others.castiga_puncte.subtitleColor"
                    />
                    {formik.values.others.castiga_puncte.subtitleColor}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Culoare Icon"
                      type="color"
                      name="others.castiga_puncte.iconColor"
                    />
                    {formik.values.others.castiga_puncte.iconColor}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card outline={true} className="">
              <CardHeader className="card-info">
                <CardTitle>Icon Oferte - home (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Inceput"
                      type="color"
                      name="others.icon_oferte.start"
                    />
                    {formik.values.others.icon_oferte.start}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Sfarsit"
                      type="color"
                      name="others.icon_oferte.end"
                    />
                    {formik.values.others.icon_oferte.end}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-info">
                <CardTitle>Icon Magazine - home (gradient)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Inceput"
                      type="color"
                      name="others.icon_magazine.start"
                    />
                    {formik.values.others.icon_magazine.start}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Sfarsit"
                      type="color"
                      name="others.icon_magazine.end"
                    />
                    {formik.values.others.icon_magazine.end}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-warning">
                <CardTitle>Gen Masculin - Profil</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Icon"
                      type="color"
                      name="others.masculin.avatar"
                    />
                    {formik.values.others.masculin.avatar}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Text"
                      type="color"
                      name="others.masculin.culoare"
                    />
                    {formik.values.others.masculin.culoare}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-warning">
                <CardTitle>Gen Feminin - Profil</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Icon"
                      type="color"
                      name="others.feminin.avatar"
                    />
                    {formik.values.others.feminin.avatar}
                  </Col>
                  <Col md={6}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Text"
                      type="color"
                      name="others.feminin.culoare"
                    />
                    {formik.values.others.feminin.culoare}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card outline={true} className="">
              <CardHeader className="card-warning">
                <CardTitle>Text Nume - Profil</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <FormikFieldObj
                      formik={formik}
                      required={true}
                      label="Culoare Text"
                      type="color"
                      name="others.profile.nameColor"
                    />
                    {formik.values.others.profile.nameColor}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="d-flex justify-content-end" md={12}>
            <Button type="submit" color="primary">
              Salveaza
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default CreateForm;
