import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICategoriesState {
  loading: boolean;
  search: string;
  filters: any;
  page: number;
  sort: string;
  order: 'asc' | 'desc';
  perPage: number;
  total: number;
  data: any[];
}

const initialState: ICategoriesState = {
  loading: true,
  search: '',
  filters: {},
  sort: 'id',
  order: 'asc',
  page: 1,
  perPage: 10,
  total: 0,
  data: [],
};

const categoriesSlice = createSlice({
  name: 'gesp/categories',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.perPage = action.payload.per_page;
      state.loading = false;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload.sort;
      state.order = action.payload.order;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { setData, setSort, setOrder, setPage, setPerPage, setSearch } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
