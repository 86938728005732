import axios from '../../../api/axios';

export const getGroupsRequest = () => axios.get('/user-groups');
export const getGroupRequest = (id: string) => axios.get(`/user-groups/${id}`);
export const createGroupRequest = (data: any) =>
  axios.post('/user-groups', data);
export const updateGroupRequest = (id: string, data: any) =>
  axios.post(`/user-groups/${id}`, data);
export const deleteGroupRequest = (id: string) =>
  axios.delete(`/user-groups/${id}`);

export const addUserToGroupRequest = (id: string, data: any) => {
  return axios.post(`/user-groups/${id}/add-user`, data);
};

export const removeUserFromGroupRequest = (id: string, data: any) => {
  return axios.post(`/user-groups/${id}/remove-user`, data);
};

export const importFromCSVRequest = (data: any) => {
  return axios.post('/user-groups/create-from-csv', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const copyGroupRequest = (id: string) => {
  return axios.post(`/user-groups/copy/${id}`);
};

export const deleteCriteriaRequest = (id: string) => {
  return axios.delete(`/user-groups/criteria/${id}`);
};
