import React from 'react';
import LocationPicker from '@/components/LocationPicker';
import UserPicker from '@/components/UserPicker';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';
import useSalesReportsHook from '@/hooks/useSalesReportsHook';
import { getSalesReport } from '@/api/reports';

const SalesFilters = ({
  getDataMethod = getSalesReport,
}: {
  getDataMethod?: (params: any) => Promise<any>;
}) => {
  const { changeFilter, resetFilters, filters, applyFilters, loading } =
    useSalesReportsHook(getDataMethod);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="card-primary">
          <CardTitle tag="h4">
            <i className="mdi mdi-filter" /> Filtre
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <UserPicker
                onPick={(id: any) => changeFilter('user_id', id)}
                value={filters.user_id}
              />
            </Col>
            <Col md={3}>
              <LocationPicker
                selectedLocation={filters.location_id}
                onPick={(id: number) => changeFilter('location_id', id)}
              />
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="data">De la</label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => changeFilter('startDate', e.target.value)}
                  className="form-control"
                  id="data"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="end_date">Pana la</label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => changeFilter('endDate', e.target.value)}
                  className="form-control"
                  id="end_date"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="account_type">Firma/PF</label>
                <select
                  className="form-control"
                  id="account_type"
                  value={filters.account_type}
                  defaultValue={filters.account_type}
                  onChange={(e) => changeFilter('account_type', e.target.value)}
                >
                  <option value="">Toate</option>
                  <option value="0">Persoana Fizica</option>
                  <option value="1">Persoana Juridica</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="sex">Sex</label>
                <Input
                  type="select"
                  name="sex"
                  value={filters.sex}
                  onChange={(e) => changeFilter('sex', e.target.value)}
                >
                  <option value="">Toate</option>
                  <option value="feminin">Feminin</option>
                  <option value="masculin">Masculin</option>
                  <option value="nespecificat">Nespecificat</option>
                </Input>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="tip_obtinere">Tip Vanzare</label>
                <Input
                  value={filters.type}
                  defaultValue={filters.type}
                  onChange={(e) => changeFilter('type', e.target.value)}
                  type="select"
                  name="select"
                  id="tip_obtinere"
                >
                  <option value="">Toate</option>
                  <option value="EXPENSE">Folosire Puncte</option>
                  <option value="INCOME">Obtinere Puncte</option>
                </Input>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="tip_obtinere">Mod obtinere</label>
                <Input
                  value={filters.description}
                  defaultValue={filters.description}
                  onChange={(e) => changeFilter('description', e.target.value)}
                  type="select"
                  name="description"
                  id="description"
                >
                  <option value="">Toate</option>
                  <option value="REDEEM">Folosire Puncte</option>
                  <option value="PURCHASE">Bonus vanzare</option>
                  <option value="WELCOME_BONUS">Bonus install app</option>
                  <option value="ADMIN_ADD">Admin</option>
                  <option value="fb_like">Facebook like</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="puncte_min">Minim Puncte</label>
                <InputGroup>
                  <InputGroupText>{'Puncte >='}</InputGroupText>
                  <Input
                    onChange={(event) =>
                      changeFilter('min_points', event.target.value)
                    }
                    value={filters.min_points}
                    placeholder="Minim de puncte"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col md={3} className={'flex'}>
              <div className="form-group">
                <label htmlFor="puncte_max">Maxim Puncte</label>
                <InputGroup>
                  <InputGroupText>{'Puncte <='}</InputGroupText>
                  <Input
                    onChange={(event) =>
                      changeFilter('max_points', event.target.value)
                    }
                    value={filters.max_points}
                    placeholder="Maxim de puncte"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="puncte_min">Valoare achizitie Minima</label>
                <InputGroup>
                  <InputGroupText>{'Total >='}</InputGroupText>
                  <Input
                    onChange={(event) =>
                      changeFilter('min_value', event.target.value)
                    }
                    value={filters.min_value}
                    placeholder="Achizitie Minima"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="puncte_max">Valoare achizitie Maxima</label>
                <InputGroup>
                  <InputGroupText>{'Total <='}</InputGroupText>
                  <Input
                    onChange={(event) =>
                      changeFilter('max_value', event.target.value)
                    }
                    value={filters.max_value}
                    placeholder="Achizitie Maxima"
                    type="number"
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col />
            <Col md={2}>
              <Button
                onClick={applyFilters}
                color="primary"
                block
                disabled={loading}
              >
                Aplica filtre
              </Button>
            </Col>
            <Col md={2}>
              <Button onClick={resetFilters} color="primary" outline block>
                Sterge Filtre
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesFilters;
