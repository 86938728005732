import React from 'react';
import { Link } from 'react-router-dom';

const EmptyState = ({
  title,
  description,
  buttonUrl,
  actionLabel,
}: {
  title: string;
  description: string;
  buttonUrl?: string;
  actionLabel?: string;
}) => {
  return (
    <div
      className={'align-content-center align-items-center d-flex flex-column'}
    >
      <h5>{title}</h5>
      {!!description && <p>{description}</p>}
      {!!buttonUrl && (
        <Link className="btn btn-primary" to={buttonUrl}>
          {actionLabel || 'Adauga'}
        </Link>
      )}
    </div>
  );
};

export default EmptyState;
