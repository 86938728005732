import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import Modal from '@/components/Modal';
import TransactionDisplay from './TransactionDisplay';
import { Link } from 'react-router-dom';
import Th from '@/components/Th';

interface IUserTransactionsProps {
  transactions: any;
  showUser?: boolean;
  sort?: any;
  order?: any;
  changeSort?: (sort: string) => void;
}

const UserTransactions: React.FC<IUserTransactionsProps> = ({
  transactions,
  showUser,
  sort,
  order,
  changeSort,
}) => {
  const [selected, setSelected] = useState<any>(null);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Tranzactii</CardTitle>
          <Table responsive>
            <thead>
              <tr>
                <Th sort={sort} order={order} onClick={changeSort} prop="id">
                  #id
                </Th>
                {showUser && (
                  <Th
                    sort={sort}
                    order={order}
                    onClick={changeSort}
                    prop="user_id"
                  >
                    Utilizator
                  </Th>
                )}
                <Th
                  sort={sort}
                  order={order}
                  onClick={changeSort}
                  prop="location_id"
                >
                  Magazin
                </Th>
                <Th sort={sort} order={order} onClick={changeSort} prop="type">
                  Tip
                </Th>
                <Th
                  sort={sort}
                  order={order}
                  onClick={changeSort}
                  prop="amount"
                >
                  Suma
                </Th>
                <Th
                  sort={sort}
                  order={order}
                  onClick={changeSort}
                  prop="points"
                >
                  Points
                </Th>
                <Th
                  sort={sort}
                  order={order}
                  onClick={changeSort}
                  prop="description"
                >
                  Eveniment
                </Th>
                <Th prop={'details'}>Detalii</Th>

                {showUser && (
                  <Th
                    sort={sort}
                    order={order}
                    onClick={changeSort}
                    prop="participation"
                  >
                    Participari
                  </Th>
                )}
                <Th
                  sort={sort}
                  order={order}
                  onClick={changeSort}
                  prop="created_at"
                >
                  Data
                </Th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction: any, index: number) => (
                <tr key={`row-${index}`}>
                  <th scope="row">{transaction.id}</th>
                  {showUser && (
                    <td>
                      <Link
                        to={`/app/users/${transaction?.user?.uuid}`}
                        className="text-decoration-none"
                      >
                        {transaction?.user?.first_name}{' '}
                        {transaction?.user?.last_name}
                      </Link>
                    </td>
                  )}
                  <td>{transaction?.location?.name}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.amount}</td>
                  <td>{Number(transaction.points).toFixed(2)}</td>
                  <td>{transaction.description}</td>
                  <td>{transaction.details}</td>
                  {showUser && <td>{transaction.participation}</td>}
                  <td>
                    {dayjs(transaction.created_at).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td>
                    {['PURCHASE'].includes(transaction.description) && (
                      <button
                        onClick={() => setSelected(transaction)}
                        className="btn btn-sm btn-primary"
                      >
                        Detalii
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {selected && (
        <Modal
          title={
            selected.type === 'PURCHASE'
              ? 'Cumparare'
              : 'Rascumparare folosind puncte'
          }
          open={!!selected}
          toggle={() => setSelected(null)}
          fullscreen="xl"
        >
          <TransactionDisplay transaction={selected} />
        </Modal>
      )}
    </React.Fragment>
  );
};

UserTransactions.defaultProps = {
  sort: 'id',
  order: 'desc',
  changeSort: () => {},
};

export default UserTransactions;
