import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import { useParams } from 'react-router-dom';

import {
  addUserToGroupRequest,
  getGroupRequest,
  removeUserFromGroupRequest,
} from '../api';
import { errorAlert, successAlert } from '@/helpers/alerts';
import UserPicker from '@/components/UserPicker';
import GroupUsersTable from '../Components/GroupUsersTable';
import Swal from 'sweetalert2';
import Modal from '@/components/Modal';

const GroupPreviewPage = () => {
  const [group, setGroup] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [showCriteria, setShowCriteria] = useState(false);

  const toggleCriteria = () => {
    setShowCriteria(!showCriteria);
  };

  const getData = () => {
    getGroupRequest(id || '').then((res) => {
      setGroup(res.data);
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUser = (userId: any) => {
    setLoading(true);
    addUserToGroupRequest(id as any, { userId })
      .then(() => {
        getData();
        successAlert('Utilizatorul a fost adaugat cu succes');
      })
      .catch((e: any) => {
        errorAlert(e.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveUser = (userId: any) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Utilizatorul va fi sters din grup',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((res) => {
      if (res.isConfirmed) {
        setLoading(true);
        removeUserFromGroupRequest(id as any, {
          userId,
        })
          .then(() => {
            getData();
            successAlert('Utilizatorul a fost scos din grup cu succes');
          })
          .catch((e: any) => {
            errorAlert(e.response.data.message);
          })
          .finally(() => setLoading(false));
      }
    });
  };
  const { users } = group || {};
  return (
    <React.Fragment>
      <Helmet>
        <title>Grupuri - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem={`Grupuri - ${brand.shortName}`}
            bread={[
              { url: '/app/groups', title: `Grupuri` },
              { title: 'Grup' },
            ]}
          />
          <Card>
            <CardHeader className="bg-info d-flex justify-content-between align-items-center">
              <CardTitle className="text-white">
                {group?.name}
                {group?.type === 'automatic' && (
                  <Button
                    size={'sm'}
                    color={'warning'}
                    className={'ms-2'}
                    onClick={toggleCriteria}
                  >
                    Criterii
                  </Button>
                )}
              </CardTitle>
              <div className="d-flex">
                {!loading && (
                  <UserPicker
                    label={false}
                    onPick={(userId: any) => handleAddUser(userId)}
                  />
                )}
              </div>
            </CardHeader>
            <CardBody>
              {users?.length > 0 && (
                <GroupUsersTable
                  users={users}
                  onRemove={handleRemoveUser}
                  onAdd={handleAddUser}
                />
              )}
              {!users?.length && <p>Grupul nu are utilizatori</p>}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        open={showCriteria}
        title={'Criterii automatizare grup'}
        toggle={toggleCriteria}
      >
        <div>
          {group?.criteria?.length > 0 && (
            <div>
              <div>
                {group?.criteria.map((c: any) => {
                  return (
                    <div key={c.id} className={'mb-2'}>
                      <strong>{c.attribute}</strong>
                      <p>
                        {c.operator} {c.value}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GroupPreviewPage;
