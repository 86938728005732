import React, { useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import screenfull from 'screenfull';
import brand from '../../../../../brand';
import ShortCuts from './ShortCuts';
import ProfileMenu from './ProfileMenu';
import UserPicker from '@/components/UserPicker';

const Header = (props: any) => {
  const [search, setsearch] = useState(false);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  const handleSearchUser = (id: any) => {
    if (id) {
      navigate(`/app/users/${id}`);
    } else {
      navigate(`/app/users`);
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header ">
          <div className="d-flex">
            <div className="navbar-brand-box ">
              <Link to="/app" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={brand.logo} alt="logo-sm-dark" height="44" />
                </span>
                <span className="logo-lg">
                  <img src={brand.logo} alt="logo-dark" height="44" />
                </span>
              </Link>

              <Link to="/app" className="logo logo-light">
                <span className="logo-sm">
                  <img src={brand.logo} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={brand.logo} alt="logo-light" height="24" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>
          </div>
          <div className={'w-25'}>
            {currentLocation.pathname.includes('/app/users') && (
              <div className="app-search d-none d-lg-block w-100">
                <div className="position-relative">
                  <UserPicker
                    label={false}
                    searchKey="uuid"
                    onPick={(id: any) => handleSearchUser(id)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="ri-search-line" />
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="ri-search-line" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <ShortCuts />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
