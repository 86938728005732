import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMilestone } from '@/types/loyalty-campaign';

interface ILoyaltyCampaignState {
  milestones: IMilestone[];
  error: any;
}

const initialState: ILoyaltyCampaignState = {
  milestones: [],
  error: null,
};

const loyaltyCampaignSlice = createSlice({
  name: 'gesp/loyalty-campaigns',
  initialState,
  reducers: {
    updateMilestones: (state, action: PayloadAction<any>) => {
      state.milestones = [...state.milestones, action.payload];
    },
    resetMilestones: (state) => {
      state.milestones = [];
    },
    deleteMilestone: (state, action: PayloadAction<string>) => {
      state.milestones = state.milestones.filter(
        (milestone) => milestone.name !== action.payload,
      );
    },
  },
});

export const { updateMilestones, resetMilestones, deleteMilestone } =
  loyaltyCampaignSlice.actions;

export default loyaltyCampaignSlice.reducer;
