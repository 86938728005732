import { contestInfo } from '@/api/contest';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

import ContestTransactions from './components/ContestTransactions';
import ContestParticipants from './components/ContestParticipants';
import ContestTotalParticipators from './components/ContestTotalParticipations';
import LocationPicker from '@/components/LocationPicker';

const ContestInfo = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>({});
  const [tab, setTab] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [locationId, setLocationId] = useState<any>(null);

  useEffect(() => {
    contestInfo(id).then(async (res) => {
      setData(res.data.contest);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Adauga Concurs - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem={data.name ?? 'Concursuri'}
          />
          <div className="d-flex justify-content-between">
            <ul className="nav nav-tabs content-tabs">
              <li className="nav-item">
                <Button
                  onClick={() => setTab(0)}
                  className={`tab nav-link ${tab === 0 ? 'active' : ''}`}
                >
                  Participari
                </Button>
              </li>

              <li className="nav-item">
                <Button
                  onClick={() => setTab(1)}
                  className={`tab nav-link ${tab === 1 ? 'active' : ''}`}
                >
                  Total Participari per User
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  onClick={() => setTab(2)}
                  className={`tab nav-link ${tab === 2 ? 'active' : ''}`}
                >
                  Tranzactii
                </Button>
              </li>
            </ul>
            <div style={{ width: 400, display: 'flex' }}>
              <div style={{ flexGrow: 1, marginRight: 20 }}>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Cauta"
                />
              </div>
              <div style={{ flexGrow: 1, marginRight: 20 }}>
                <LocationPicker
                  selectedLocation={locationId}
                  hideLabel
                  onPick={(id: any) => setLocationId(id)}
                />
              </div>
            </div>
          </div>

          {tab === 0 && (
            <ContestParticipants
              contest_id={Number(id)}
              locationId={locationId}
              search={search}
            />
          )}

          {tab === 1 && (
            <ContestTotalParticipators
              contest_id={Number(id)}
              locationId={locationId}
              search={search}
            />
          )}
          {tab === 2 && (
            <ContestTransactions
              locationId={locationId}
              search={search}
              contest_id={Number(id)}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContestInfo;
