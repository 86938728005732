import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUsersState {
  loading: boolean;
  search: string;
  filters: any;
  page: number;
  sort: string;
  order: 'asc' | 'desc';
  perPage: number;
  total: number;
  users: any[];
}

const initialState: IUsersState = {
  loading: true,
  search: '',
  filters: {},
  sort: 'id',
  order: 'desc',
  page: 1,
  perPage: 10,
  total: 0,
  users: [],
};

const usersSlice = createSlice({
  name: 'gesp/users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload.data;
      state.total = action.payload.total;
      state.perPage = action.payload.per_page;
      state.loading = false;
      state.page = action.payload.current_page;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload.sort;
      state.order = action.payload.order;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setUsers,
  setSort,
  setOrder,
  setPage,
  setPerPage,
  setSearch,
  setFilters,
} = usersSlice.actions;

export default usersSlice.reducer;
