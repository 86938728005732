import React, { useEffect, useState } from 'react';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import { viewContest } from '@/api/contest';
import Loader from '@/components/Loader';
import ContestForm from './ContestForm';
import ContestPrizes from './ContestPrizes';
import ContestPrizeModal from './ContestPrizeModal';

const ViewContestPage = () => {
  const { id } = useParams<{ id: any }>();
  const [contest, setContest] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [prize, setPrize] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    viewContest(id)
      .then((res) => {
        setContest(res.data.contest);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleSubmitSuccess = () => {
    viewContest(id).then((res) => {
      setContest(res.data.contest);
    });
    setPrize(null);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${contest.name} - ${brand.name}`}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Concursuri" />
          <Card>
            <CardHeader className="bg-primary d-flex justify-content-between">
              <CardTitle className="text-white">
                Concurs: {contest.name}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader loading={true} />
              ) : (
                <ContestForm
                  initialValues={contest}
                  onSubmit={handleSubmitSuccess}
                />
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="bg-primary d-flex justify-content-between">
              <CardTitle className="text`-white">Premii concurs</CardTitle>
              <Button
                onClick={() => setPrize({})}
                className="btn btn-secondary btn-sm"
              >
                Adauga Premiu
              </Button>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader loading={true} />
              ) : (
                <ContestPrizes
                  onChange={handleSubmitSuccess}
                  setPrize={setPrize}
                  prizes={contest.prizes}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {prize && (
        <ContestPrizeModal
          record={prize}
          handleClose={() => setPrize(null)}
          onSubmit={handleSubmitSuccess}
          contestId={id}
        />
      )}
    </React.Fragment>
  );
};

export default ViewContestPage;
