import { destroy, index, sort, store } from '@/api/faq';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setData } from '@/redux/slices/faqs.slice';
import { update } from '@/api/faq';
const useFaq = () => {
  const data = useAppSelector((state) => state.faq);
  const dispatch = useAppDispatch();

  const getFaq = () => index().then((res) => dispatch(setData(res.data)));

  const createFaq = (params: any) => store(params);

  const updateFaq = (id: number, params: any) => update(id, params);

  const deleteFaq = (id: number) => destroy(id);

  const sortFaq = (params: any) => sort(params);

  return {
    ...data,
    getFaq,
    createFaq,
    updateFaq,
    deleteFaq,
    sortFaq,
  };
};

export default useFaq;
