import axios from './axios';

export const getUsersRequest = async (params: any = {}) => {
  return await axios.get('/users', { params });
};

export const updateUserRequest = (uuid: string, data: any) => {
  return axios.post(`/users/${uuid}`, data);
};

export const createUserRequest = async (data: any) => {
  return await axios.post('/users', data);
};

export const updateOrCreateUserBusiness = (uuid: string, data: any) => {
  return axios.post(`/users/${uuid}/business`, data);
};

export const removeUserBusiness = (uuid: string) => {
  return axios.delete(`/users/${uuid}/business`);
};

export const toggleUserStatusRequest = (uuid: string) => {
  return axios.post(`/users/${uuid}/toggle-status`);
};

export const sendPointsRequest = (uuid: string, data: any) => {
  return axios.post(`/users/${uuid}/points`, data);
};

export const sendUserNotificationRequest = (
  uuid: string,
  data: { title: string; message: string },
) => {
  return axios.post(`/users/${uuid}/notify`, data);
};

export const getUserRequest = (uuid: string) => axios.get(`/users/${uuid}`);

export const userStatistics = (uuid: string) =>
  axios.get(`/statistics/users/${uuid}`);

export const usersStatistics = () => axios.get(`/statistics/users`);
