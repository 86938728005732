const options: any = {
  options: {
    legend: {
      show: true,
    },
    chart: {
      height: 350,
      type: 'treemap',
    },
    title: {
      text: 'Top Categorii',
    },
  },
};

export default options;
