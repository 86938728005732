import React from 'react';
import { Button } from 'reactstrap';

const SectionItemsTable = ({
  items,
  setEditItem,
  toggleItem,
  removeItem,
}: any) => {
  const renderItems = () => {
    return (
      items &&
      items.map((item: any, index: number) => {
        return (
          <tr key={`${index}-item`}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.url}</td>
            <td>
              {item.image ? (
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ maxHeight: 30 }}
                />
              ) : (
                'Not Set'
              )}
            </td>
            <td>{item.enabled ? 'Activ' : 'Inactiv'}</td>
            <td className="d-flex justify-content-end">
              <button
                onClick={() => setEditItem(item)}
                className="btn btn-sm btn-primary"
              >
                Modifica
              </button>
              <Button
                onClick={() => toggleItem(item.id, !item.enabled)}
                color={item.enabled ? 'warning' : 'info'}
                size="sm"
              >
                {item.enabled ? 'Dezactiveaza' : 'Activeaza'}
              </Button>
              <Button
                onClick={() => removeItem(item.id)}
                size="sm"
                color="danger"
              >
                Sterge
              </Button>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap table-hover mb-0">
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ width: '70px' }}>
                #
              </th>
              <th scope="col">Nume</th>
              <th scope="col">Url</th>
              <th scope="col">Image</th>
              <th scope="col">Enabled</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="font-14">
            {renderItems()}
            {!items.length && (
              <tr>
                <td colSpan={7} className="text-center">
                  Nu exista inregistrari
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default SectionItemsTable;
