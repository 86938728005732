import { useAppDispatch, useAppSelector } from '@/redux/store';
import {
  updateMilestones,
  resetMilestones,
  deleteMilestone,
} from '@/redux/slices/loyaltyCampaigns.slice';
import {
  createLoyaltyCampaign,
  createMilestoneForCampaign,
  deleteMilestoneForCampaign,
  getLoyaltyCampaignById,
  updateLoyaltyCampaign,
  updateMilestoneForCampaign,
  getTransactionsForCampaign,
  activateLoyaltyCampaign,
  deactivateLoyaltyCampaign,
} from '@/api/loyalty-campaigns';

import { ILoyaltyCampaign } from '@/types/loyalty-campaign';

const useLoyaltyCampaign = () => {
  const dispatch = useAppDispatch();
  const reducer = useAppSelector((state) => state['loyaltyCampaign']);

  /**
   * Add a milestone to the state
   * @param milestone
   */
  const addMilestone = (milestone: any) => {
    // check if the milestone already exists
    const milestoneIndex = reducer.milestones.findIndex(
      (ms) => milestone.number === ms.number,
    );

    // check if the milestone required amount is greater than the previous milestone
    const hasSmallerValue = reducer.milestones.filter((ms) => {
      return ms.required_amount > milestone.required_amount;
    });

    if (milestoneIndex !== -1 || hasSmallerValue.length > 0) {
      return { error: true };
    }
    dispatch(updateMilestones(milestone));
  };

  /**
   * Removes a milestone by dispatching a deleteMilestone action.
   * The action will remove the milestone from the state.
   *
   * @param {string} name - The name of the milestone to be removed.
   * @returns {void}
   */
  const removeMilestone = (name: string): void => {
    dispatch(deleteMilestone(name));
  };

  /**
   * Deletes a milestone for a campaign. It will delete the milestone from database.
   *
   * @param milestoneId
   */
  const deleteMilestoneRequest = async (milestoneId: number) => {
    try {
      return await deleteMilestoneForCampaign(milestoneId);
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  /**
   * Creates a milestone for a campaign. It will create the milestone in the database.
   * @param campaignId
   * @param milestone
   */
  const createMilestone = async (campaignId: number, milestone: any) => {
    try {
      const response = await createMilestoneForCampaign(campaignId, milestone);
      return response?.campaign;
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  /**
   * Updates a milestone for a campaign. It will update the milestone in the database.
   * @param campaignId
   * @param milestone
   */
  const updateMilestone = async (campaignId: number, milestone: any) => {
    try {
      return await updateMilestoneForCampaign(campaignId, milestone);
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  /**
   * Creates a campaign using the provided values and milestones.
   *
   * @param {any} values - The values to be used for creating the campaign.
   * @returns {Promise} A promise that resolves to the response of the API call.
   */
  const createCampaign = async (values: any): Promise<any> => {
    // get milestones from reducer
    // call API
    const response = await createLoyaltyCampaign({
      ...values,
      milestones: reducer.milestones,
    });

    if (response.status === 201) {
      dispatch(resetMilestones());
    }
    return response;
  };

  /**
   *
   * @param id
   * @param values
   */
  const updateCampaign = async (id: number, values: any): Promise<any> => {
    return await updateLoyaltyCampaign(id, values);
  };

  /**
   * Activates a campaign by its ID.
   * @param {number} id
   */
  const activateCampaign = async (id: number): Promise<any> => {
    try {
      return await activateLoyaltyCampaign(id);
      // return response;
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  /**
   * Deactivates a campaign by its ID.
   * @param id
   */
  const deactivateCampaign = async (id: number): Promise<any> => {
    try {
      return await deactivateLoyaltyCampaign(id);
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  /**
   * Retrieves a campaign by its ID from the loyalty API.
   *
   * @async
   * @param {string} id - The ID of the campaign.
   * @returns {Promise<Object|null>} The campaign object if successful, otherwise null.
   */
  const getCampaignDetails = async (
    id: string,
  ): Promise<{ campaign: ILoyaltyCampaign } | null> => {
    // call API to get campaign
    const response = await getLoyaltyCampaignById(Number(id));

    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  const getCampaignTransactions = async (id: number) => {
    return await getTransactionsForCampaign(id);
  };

  return {
    ...reducer,
    addMilestone,
    createMilestone,
    resetMilestones,
    removeMilestone,
    deleteMilestoneRequest,
    updateMilestone,
    createCampaign,
    updateCampaign,
    getCampaignDetails,
    getCampaignTransactions,
    activateCampaign,
    deactivateCampaign,
  };
};

export default useLoyaltyCampaign;
