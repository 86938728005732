import React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../../components/Breadcrumbs';
import brand from '../../../brand';
import NotificationsTable from './Components/NotificationsTable';

const Marketing = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Marketing - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[{ title: 'Marketing', url: '/app/marketing' }]}
            breadcrumbItem="Marketing"
          />{' '}
          <NotificationsTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Marketing;
