import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import Swal from 'sweetalert2';
import { Container } from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  getUserRequest,
  sendPointsRequest,
  sendUserNotificationRequest,
  toggleUserStatusRequest,
} from '@/api';
import Loader from '@/components/Loader';
import brand from '@/brand';
import ProfileDetails from './components/ProfileDetails';
import ModalPuncte from './components/PointsModal';
import ProfileMenu from './components/ProfileMenu';
import UserDetailsForm from './components/UserDetailsForm';
import UsersBusinessForm from './components/UsersBusinessForm';
import { errorAlert, successAlert } from '@/helpers/alerts';
import NotificationModal from './components/NotificationModal';
import Referrals from './components/Referrals';
import UserStatistics from '../All/components/UserStatistics';
import UserTransactions from './components/UserTransactions';
import UserNotifications from './components/UserNotifications';

const ShowUserPage = () => {
  const params = useParams();
  const [pointsModal, setPointsModal] = React.useState<boolean>(false);
  const [notifModal, setNotifModal] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [user, setUser] = React.useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transactions, setTransactions] = React.useState<any>([]);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [referrals, setReferrals] = React.useState<any>([]);

  const [business, setBusiness] = React.useState<any>(null);
  const [invitedBy, setInvitedBy] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getUser = (uuid: string) => {
    getUserRequest(uuid).then((res: any) => {
      setUser(res.data.user);
      if (res.data.transactions) {
        setTransactions(res.data.transactions);
      }
      if (res.data.business) {
        setBusiness(res.data.business);
      }

      if (res.data.invitedBy) {
        setInvitedBy(res.data.invitedBy);
      }
      if (res.data.user.referrals) {
        setReferrals(res.data.referrals);
      }
      if (res.data.notifications) {
        setNotifications(res.data.notifications);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (params.uuid === undefined) return;
    getUser(params.uuid);
  }, [params.uuid]);

  const toggleUserActive = () => {
    if (user.enabled) {
      Swal.fire({
        title: 'Dezactivare utilizator',
        text: 'Sunteti sigur ca doriti sa dezactivati acest utilizator? Accesul acestuia va fi blocat.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, dezactiveaza!',
        cancelButtonText: 'Nu, anuleaza!',
      }).then((result) => {
        if (result.value) {
          toggleUserStatusRequest(user.uuid).then((res: any) => {
            setUser(res.data.user);
          });
          setUser({ ...user, enabled: false });
        }
      });
    } else {
      Swal.fire({
        title: 'Activare utilizator',
        text: 'Sunteti sigur ca doriti sa activati acest utilizator? Accesul acestuia va fi deblocat.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, activeaza!',
        cancelButtonText: 'Nu, anuleaza!',
      }).then((result) => {
        if (result.value) {
          toggleUserStatusRequest(user.uuid).then((res: any) => {
            setUser(res.data.user);
          });
          setUser({ ...user, enabled: true });
        }
      });
    }
  };

  const sendPoints = (values: any) => {
    sendPointsRequest(user.uuid, values)
      .then((res) => {
        setUser({ ...user, ...res.data.user });
        successAlert(
          `${user.first_name} a primit de la dvs ${values.points} puncte`,
        );
        setPointsModal(false);
      })
      .catch((e) => {
        errorAlert('Ceva nu a mers cum trebuie');
      });
  };

  const sendNotif = (values: { title: string; message: string }) => {
    sendUserNotificationRequest(user.uuid, values)
      .then((res) => {
        successAlert('Notificare trimisa cu succes');
        getUser(user.uuid);
        setNotifModal(false);
      })
      .catch((e) => {
        errorAlert('Ceva nu a mers cum trebuie');
      });
  };

  if (!user) return null;

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`${loading ? 'Please wait...' : user.first_name} ${
            user.last_name
          } - ${brand.name}`}
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                title: 'Utilizatori',
                url: '/app/users',
              },
              {
                title: `${user.first_name} ${user.last_name}`,
              },
            ]}
            breadcrumbItem={`${user.first_name} ${user.last_name}`}
          />
          <Loader loading={loading} fullHeight />
          <UserStatistics uuid={user.uuid} />
          <div className="row">
            <ProfileDetails
              openModal={() => setPointsModal(true)}
              notifModal={() => setNotifModal(true)}
              user={user}
              invitedBy={invitedBy}
              toggleUserActive={toggleUserActive}
            />
            <div className="col-md-4">
              <Referrals referrals={referrals} />
            </div>
            <div className="col-md-4" />
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Date Utilizator</h4>
                  {!user.enabled && (
                    <div className="alert alert-danger">
                      Acest utilizator este dezactivat. Accesul lui este blocat.
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-2">
                      <ProfileMenu
                        activeStep={activeStep}
                        setStep={(step: number) => {
                          setActiveStep(step);
                        }}
                      />
                    </div>
                    <div className="col-md-10">
                      {activeStep === 0 && (
                        <UserDetailsForm user={user} setUser={setUser} />
                      )}
                      {activeStep === 1 && (
                        <UsersBusinessForm
                          user={user}
                          business={business}
                          setBusiness={setBusiness}
                        />
                      )}
                      {activeStep === 2 && (
                        <UserTransactions transactions={transactions} />
                      )}
                      {activeStep === 3 && (
                        <UserNotifications notifications={notifications} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {pointsModal && (
        <ModalPuncte
          handleSubmit={sendPoints}
          open={pointsModal}
          onToggle={() => setPointsModal(!pointsModal)}
        />
      )}
      {notifModal && (
        <NotificationModal
          handleSubmit={sendNotif}
          onToggle={() => setNotifModal(!notifModal)}
          open={notifModal}
        />
      )}
    </React.Fragment>
  );
};

export default ShowUserPage;
