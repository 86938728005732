import React, { useRef, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import ApexCharts from 'apexcharts';
import options from './options';
import useDashboard from '@/hooks/useDashoard';

const Overview = () => {
  const chartRef = useRef<any>(null);
  const { getOverview, overview } = useDashboard();
  useEffect(() => {
    getOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (overview && overview.series.length > 0 && chartRef.current) {
      options.series = overview.series;
      options.xaxis.categories = overview.categories;
      chartRef.current.innerHTML = '';
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
    }
  }, [overview]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div ref={chartRef} style={{ width: '100%' }} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Overview;
