import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Modal } from 'reactstrap';
import UserGroupForm from '@/pages/User/Groups/Components/UserGroupForm';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele grupului este obligatoriu'),
  description: Yup.string().nullable(),
  type: Yup.string().required('Tipul grupului este obligatoriu'),
  criteria: Yup.array()
    .of(
      Yup.object().shape({
        attribute: Yup.string().required('Atributul este obligatoriu'),
        operator: Yup.string().required('Operatorul este obligatoriu'),
        value: Yup.string().required('Valoarea este obligatorie'),
      }),
    )
    .nullable(),
});
const GroupModalForm = ({ dbData, onSubmit, id, open, handleClose }: any) => {
  const initialValues = {
    id: dbData?.id || '',
    name: dbData?.name || '',
    description: dbData?.description || '',
    type: dbData?.type || 'manual',
    criteria: dbData.criteria || [],
  };

  return (
    <Modal isOpen={open} toggle={() => handleClose()} backdrop={'static'}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{id ? 'Modifica' : 'Adauga'} Grup</h5>
        <Button type="button" className="close" onClick={() => handleClose()}>
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <UserGroupForm />
        </Formik>
      </div>
    </Modal>
  );
};

GroupModalForm.defaultProps = {
  initialValues: {
    name: '',
  },
  onSubmit: () => {},
};
export default GroupModalForm;
