import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import PushNotification from '@/components/Notifications/PushNotificationEdit';
import MailNotification from '@/components/Notifications/MailNotificationEdit';
import SmsNotification from '@/components/Notifications/SmsNotificationEdit';
import { getMarketingDataById } from '@/api/marketing';
import Loader from '@/components/Loader';
import { INotificationStatus } from '@/types/notifications';
import PreviewNotification from './PreviewNotification';

const EditNotificationPage = () => {
  const { type, id } = useParams();
  const [notification, setNotification] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getMarketingDataById(id).then((res) => {
      setNotification(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Loader loading={loading} />
        {!loading && (
          <Container fluid={true}>
            <Breadcrumbs
              title={brand.shortName}
              bread={[
                { title: 'Marketing', url: '/app/marketing' },
                {
                  title: `Modifica ${notification.label}`,
                  url: `/app/marketing/${type}/${id}`,
                },
              ]}
              breadcrumbItem={`Notificare noua - ${type}`}
            />
            {[INotificationStatus.SENT, INotificationStatus.PROCESSING].indexOf(
              notification.status,
            ) >= 0 ? (
              <>
                <div className="alert alert-danger">
                  <strong>Atentie!</strong> Nu se mai pot face modificari la
                  notificare deoarece a fost deja trimisa.
                </div>
                <PreviewNotification notification={notification} />
              </>
            ) : (
              <>
                {type === 'push' && (
                  <PushNotification id={id} notification={notification} />
                )}
                {type === 'email' && (
                  <MailNotification id={id} notification={notification} />
                )}
                {type === 'sms' && (
                  <SmsNotification id={id} notification={notification} />
                )}
              </>
            )}
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default EditNotificationPage;
