import React from 'react';
import DataTable from 'react-data-table-component';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import dayjs from 'dayjs';

const OpenNotif = (row: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="btn btn-info btn-sm">
        <i className="mdi mdi-eye"></i>
      </Button>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader>{row.title}</ModalHeader>
        <ModalBody>{row.message}</ModalBody>
        <ModalFooter>
          Primit la: {dayjs(row.created_at).format('DD/MM/YYYY HH:mm')}
        </ModalFooter>
      </Modal>
    </>
  );
};
const columns = [
  {
    keyField: 'id',
    name: '#',
    width: '5%',
    selector: (row: any, index: any) => {
      return index + 1;
    },
    sortable: false,
  },
  {
    keyField: 'title',
    name: 'title',
    selector: (row: any) => row.title,
    sortable: true,
  },
  {
    keyField: 'message',
    name: 'Message',
    selector: (row: any) => row.message,
    sortable: true,
  },
  {
    keyField: 'created_at',
    name: 'Date',
    selector: (row: any) => dayjs(row.created_at).format('DD/MM/YYYY HH:mm'),
    sortable: true,
    sortFunction: (a: any, b: any) => {
      return dayjs(a.created_at).unix() - dayjs(b.created_at).unix();
    },
  },
  {
    keyField: 'actions',
    name: 'Actiuni',
    sortable: false,
    selector: OpenNotif,
    width: '90px',
  },
];

const UserNotifications = ({ notifications }: any) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle className="text-white">Notificari primite</CardTitle>
        </CardHeader>
        <CardBody>
          {notifications.length === 0 && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100%' }}
            >
              Nu exista notificari primite
            </div>
          )}

          <DataTable
            pagination
            paginationPerPage={20}
            columns={columns}
            data={notifications}
            dense
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UserNotifications;
