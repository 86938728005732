import Swal from 'sweetalert2';

const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const successToast = (message: string) => {
  toast.fire({
    icon: 'success',
    title: message,
  });
};

export const errorToast = (message: string) => {
  toast.fire({
    icon: 'error',
    title: message,
  });
};

export const warningToast = (message: string) => {
  toast.fire({
    icon: 'warning',
    title: message,
  });
};

export const infoToast = (message: string) => {
  toast.fire({
    icon: 'info',
    title: message,
  });
};

export const questionToast = (message: string) => {
  toast.fire({
    icon: 'question',
    title: message,
  });
};

export const successAlert = (message: string) => {
  Swal.fire({
    icon: 'success',
    title: message,
    confirmButtonColor: '#0bb197',
    timerProgressBar: true,
    timer: 2000,
  });
};

export const errorAlert = (message: string) => {
  Swal.fire({
    icon: 'error',
    title: message,
  });
};

export const warningAlert = (message: string) => {
  Swal.fire({
    icon: 'warning',
    title: message,
  });
};

export const infoAlert = (message: string) => {
  Swal.fire({
    icon: 'info',
    title: message,
  });
};

export const questionAlert = (message: string) => {
  Swal.fire({
    icon: 'question',
    title: message,
  });
};
