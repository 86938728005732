const brand = {
  name: 'Sory Club Admin',
  shortName: 'Sory Club',
  description: 'Make it simple',
  logo: '/assets/sory-logo.svg',
  logoText: 'Sory Club',
  brandColors: {
    primary: '#5c2c91',
    primaryLight: '#8e5ea2',
    secondary: '#1a0f50',
    secondaryLight: '#4d4171',
  },
};

export default brand;
