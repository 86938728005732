import React, { useEffect } from 'react';
import FormikField from '../FormikField';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  cif: Yup.string().required('Codul de identificare este obligatoriu'),
});

const BusinessForm = ({ business, handleSubmit, onRemove }: any) => {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (business) {
      formik.setValues(business);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <FormikField
              name="cif"
              formik={formik}
              label="Cif"
              placeholder="Cif"
            />
          </Col>
          <Col md={6}>
            <FormikField
              name="name"
              formik={formik}
              label="Nume Firma"
              placeholder="Nume"
              disabled
            />
          </Col>
          <Col md={6}>
            <FormikField
              name="reg_com"
              formik={formik}
              disabled
              label="Registru Comert"
              placeholder="Registru Comert"
            />
          </Col>
          <Col md={6}>
            <FormikField
              name="address"
              formik={formik}
              disabled
              label="Adresa"
              placeholder="Adresa"
            />
          </Col>
          <Col md={6}>
            <FormikField
              name="city"
              disabled
              formik={formik}
              label="Judet"
              placeholder="Judet"
            />
          </Col>
          <Col md={6}>
            <FormikField
              disabled
              name="vat"
              type="select"
              formik={formik}
              label="TVA"
            >
              <option value="da">Da</option>
              <option value="nu">Nu</option>
            </FormikField>
          </Col>
          <Col md={12}>
            {onRemove && business.id && (
              <Button onClick={onRemove} color="danger">
                Sterge asocierea
              </Button>
            )}
            {!business.id && (
              <Button color="primary" type="submit">
                Salveaza
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default BusinessForm;
