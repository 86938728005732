import axios from './axios';

export const getNotifications = async (params: any = {}) => {
  return await axios.get('/notifications', { params });
};

export const getNotification = (id: number | string) => {
  return axios.get(`/notifications/${id}`);
};

export const updateNotification = (id: number | string, data: any) => {
  return axios.post(`/notifications/${id}`, data);
};
