import { deleteAppContent, getAppContent, toggleState } from '@/api';
import { useEffect, useCallback, useRef } from 'react';
import { setSort, setData, setSearch } from '@/redux/slices/appContent.slice';
import { useAppDispatch, useAppSelector } from '@/redux/store';

const reducerName = 'appContent';

const useAppContent = () => {
  const dispatch = useAppDispatch();
  const timer = useRef<any>(null);
  const reducer = useAppSelector((state) => state[reducerName]);

  const getData = useCallback((params: any) => getAppContent(params), []);

  const dataQuery = (others: any = {}) => {
    return getData({ ...getFilters(), ...others }).then((res) => {
      dispatch(setData(res.data[reducerName]));
    });
  };

  const getFilters = () => {
    return {
      search: reducer.search,
      sort: reducer.sort,
      order: reducer.order,
    };
  };

  useEffect(() => {
    dataQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSort = (sort: string) => {
    const order =
      reducer.sort === sort
        ? reducer.order === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch(
      setSort({
        sort,
        order,
      }),
    );
    dataQuery({ sort, order });
  };

  // this should be debounced
  const changeSearch = (search: string) => {
    dispatch(setSearch(search));
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dataQuery({ search });
    }, 500);
  };

  const deleteRequest = (id: number) => deleteAppContent(id);

  const toggleAppContent = (id: number, state: boolean) =>
    toggleState(id, state);

  return {
    ...reducer,
    changeSort,
    getData,
    dataQuery,
    changeSearch,
    deleteRequest,
    toggleAppContent,
  };
};

export default useAppContent;
