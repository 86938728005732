import { useCallback } from 'react';
import {
  setData,
  setFilters,
  setSort,
  setPage,
  setPerPage,
  setLoading,
  setSortDirection,
} from '@/redux/slices/salesReports.slice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import dayjs from 'dayjs';

const useSalesReportsHook = (getDataMethod: (params: any) => any) => {
  const dispatch = useAppDispatch();
  const reducer = useAppSelector((state) => state.salesReports);
  const getSales = useCallback((params: any) => getDataMethod(params), []);

  const getFilters = () => {
    return {
      filters: reducer.filters,
      page: reducer.page,
      sort: reducer.sort,
      order: reducer.sortDirection,
      perPage: reducer.perPage,
    };
  };

  const salesQuery = (others: any = {}) => {
    dispatch(setLoading(true));
    return getSales({ ...getFilters(), ...others }).then((res) => {
      return dispatch(setData(res.data));
    });
  };

  const getDataForExport = () => {
    return getSales({ ...getFilters(), page: 1, perPage: reducer.total });
  };

  const changeSort = (newSort: string, newDirection?: string) => {
    const order =
      reducer.sort === newSort
        ? reducer.sortDirection === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch(setSort(newSort));
    dispatch(setSortDirection(order));

    if (
      (newSort !== reducer.sort && newSort !== undefined) ||
      order !== reducer.sortDirection
    ) {
      salesQuery({ newSort, order });
    }
  };

  const changePage = (page: number) => {
    const { page: currentPage } = reducer;
    if (page !== currentPage) {
      dispatch(setPage(page));
      salesQuery({ page });
    }
  };

  const changePerPage = (perPage: number) => {
    const { perPage: currentPerPage } = reducer;
    if (perPage !== currentPerPage) {
      dispatch(setPage(1));
      dispatch(setPerPage(perPage));
      salesQuery({ perPage, page: 1 });
    }
  };

  const changeFilter = (key: string, value: any) => {
    const newFilters = { ...reducer.filters, [key]: value };

    dispatch(setFilters(newFilters));
  };

  const applyFilters = () => {
    dispatch(setPage(1));
    salesQuery();
  };

  const resetFilters = () => {
    const newFilters = {
      startDate: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      min_points: '',
      max_points: '',
      min_value: '',
      max_value: '',
      user_id: '',
      location_id: '',
    };

    dispatch(setFilters(newFilters));
    salesQuery({ filters: newFilters });
  };

  const filtersToString = (): string => {
    const { filters } = getFilters();
    let activeFilters = '';
    Object.keys(filters).map((filter: any) => {
      if (filters[filter] !== '') {
        activeFilters += `${filter}-${filters[filter]}_`;
      }
      return activeFilters;
    });
    return activeFilters;
  };

  return {
    ...reducer,
    salesQuery,
    changeFilter,
    resetFilters,
    changePage,
    changePerPage,
    changeSort,
    applyFilters,
    getDataForExport,
    filtersToString,
  };
};

export default useSalesReportsHook;
