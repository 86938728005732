import React from 'react';

import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap';
import Th from '@/components/Th';
import PaginationComponent from '@/components/Paginate';
import useNotifications from '@/hooks/useNotifications';
import ChangeNotification from './ChangeNotification';
import { updateNotification } from '@/api/notifications';
import { successAlert } from '@/helpers/alerts';

const AllNotificationsPage = () => {
  const [selected, setSelected] = React.useState<any>(null);
  const {
    loading,
    data,
    sort,
    page,
    order,
    changeSort,
    changePerPage,
    perPage,
    total,
    search,
    changePage,
    changeSearch,
  } = useNotifications();

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    updateNotification(selected.id, values)
      .then(() => {
        setSelected(null);
        changePage(1);
        successAlert('Notificare actualizata cu succes!');
      })
      .finally(() => setSubmitting(false));
  };

  const renderRows = () =>
    data.map((notification: any, index: number) => (
      <tr key={`${index}-product`}>
        <td>{notification.id}</td>
        <td>{notification.name}</td>
        <td>{notification.title}</td>
        <td>{notification.body}</td>
        <td>{notification.event}</td>
        <td className="d-flex justify-content-end">
          <button
            onClick={() => setSelected(notification)}
            className="btn btn-sm btn-info"
          >
            Edit
          </button>
        </td>
      </tr>
    ));
  return (
    <React.Fragment>
      <Helmet>
        <title>Notificari Personalizate - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Personalizare Notificari"
          />
          <Card>
            <CardHeader className="bg-info">
              <CardTitle className="text-white">
                Notificari Personalizate
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col />
                <Col md={3}>
                  <div className="form-group">
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Cauta..."
                      value={search}
                      onChange={(e) => changeSearch(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Table responsive={true} hover={true}>
                <thead>
                  <tr>
                    <Th
                      sort={sort}
                      order={order}
                      onClick={changeSort}
                      prop="id"
                    >
                      #
                    </Th>
                    <Th
                      sort={sort}
                      order={order}
                      onClick={changeSort}
                      prop="name"
                    >
                      Nume
                    </Th>
                    <Th
                      sort={sort}
                      order={order}
                      onClick={changeSort}
                      prop="title"
                    >
                      Titlu
                    </Th>
                    <Th
                      sort={sort}
                      order={order}
                      onClick={changeSort}
                      prop="body"
                    >
                      Body
                    </Th>
                    <Th
                      sort={sort}
                      order={order}
                      onClick={changeSort}
                      prop="event"
                    >
                      Event
                    </Th>
                    <th className="d-flex justify-content-end">Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {' '}
                  {loading && (
                    <tr>
                      <td colSpan={6}>Loading...</td>
                    </tr>
                  )}
                  {renderRows()}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center align-items-center p-3">
                <PaginationComponent
                  page={page}
                  perPage={perPage}
                  total={total}
                  onClick={(p) => changePage(p)}
                />
                <div
                  className={`form-group d-flex align-items-center ${
                    total < 11 ? 'd-none' : ''
                  }`}
                >
                  <select
                    className="form-control"
                    id="perPage"
                    style={{ width: '40px' }}
                    value={perPage}
                    onChange={(e) => changePerPage(parseInt(e.target.value))}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                  {'   '} - pe pagina din {total}
                </div>
              </div>
            </CardFooter>
          </Card>
        </Container>
      </div>
      {selected && (
        <ChangeNotification
          notification={selected}
          onClose={() => setSelected(null)}
          onSubmit={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default AllNotificationsPage;
