import { useCallback } from 'react';
import dayjs from 'dayjs';
import {
  setData,
  setFilters,
  setPage,
  setSort,
  setPerPage,
  setLoading,
} from '@/redux/slices/usersReport.slice';
import { getUsersReport } from '@/api/reports';
import { useAppDispatch, useAppSelector } from '@/redux/store';

const useUsersReports = () => {
  const dispatch = useAppDispatch();
  const reducer = useAppSelector((state) => state.usersReport);

  const getData = useCallback((params: any) => getUsersReport(params), []);

  const getFilters = () => {
    return {
      // search: reducer?.search,
      filters: reducer.filters,
      page: reducer.page,
      sort: reducer.sort,
      order: reducer.order,
      perPage: reducer.perPage,
    };
  };

  const dataQuery = (others: any = {}) => {
    dispatch(setLoading(true));
    return getData({ ...getFilters(), ...others }).then((res) => {
      dispatch(setData(res.data));
    });
  };

  // useEffect(() => {
  //   dataQuery();
  // }, []);

  const changeFilter = (key: string, value: any) => {
    const newFilters = { ...reducer.filters, [key]: value };
    dispatch(setFilters(newFilters));
  };

  const resetFilters = () => {
    const newFilters = {
      startDate: dayjs().subtract(12, 'months').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      page: 1,
    };
    dispatch(setFilters(newFilters));
    dataQuery(newFilters);
  };

  const changePage = (page: number) => {
    const { page: currentPage } = reducer;
    if (page !== currentPage) {
      dispatch(setPage(page));
      dataQuery({ page });
    }
  };

  const changePerPage = (perPage: number) => {
    const { perPage: currentPerPage } = reducer;
    if (perPage !== currentPerPage) {
      dispatch(setPage(1));
      dispatch(setPerPage(perPage));
      dataQuery({ perPage, page: 1 });
    }
  };

  const changeSort = (sort: string, direction?: string) => {
    const { sort: currentSort, order: currentOrder } = reducer;
    if (sort !== currentSort || direction !== currentOrder) {
      dispatch(
        setSort({
          sort,
          order: direction,
        }),
      );
      dataQuery({ sort, order: direction });
    }
  };

  const applyFilters = () => {
    // dispatch(setLoading(true));
    dispatch(setPage(1));
    dataQuery();
  };

  return {
    ...reducer,
    dataQuery,
    getData,
    changeFilter,
    resetFilters,
    changePage,
    changePerPage,
    changeSort,
    applyFilters,
  };
};

export default useUsersReports;
