import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from 'reactstrap';
import Swal from 'sweetalert2';
import useLoyaltyCampaign from '@/hooks/useLoyaltyCampaign';
import { IMilestone } from '@/types/loyalty-campaign';
import { format_currency } from '@/helpers/numbers';

function CampaignMilestones() {
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen('0');
    } else {
      setOpen(id);
    }
  };

  const { milestones, removeMilestone } = useLoyaltyCampaign();
  const handleDelete = (name) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Daca stergi acest prag, nu il vei putea recupera!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da, sterge pragul!',
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch to remove milestone from state
        removeMilestone(name);
        Swal.fire('Prag sters!', 'Pragul a fost sters cu succes.', 'success');
      }
    });
  };
  return (
    <div>
      {milestones?.length > 0 && (
        <Accordion open={open} toggle={toggle}>
          {milestones.map((milestone: IMilestone, index: number) => (
            <AccordionItem key={`milestone-${index}`}>
              <AccordionHeader targetId={`id${index}`} title={milestone.name}>
                <h5 className={'m-0'}>{milestone.name}</h5>
              </AccordionHeader>
              <AccordionBody accordionId={`id${index}`}>
                <div>
                  <p>{milestone.reward_description}</p>
                  <p>
                    <strong>Suma necesara: </strong>
                    {format_currency(milestone.required_amount)}
                  </p>
                  <p>
                    <strong>Bonus: </strong>
                    {milestone.reward_points} puncte
                  </p>
                  <div>
                    <Button
                      type={'button'}
                      size={'sm'}
                      color={'danger'}
                      onClick={() => handleDelete(milestone.name)}
                    >
                      Sterge prag
                    </Button>
                  </div>
                </div>
              </AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </div>
  );
}

export default CampaignMilestones;
