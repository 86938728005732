import AsyncSelect from 'react-select/async';
import { searchCategories } from '@/api/autocomplete';
import { useEffect, useState } from 'react';

let timeout: any = null;
const CategoryPicker = ({ onPick, value }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>(value);

  useEffect(() => {
    if (options.length > 0) {
      setSelected(options.find((o: any) => o.value === value));
    }
  }, [options, value, selected]);

  return (
    <div className="form-group">
      <label htmlFor="user">Categorie</label>
      <AsyncSelect
        cacheOptions
        isClearable
        defaultOptions
        value={selected}
        loadOptions={(inputValue: any, callback: any) => {
          if (inputValue && timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            searchCategories({ search: inputValue }).then((response: any) => {
              callback(response.data);
              setOptions(response.data);
            });
          }, 500);
        }}
        onChange={(value: any) => onPick(value?.value)}
      />
    </div>
  );
};

export default CategoryPicker;
