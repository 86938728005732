import dayjs from 'dayjs';

/**
 * Format date using dayjs
 * @param date
 * @param format
 */
export function format_date(date: string, format: string = 'DD.MM.YYYY HH:mm') {
  if (!date) return 'N/A';

  return dayjs(date).format(format);
}
