import axios from './axios';

export const getAppContent = (params: any = {}) => {
  return axios.get('/app-content', { params });
};

export const toggleState = (id: number, state: boolean) => {
  return axios.post(`/app-content/${id}/toggle-state`, { enabled: state });
};

export const deleteAppContent = (id: number) => {
  return axios.delete(`/app-content/${id}`);
};

export const getSection = (id: any) => {
  return axios.get(`/app-content/${id}`);
};

export const createAppContent = (params: any = {}) => {
  return axios.post('/app-content', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateAppContent = (id: any, params: any) => {
  return axios.post(`/app-content/${id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/*!SECTION ITEMS */
export const addSectionItem = (id: any, params: any) => {
  return axios.post(`/app-content/${id}/items`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateSectionItem = (id: any, itemId: any, params: any) => {
  return axios.post(`/app-content/${id}/items/${itemId}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteSectionItem = (id: any, itemId: any) => {
  return axios.delete(`/app-content/${id}/items/${itemId}`);
};

export const toggleSectionItem = (id: any, itemId: any, state: boolean) => {
  return axios.post(`/app-content/${id}/items/${itemId}/toggle-state`, {
    enabled: state,
  });
};

export const changeContentSortOrder = (items: any) => {
  return axios.post(`/app-content-change-sort-order`, { items });
};
