import { _login, _profile } from '@/api';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { authenticate } from '@/redux/slices/auth.slice';
import { IUser } from '@/types/user';

const useCurrentUser = () => {
  const dispatch = useAppDispatch();
  const { token, user, isAuthenticated } = useAppSelector(
    (state) => state.auth,
  );

  const login = (email: string, password: string) => _login(email, password);

  const getProfile = () => _profile();

  const setUser = (user: IUser, token?: string | null | undefined) => {
    dispatch(authenticate({ token, user }));
  };

  return {
    token,
    profile: user,
    isAuthenticated,
    dispatch,
    login,
    getProfile,
    setUser,
  };
};

export default useCurrentUser;
