import AsyncSelect from 'react-select/async';
import { searchUsers } from '@/api/autocomplete';
import { useEffect, useState } from 'react';

let timeout: any = null;
const UserPicker = ({ onPick, value, label, searchKey }: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleChange = (value: any) => {
    onPick(value?.value);
    setSelectedOption(value);
  };

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    }
  }, [value]);

  return (
    <div className="form-group">
      {label && <label htmlFor="user">{label}</label>}
      <AsyncSelect
        placeholder="Cauta utilizator..."
        cacheOptions
        isClearable
        defaultOptions
        loadOptions={(inputValue: any, callback: any) => {
          if (inputValue && timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            searchUsers({ search: inputValue, searchKey }).then(
              (response: any) => {
                callback(response.data);
              },
            );
          }, 500);
        }}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
};

UserPicker.defaultProps = {
  label: 'Utilizator',
  searchKey: 'id',
};

export default UserPicker;
