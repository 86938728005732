import { IMilestone } from '@/types/loyalty-campaign';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from 'reactstrap';
import React from 'react';

const MilestoneCard = ({
  milestone,
  deleteAction,
  editAction,
  disableActions,
}: {
  milestone: IMilestone;
  deleteAction: (id: number) => void;
  editAction: () => void;
  disableActions: boolean;
}) => {
  const handleDelete = async () => {
    deleteAction(milestone.id);
  };

  const handleEdit = () => {
    // open modal with milestone form
    editAction();
  };

  return (
    <Card className="my-2 border border-secondary" color="light" outline>
      <CardHeader>
        {milestone.name} <Badge>{milestone.number}</Badge>
      </CardHeader>
      <CardBody>
        <CardText>{milestone.reward_description}</CardText>
        <Row>
          <Col md={6}>
            <CardText>
              <strong>Prag necesar:</strong> <br /> {milestone.required_amount}{' '}
              RON
            </CardText>
          </Col>
          <Col md={6}>
            <CardText>
              <strong>Bonus acordat:</strong> <br /> {milestone.reward_points}{' '}
              puncte
            </CardText>
          </Col>
        </Row>
        <Row className={'mt-2'}>
          <Col md={6}>
            <CardText>
              <strong>Tranzactii:</strong> <br /> {milestone?.transactions}
            </CardText>
          </Col>
          <Col md={6}>
            <CardText>
              <strong>Clienti:</strong> <br /> {milestone?.user_entries}
            </CardText>
          </Col>
        </Row>
        <Row className={'mt-4'}>
          <Col md={6}>
            <Button
              size={'sm'}
              color={'primary'}
              onClick={handleEdit}
              disabled={disableActions}
            >
              <i className={'fa fa-edit'} />
            </Button>
          </Col>
          <Col md={6} className={'text-end'}>
            <Button
              size={'sm'}
              color={'danger'}
              onClick={handleDelete}
              disabled={disableActions}
            >
              <i className={'fa fa-trash'} />
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default MilestoneCard;
