import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import ContestForm from './ContestForm';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  name: '',
  description: '',
  categories: [],
  start_date: '',
  end_date: '',
  image: '',
  winners_list: '',
  rules_link: '',
};

const ContestPage = () => {
  const navigate = useNavigate();
  const onSubmit = (contest: any) => {
    navigate(`/app/contest/${contest.id}`);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Adauga Concurs - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Concursuri" />
          <Card>
            <CardHeader className="bg-primary d-flex justify-content-between">
              <CardTitle className="text-white">Concurs Nou</CardTitle>
            </CardHeader>
            <CardBody>
              <ContestForm initialValues={initialValues} onSubmit={onSubmit} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContestPage;
