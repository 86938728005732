import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import Modal from '@/components/Modal';
import CreateForm from '../components/CreateForm';
import useFaq from '@/hooks/useFaq';
import FaqsTable from '../components/FaqTable';

const FaqPage = () => {
  const { data, getFaq } = useFaq();
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Intrebari frecvente"
            bread={[{ title: 'FAQ', url: '/app/faq' }]}
          />
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between bg-primary">
              <CardTitle className="text-white">Intrebari frecvente</CardTitle>
              <Button
                onClick={() => setCreating(true)}
                className="btn btn-sm btn-primary"
              >
                Adauga
              </Button>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <FaqsTable reload={() => getFaq()} faqs={data} />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        title="Adauga intrebare"
        open={creating}
        toggle={() => setCreating(false)}
      >
        <CreateForm
          onCreated={() => {
            setCreating(false);
            getFaq();
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default FaqPage;
