import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import brand from '@/brand';
import d from '../../../../../../package.json';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={5}>
              {new Date().getFullYear()} © {brand.name}.
            </Col>
            <Col sm={2}>v{d.version}</Col>
            <Col sm={5}>
              <div className="text-sm-end d-none d-sm-block">
                Crafted with <i className="mdi mdi-heart text-danger"></i> by
                Gesp IT Solutions
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
