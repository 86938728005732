import {
  contestClone,
  getAllContests,
  toggleStatus,
  toggleSchedule,
} from '@/api/contest';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

const ContestPage = () => {
  const [contests, setContests] = React.useState([]);

  useEffect(() => {
    getAllContests().then((res) => {
      setContests(res.data.contests);
    });
  }, []);

  const handleContestStatusChange = (contest: any) => {
    if (contest.is_active) {
      // is active shit
      Swal.fire({
        title: 'Esti sigur ca vrei sa dezactivezi concursul activ?',
        text: 'In aplicatie concursul nu o sa mai fie vizibil si punctele nu se vor mai colecta',
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          toggleStatus(contest.id, {
            action: 'deactivate',
          }).then(() => {
            getAllContests().then((res) => {
              setContests(res.data.contests);
            });
          });
        }
      });
    } else {
      // is not active shit
      Swal.fire({
        title: 'Esti sigur ca vrei sa activezi acest concurs?',
        text: 'Tranzactiile viitoare eligibile vor fi asociate cu concursul activ. Daca un alt concurs este activ, acesta se va dezactiva.',
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          toggleStatus(contest.id, {
            action: 'activate',
          }).then(() => {
            getAllContests().then((res) => {
              setContests(res.data.contests);
            });
          });
        }
      });
    }
  };

  // const handleContestSchedule = (contest: any) => {
  //   toggleSchedule(contest.id, { status: contest.status }).then((res) => {
  //     getAllContests().then((res) => {
  //       setContests(res.data.contests);
  //     });
  //   });
  // };

  const handleCloneAction = (contest: any) => {
    Swal.fire({
      title: 'Esti sigur ca vrei sa clonezi concursul?',
      text: 'Se vor clona premiile si categoriile la care se refera concursul',
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        contestClone(contest.id).then(() => {
          getAllContests().then((res) => {
            setContests(res.data.contests);
          });
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Concursuri - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem="Concursuri" />
          <Card>
            <CardHeader className="bg-primary d-flex justify-content-between">
              <CardTitle className="text-white">Concursuri</CardTitle>
              <Link
                className="btn btn-secondary btn-sm"
                to="/app/contest/create"
              >
                Adauga concurs
              </Link>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th>ID</th>
                      <th scope="col">Nume</th>
                      <th scope="col">Descriere</th>
                      <th scope="col">Început</th>
                      <th scope={'col'}>Sfârşit</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contests.map((contest: any, index: number) => (
                      <tr key={`contest-${contest.id}`}>
                        <td>{index + 1}</td>
                        <td>{contest.id}</td>
                        <td>{contest.name}</td>
                        <td className={'text-wrap'}>{contest.description}</td>
                        <td>{dayjs(contest.start_date).format('DD.MM.YY')}</td>
                        <td>{dayjs(contest.end_date).format('DD.MM.YY')}</td>
                        <td>
                          <button
                            onClick={() => handleContestStatusChange(contest)}
                            className={`btn btn-sm btn-${
                              contest.is_active ? 'success' : 'danger'
                            }`}
                          >
                            {contest.is_active ? 'Activ' : 'Inactiv'}
                          </button>
                        </td>
                        <td>
                          <Link
                            className="btn btn-sm btn-primary m-1"
                            to={`/app/contest/${contest.id}`}
                          >
                            <i className="fas fa-edit"></i>
                          </Link>
                          <Link
                            className="btn btn-sm btn-success"
                            to={`/app/contest/${contest.id}/info`}
                          >
                            <i className="fas fa-info"></i>
                          </Link>
                          <Button
                            onClick={() => handleCloneAction(contest)}
                            className="btn btn-sm btn-info m-1"
                          >
                            Cloneaza
                          </Button>
                          {/*{contest.can_be_scheduled && (*/}
                          {/*  <Button*/}
                          {/*    onClick={() => handleContestSchedule(contest)}*/}
                          {/*    title={*/}
                          {/*      contest.status === 'scheduled'*/}
                          {/*        ? 'Dezactiveaza programarea'*/}
                          {/*        : 'Activeaza programarea'*/}
                          {/*    }*/}
                          {/*    className={`btn btn-sm btn-${*/}
                          {/*      contest.status === 'scheduled'*/}
                          {/*        ? 'success'*/}
                          {/*        : 'disabled'*/}
                          {/*    }`}*/}
                          {/*  >*/}
                          {/*    <i className={'fas fa-calendar-check'}></i>*/}
                          {/*  </Button>*/}
                          {/*)}*/}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContestPage;
