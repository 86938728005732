import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';

const ShortCuts = () => {
  const [singlebtn, setSinglebtn] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-apps-2-line"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
          <div className="px-lg-2">
            <SimpleBar>
              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="/app/users">
                    <i className="ri-user-2-line"></i>
                    <span>Utilizatori</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="/app/products">
                    <i className="ri-shopping-bag-2-line"></i>
                    <span>Produse</span>
                  </Link>
                </Col>
                <Col>
                  <Link
                    className="dropdown-icon-item"
                    to="/app/marketing/calendar"
                  >
                    <i className="ri-calendar-2-line"></i>
                    <span>Marketing</span>
                  </Link>
                </Col>
              </Row>
              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="/app/locations">
                    <i className="ri-map-pin-line"></i>
                    <span>Magazine</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="/app/settings">
                    <i className="ri-settings-2-line"></i>
                    <span>Setari</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="/app/reports/users">
                    <i className="ri-pie-chart-2-line"></i>
                    <span>Rapoarte</span>
                  </Link>
                </Col>
              </Row>
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ShortCuts;
