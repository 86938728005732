import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ILocation } from '@/types/location';
import brand from '@/brand';
import { getLocationRequest, updateLocationRequest } from '@/api';
import { Container } from 'reactstrap';
import Breadcrumbs from '@/components/Breadcrumbs';
import LocationForm from '../components/Summary/LocationForm';
import Loader from '@/components/Loader';
import { successAlert } from '@/helpers/alerts';

const ViewLocationPage = () => {
  const [location, setLocation] = useState<ILocation>({} as ILocation);
  const [loading, setLoading] = useState<boolean>(false);
  const { uuid } = useParams();

  useEffect(() => {
    setLoading(true);
    getLocationRequest(uuid as string)
      .then((res) => {
        if (res.data) {
          setLocation(res.data.location);
        }
      })
      .finally(() => setLoading(false));
  }, [uuid]);

  const handleSubmit = (loc: ILocation) => {
    setLoading(true);
    updateLocationRequest(location.uuid, loc)
      .then((res) => {
        if (res.data) {
          setLocation(res.data.location);
          successAlert('Locatia a fost modificata cu succes!');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {location.name || 'Incarca'} - {brand.name}
        </title>
      </Helmet>
      <div className="page-content text-white">
        <Container fluid={true}>
          <Breadcrumbs title={brand.shortName} breadcrumbItem={location.name} />
          {location && location.name && (
            <LocationForm
              loading={loading}
              location={location}
              handleSubmit={handleSubmit}
            />
          )}
          <Loader loading={loading} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewLocationPage;
