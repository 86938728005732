const options: any = {
  options: {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: 'treemap',
    },
    title: {
      text: 'Top produse',
    },
  },
};

export default options;
