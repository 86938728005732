import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFaqState {
  loading: boolean;
  data: any;
}

const initialState: IFaqState = {
  loading: true,
  data: [],
};

const faqSlice = createSlice({
  name: 'gesp/reports/users',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
});

export const { setData, setLoading } = faqSlice.actions;

export default faqSlice.reducer;
