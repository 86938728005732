export enum INotificationStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
}

export interface IAdminNotification {
  id: number;
  label: string;
  title: string;
  subject: string;
  message: string;
  type: 'email' | 'sms' | 'push';
  status: INotificationStatus;
  run_at: string;
  rules: any;
  user_id: number;
  user: any;
  created_at: string;
  notifications?: any[];
  notifications_count?: number;
}
