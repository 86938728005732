import React from 'react';
import { Container, Table } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '@/components/Breadcrumbs';
import brand from '@/brand';
import UserSummary from './components/Summary';
import useUsers from '@/hooks/useUsers';
import Th from '@/components/Th';
import PaginationComponent from '@/components/Paginate';
import { Link } from 'react-router-dom';
import SearchGroup from '@/components/Notifications/NotificationFilters/SearchGroup';
import dayjs from 'dayjs';
import { format_number } from '@/helpers/numbers';

const AllUsersPage = () => {
  const {
    loading,
    users,
    sort,
    page,
    order,
    search,
    filters,
    changeSort,
    changePerPage,
    perPage,
    total,
    changePage,
    changeSearch,
    changeFilters,
  } = useUsers();

  const renderUsers = (users: any) => {
    return users.map((user: any, index: number) => (
      <tr key={`user-${index}`}>
        {/* calculate index based on current page */}
        <td>{(page - 1) * perPage + index + 1}</td>
        <td>{user.id}</td>
        <td>
          {user.first_name} {user.last_name}
        </td>
        <td>{user.email}</td>
        <td>{user.age ? user.age : 'n/a'}</td>
        <td>{`${user.avg_spent} RON`}</td>
        <td>{user.phone_no}</td>
        <td>{format_number(user.points)}</td>
        <td style={{ textTransform: 'capitalize' }}>{user.sex}</td>
        <td>{user.address}</td>
        <td>{user.business_id ? 'Firma' : 'Persoana Fizica'}</td>
        <td>{dayjs(user.created_at).format('DD/MM/YYYY HH:mm')}</td>
        <td>{user.role_id}</td>
        <td>
          <Link to={`/app/users/${user.uuid}`} className="btn btn-sm btn-info">
            <i className="fa fa-eye"></i>
          </Link>
        </td>
      </tr>
    ));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{brand.name} - Utilizatori</title>
      </Helmet>
      <div className="page-content">
        <Breadcrumbs
          title={brand.shortName}
          bread={[
            {
              url: '/app/users',
              title: 'Utilizatori',
            },
          ]}
          breadcrumbItem="Utilizatori"
        />
        <Container fluid={true}>
          <UserSummary />
          <div className="card">
            <div className="card-body">
              <div className="card-title">Utilizatori {brand.shortName}</div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="type-filter">Tip</label>
                    <select
                      onChange={(e) =>
                        changeFilters('is_business', e.target.value)
                      }
                      className="form-control"
                      value={filters.is_business}
                    >
                      <option value="">Toate</option>
                      <option value="1">Firma</option>
                      <option value="0">Persoana Fizica</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="sex-filter">Sex</label>
                    <select
                      onChange={(e) => changeFilters('sex', e.target.value)}
                      className="form-control"
                      value={filters.sex}
                    >
                      <option value="">Toate</option>
                      <option value="masculin">Masculin</option>
                      <option value="feminin">Feminin</option>
                      <option value="nespecificat">Nespecificat</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="role-filter">Rol</label>
                    <select
                      onChange={(e) => changeFilters('role_id', e.target.value)}
                      className="form-control"
                      value={filters.role_id}
                    >
                      <option value="">Toate</option>
                      <option value="ADMIN">Administrator</option>
                      <option value="USER">Utilizator</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <SearchGroup
                    value={filters.groups}
                    onPick={(e: any) => changeFilters('groups', e)}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="search">Cauta</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Cauta"
                      onChange={(e) => changeSearch(e.target.value)}
                      value={search}
                    />
                    <small className="form-text text-muted">
                      Cauta dupa nume, email, telefon, adresa
                    </small>
                  </div>
                </div>
              </div>
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <th>Nr.crt</th>
                    <Th
                      prop="id"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      ID
                    </Th>
                    <Th
                      prop="name"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Nume
                    </Th>
                    <Th
                      prop="email"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Email
                    </Th>
                    <Th
                      prop="age"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Varsta
                    </Th>
                    <Th
                      prop="avg_spent"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Cump. Med
                    </Th>
                    <Th
                      prop="phone_no"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Telefon
                    </Th>
                    <Th
                      prop="points"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Puncte
                    </Th>
                    <Th
                      prop="sex"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Sex
                    </Th>
                    <Th
                      prop="address"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Adresa
                    </Th>
                    <Th
                      prop="is_business"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Tip
                    </Th>
                    <Th
                      prop="created_at"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Data Inregistrare
                    </Th>
                    <Th
                      prop="role_id"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Rol
                    </Th>
                    <th>Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    renderUsers(users)
                  )}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center align-items-center p-3">
              <PaginationComponent
                page={page}
                perPage={perPage}
                total={total}
                onClick={(p) => changePage(p)}
              />
              <div
                className={`form-group d-flex align-items-center ${
                  total < 11 ? 'd-none' : ''
                }`}
              >
                <select
                  className="form-control"
                  id="perPage"
                  style={{ width: '40px' }}
                  value={perPage}
                  onChange={(e) => changePerPage(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
                {'   '} - pe pagina din {total}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllUsersPage;
