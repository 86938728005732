import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import useDashboard from '@/hooks/useDashoard';
import dayjs from 'dayjs';

const SuspectActivity = () => {
  const { suspectedTransactions, getSuspectedTransactions } = useDashboard();

  useEffect(() => {
    getSuspectedTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Achizitii Suspecte</CardTitle>
          <Table responsive>
            <thead>
              <tr>
                <td>#</td>
                <td>Utilizator</td>
                <td>Locatie</td>
                <td>Numar achizitii</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {suspectedTransactions?.length > 0 ? (
                <>
                  {suspectedTransactions?.map(
                    (transaction: any, index: number) => (
                      <tr key={`transacxtion-${index}`}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/app/users/${transaction?.user?.uuid}`}>
                            {transaction?.user?.first_name}{' '}
                            {transaction?.user?.last_name}
                          </Link>
                        </td>
                        <td>{transaction?.location?.name}</td>
                        <td>{transaction?.transactions_count}</td>
                        <td>{dayjs(transaction?.date).format('DD/MM/YYYY')}</td>
                      </tr>
                    ),
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>Nu exista tranzactii</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-center" colSpan={6}>
                  <Link to="/app/">Vezi toate tranzactiile</Link>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SuspectActivity;
function getSuspectedTransactions() {
  throw new Error('Function not implemented.');
}
