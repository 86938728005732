import { getCategoriesRequest, updateCategoryRequest } from './../api';
import { useEffect, useCallback, useRef } from 'react';
import {
  setPage,
  setSort,
  setData,
  setPerPage,
  setSearch,
} from '@/redux/slices/categories.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { createCategoryRequest } from '../api/categories';

const reducerName = 'categories';

const useCategories = () => {
  const dispatch = useAppDispatch();
  const timer = useRef<any>(null);
  const reducer = useAppSelector((state) => state[reducerName]);

  const getData = useCallback(
    (params: any) => getCategoriesRequest(params),
    [],
  );

  const dataQuery = (others: any = {}) => {
    return getData({ ...getFilters(), ...others }).then((res) =>
      dispatch(setData(res.data[reducerName])),
    );
  };

  const getFilters = () => {
    return {
      search: reducer.search,
      page: reducer.page,
      sort: reducer.sort,
      order: reducer.order,
      perPage: reducer.perPage,
    };
  };

  useEffect(() => {
    dataQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSort = (sort: string) => {
    const order =
      reducer.sort === sort
        ? reducer.order === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch(
      setSort({
        sort,
        order,
      }),
    );
    dataQuery({ sort, order });
  };

  const changePage = (page: number) => {
    dispatch(setPage(page));
    dataQuery({ page });
  };

  const changePerPage = (perPage: number) => {
    dispatch(setPerPage(perPage));
    dataQuery({ perPage, page: 1 });
  };
  // this should be debounced
  const changeSearch = (search: string) => {
    dispatch(setSearch(search));
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dataQuery({ search });
    }, 500);
  };

  const updateCategory = (id: number, data: any, cb: any) => {
    return updateCategoryRequest(id, data)
      .then((res) => {
        dataQuery();
        cb && cb(res.data.category);
      })
      .catch((err) => {
        cb && cb(null, err);
      });
  };

  const createCategory = (data: any, cb: any) => {
    return createCategoryRequest(data)
      .then((res) => {
        dataQuery();
        cb && cb(res.data.category);
      })
      .catch((err) => {
        cb && cb(null, err);
      });
  };

  return {
    ...reducer,
    changeSort,
    getData,
    changePage,
    changePerPage,
    changeSearch,
    updateCategory,
    createCategory,
  };
};

export default useCategories;
