import React, { useEffect, useState } from 'react';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import { deleteTheme, getThemes, setForAdmin, setPublic } from '@/api/appTheme';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const ThemePage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [themes, setThemes] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getThemes()
      .then((res) => {
        setThemes(res.data.themes);
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          title: 'A aparut o eroare!',
          text: 'A aparut o eroare la incarcarea temelor aplicatiei',
        });
        setLoading(false);
      });
  }, []);

  const handleActivate = (theme: any) => {
    if (theme.active) {
      Swal.fire({
        title: 'Tema este deja activa!',
      });
    }

    Swal.fire({
      title: 'Esti sigur?',
      text: 'Tema va fi activata pentru toti utilizatorii!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, activeaza tema!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        setPublic(theme.id).then((res: any) => {
          setThemes(res.data.themes);
        });
      }
    });
  };

  const handleActivateForAdmin = (theme: any) => {
    if (theme.show_to_admins) {
      Swal.fire({
        title: 'Tema este deja activa pentru admini!',
      });
    } else {
      Swal.fire({
        title: 'Tema va fi activata pentru admini!',
        text: 'Aceasta tema va fi vizibila doar pentru admini!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, activeaza tema!',
        cancelButtonText: 'Nu, renunta!',
      }).then((result) => {
        if (result.isConfirmed) {
          setForAdmin(theme.id).then((res: any) => {
            setThemes(res.data.themes);
          });
        }
      });
    }
  };

  const handleDelete = (theme: any) => {
    if (theme.active) {
      Swal.fire({
        title: 'Tema este activa!',
        text: 'Tema nu poate fi stearsa cat timp este activa!',
      });
      return;
    }

    if (theme.show_to_admins) {
      Swal.fire({
        title: 'Tema este activa pentru admini!',
        text: 'Tema nu poate fi stearsa cat timp este activa pentru admini!',
      });
      return;
    }

    Swal.fire({
      title: 'Esti sigur?',
      text: 'Stergerea temei este ireversibila!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge tema!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTheme(theme.id).then((res: any) => {
          setThemes(res.data.themes);
          Swal.fire(
            'Tema stearsa!',
            'Tema a fost stearsa cu succes.',
            'success',
          );
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Tema aplicatie - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem="Teme Aplicatie"
          />
          <Card>
            <CardHeader className="bg-primary">
              <CardTitle className="text-white d-flex justify-content-between align-items-center">
                <span>Teme aplicatie</span>
                <div>
                  <Link to="/app/theme/new" className="btn btn-sm btn-warning">
                    <i className="fas fa-plus-circle"></i> Creeaza
                  </Link>{' '}
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table className="table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Culori Principale</th>
                    <th scope="col">Activa</th>
                    <th scope="col">Activ pt. Admin</th>
                    <th scope="col">Creeat de</th>
                    <th scope="col">Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {themes.map((theme, index) => (
                    <tr key={`theme-${index}`}>
                      <td>{index + 1}</td>
                      <td>{theme.name}</td>
                      <td>
                        <Button
                          color="black"
                          style={{
                            background: theme.colors.primary,
                          }}
                        >
                          {theme.colors.primary}
                        </Button>
                        <Button
                          color="black"
                          style={{
                            color: '#000000!important',
                            background: theme.colors.secondary,
                          }}
                        >
                          {theme.colors.secondary}
                        </Button>
                      </td>
                      <td>
                        <Button
                          color={theme.active ? 'success' : 'danger'}
                          size="sm"
                          onClick={() => handleActivate(theme)}
                          active={true}
                        >
                          {theme.active ? 'Live' : 'Inactiv'}
                        </Button>
                      </td>
                      <td>
                        <Button
                          color={theme.show_to_admins ? 'success' : 'danger'}
                          size="sm"
                          onClick={() => handleActivateForAdmin(theme)}
                          active={true}
                        >
                          {theme.show_to_admins ? 'Da' : 'Nu'}
                        </Button>
                      </td>
                      <td>
                        {theme.creator
                          ? `${theme.creator.first_name} ${theme.creator.last_name}`
                          : 'System'}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            navigate(`/app/theme/edit/${theme.id}`)
                          }
                          className="mr-2"
                          color="primary"
                          size="sm"
                        >
                          Editeaza
                        </Button>{' '}
                        <Button
                          onClick={() => handleDelete(theme)}
                          color="danger"
                          size="sm"
                        >
                          Sterge
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ThemePage;
