import {
  addSectionItem,
  deleteSectionItem,
  getSection,
  toggleSectionItem,
  updateAppContent,
  updateSectionItem,
} from '@/api';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import AppContentForm from '../components/AppContentForm';
import { errorAlert, successAlert } from '@/helpers/alerts';
import SectionItemsTable from '../components/SectionItemsTable';
import SectionItemForm from '../components/SectionItemForm';
import Modal from '@/components/Modal';
import Swal from 'sweetalert2';

const ViewAppContentPage = () => {
  const { id } = useParams();
  const [section, setSection] = useState<any>(null);
  const [item, setItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = (idx: any) => {
    getSection(idx)
      .then((res) => {
        setSection(res.data.section);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  const handleUpdate = (data: any) => {
    updateAppContent(id, data)
      .then((res) => {
        setSection(res.data.section);
        successAlert('Continutul a fost salvat cu succes');
      })
      .catch((err) => {
        errorAlert('A aparut o eroare la salvarea continutului');
      });
  };

  const handleCreateItem = (data: any) => {
    if (item.id) {
      // update
      updateSectionItem(section.id, item.id, data)
        .then(() => {
          getData(id);
          setItem(null);
          successAlert('Continutul a fost salvat cu succes');
        })
        .catch(() => {
          errorAlert('A aparut o eroare la salvarea continutului');
        });
    } else {
      addSectionItem(section.id, data)
        .then(() => {
          getData(id);
          setItem(null);
          successAlert('Continutul a fost salvat cu succes');
        })
        .catch(() => {
          errorAlert('A aparut o eroare la salvarea continutului');
        });
    }
  };

  const toggleItem = (itemId: any, state: any) => {
    toggleSectionItem(section.id, itemId, state).then(() => {
      getData(id);
      successAlert('Continutul a fost salvat cu succes');
    });
  };
  const removeItem = (item: any) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Stergerea este ireversibila!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSectionItem(section.id, item).then(() => {
          getData(id);
          successAlert('Continutul a fost sters cu succes');
        });
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={brand.shortName}
            bread={[
              {
                url: '/app/contents',
                title: 'Oferte',
              },
              {
                title: 'Vizualizare',
                ulr: `/app/contents/${id}`,
              },
            ]}
            breadcrumbItem="Oferte"
          />
          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-primary">
                  <CardTitle className="text-white">Detalii Sectiune</CardTitle>
                </CardHeader>
                <CardBody>
                  <AppContentForm
                    content={section}
                    handleSubmit={handleUpdate}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              {section.mode === 'slider' && (
                <Card>
                  <CardHeader className="bg-primary d-flex justify-content-between">
                    <CardTitle className="text-white">
                      Vizualizare Sectiune
                    </CardTitle>
                    <div className="card-header-actions">
                      <Button
                        onClick={() => setItem({})}
                        color="secondary"
                        size="sm"
                      >
                        Adauga
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <SectionItemsTable
                      items={section.items}
                      toggleItem={toggleItem}
                      removeItem={removeItem}
                      setEditItem={setItem}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {item && (
        <Modal title="Adauga Element" open toggle={() => setItem(null)}>
          <SectionItemForm content={item} handleSubmit={handleCreateItem} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ViewAppContentPage;
