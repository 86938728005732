import React, { useEffect, useCallback, useState } from 'react';
import FormikField from '@/components/FormikField';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DropFile from '@/components/DropFile';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu'),
  url: Yup.string().required('Url-ul este obligatoriu'),
});

interface SectionItem {
  name: string;
  url: string;
  description?: string;
  image?: string;
}

const SectionItemForm = ({ content, handleSubmit }: any) => {
  const [file, setFile] = useState<any>(null);
  const initialValues: SectionItem = {
    name: '',
    url: '',
    description: '',
    image: '',
    ...content,
  };
  const onSubmit = (values: any, formikProps: any) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('url', values.url ? values.url : '');
    if (file) {
      formData.append('image', file);
    }
    handleSubmit(formData, formikProps);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  // useEffect(() => {
  //   if (content) {
  //     formik.setValues(content);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [content]);

  const onFileDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    setFile(file);
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <FormikField
              name="name"
              formik={formik}
              label="Nume"
              placeholder="Nume"
            />
            <FormikField
              name="description"
              formik={formik}
              label="Descriere"
              placeholder="Descriere"
              type="textarea"
            />

            <FormikField
              name="url"
              formik={formik}
              label="Url"
              placeholder="Link"
            />
            <p>Imagine</p>
            <DropFile onDropFile={onFileDrop} defaultPreview={content.image} />
          </Col>

          <Col className="d-flex justify-content-end" md={12}>
            <Button color="primary" type="submit">
              Salveaza
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default SectionItemForm;
