import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '@/brand';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
  Table,
} from 'reactstrap';
// import LocationsSummary from "../components/Summary";
import Th from '@/components/Th';
import useLocations from '@/hooks/useLocations';
import PaginationComponent from '@/components/Paginate';
import { Link } from 'react-router-dom';
import { syncCalendar } from '@/api/locations';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

const AllLocationsPage = () => {
  const {
    loading,
    data,
    sort,
    page,
    order,
    changeSort,
    changePerPage,
    perPage,
    total,
    changePage,
    changeSearch,
  } = useLocations();
  const [syncLoading, setLoading] = React.useState(false);
  const renderRows = () =>
    data.map((location: any, index: number) => (
      <tr key={`${index}-location`}>
        <td>{location.id}</td>
        <td>{location.name}</td>
        <td>{location.email}</td>
        <td>{location.phone_no}</td>
        <td>{location.address}</td>
        <td>{location.city}</td>
        <td>
          {location?.opening_hours?.updated_at
            ? dayjs(location?.opening_hours?.updated_at).format(
                'DD/MM/YYYY HH:mm',
              )
            : 'Nu a fost setat'}
        </td>
        <td className="d-flex justify-content-end">
          <Link
            to={`/app/locations/${location.uuid}`}
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>
        </td>
      </tr>
    ));

  const handleScheduleUpdate = () => {
    setLoading(true);
    syncCalendar()
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Succes',
          text: 'Orarul a fost actualizat cu succes!',
        });
        changeSearch('');
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ceva nu a mers bine!',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Magazine - {brand.name}</title>
      </Helmet>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={brand.shortName}
            breadcrumbItem={`Magazine - ${brand.shortName}`}
          />
          {/* <LocationsSummary /> */}
          <Card>
            <CardHeader className="bg-success d-flex justify-content-between align-items-center">
              <CardTitle className="text-white">
                Magazine {brand.shortName}
              </CardTitle>
              <div>
                <Button
                  onClick={handleScheduleUpdate}
                  className="btn btn-danger"
                  disabled={syncLoading}
                >
                  <i className="fa fa-refresh" />
                  Actualizează orar
                </Button>{' '}
                <Link to="/app/locations/new" className="btn btn-info">
                  Adauga Magazin
                </Link>
              </div>
            </CardHeader>
            <CardBody className="card-body">
              <div className="row">
                <div className="col" />
                <div className="col-md-3">
                  <div className="form-group">
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Cauta magazin..."
                      onChange={(e) => changeSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <Th
                      prop="id"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      ID
                    </Th>
                    <Th
                      prop="name"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Nume
                    </Th>
                    <Th
                      prop="email"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Email
                    </Th>
                    <Th
                      prop="phone_no"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Telefon
                    </Th>
                    <Th
                      prop="address"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Adresa
                    </Th>
                    <Th
                      prop="city"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Oras
                    </Th>
                    <Th
                      prop="last_synced_at"
                      order={order}
                      sort={sort}
                      onClick={changeSort}
                    >
                      Ultima sincronizare
                    </Th>
                    <th className="d-flex justify-content-end">Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={6}>Loading...</td>
                    </tr>
                  )}
                  {renderRows()}
                </tbody>
              </Table>
            </CardBody>
            <div className="d-flex justify-content-center align-items-center p-3">
              <PaginationComponent
                page={page}
                perPage={perPage}
                total={total}
                onClick={(p) => changePage(p)}
              />
              <div
                className={`form-group d-flex align-items-center ${
                  total < 11 ? 'd-none' : ''
                }`}
              >
                <select
                  className="form-control"
                  id="perPage"
                  style={{ width: '40px' }}
                  value={perPage}
                  onChange={(e) => changePerPage(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
                {'   '} - pe pagina din {total}
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllLocationsPage;
