import { IAppContent } from '@/types/appContnent';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAppContentSlice {
  loading: boolean;
  search: string;
  filters: any;
  sort: string;
  order: 'asc' | 'desc';
  data: IAppContent[];
}

const initialState: IAppContentSlice = {
  loading: true,
  search: '',
  filters: {},
  sort: 'id',
  order: 'asc',
  data: [],
};

const locationSlice = createSlice({
  name: 'gesp/appContent',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload.sort;
      state.order = action.payload.order;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { setData, setSort, setOrder, setSearch } = locationSlice.actions;

export default locationSlice.reducer;
