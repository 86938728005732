import { ISale } from '@/types/reports';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ISaleFilters {
  startDate: string;
  endDate: string;
  min_points?: number | string;
  max_points?: number | string;
  min_value?: number | string;
  max_value?: number | string;
  type?: string;
  description?: string;
  sex?: string;
  account_type?: number;
  location_id?: number | string;
  user_id?: number;
  min_age?: number | string;
  max_age?: number | string;
}

export interface ISalesState {
  loading: boolean;
  filters: ISaleFilters;
  salesData: ISale[];
  totalSales?: number;
  page: number;
  perPage: number;
  total: number;
  sort: string;
  sortDirection: 'asc' | 'desc';
}

const initialState: ISalesState = {
  loading: true,
  filters: {
    startDate: dayjs().subtract(12, 'months').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    min_points: '',
    max_points: '',
    min_value: '',
    max_value: '',
  },
  page: 1,
  perPage: 30,
  total: 0,
  sort: 'id',
  sortDirection: 'asc',
  totalSales: 0,
  salesData: [],
};

const salesReportsSlice = createSlice({
  name: 'gesp/sales-reports',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.salesData = action.payload.sales.data;
      state.totalSales = action.payload.total_amount;
      state.loading = false;
      state.total = action.payload.sales.total;
      state.perPage = action.payload.sales.per_page;
      state.page = action.payload.sales.current_page;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.sort = action.payload;
    },
    setSortDirection: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.sortDirection = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setData,
  setFilters,
  setPage,
  setPerPage,
  setSort,
  setSortDirection,
  setLoading,
} = salesReportsSlice.actions;

export default salesReportsSlice.reducer;
