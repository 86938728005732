import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col } from 'reactstrap';
import { userStatistics } from '@/api';
import CardWidget from '@/components/CardWidget';
import dayjs from 'dayjs';

interface IProps {
  uuid: string;
}

interface IStatistics {
  points: number;
  shopAvgRon: number;
  totalSpentRon: number;
  acquisitionsNo: number;
  claimsNo: number;
  claimsPoints: number;
  bonusPoints: number;
  lastTimeAtSory: string;
}

const UserStatistics: React.FC<IProps> = ({ uuid }) => {
  const [data, setData] = useState<IStatistics>({} as IStatistics);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      userStatistics(uuid).then((res: AxiosResponse<IStatistics>) => {
        setData(res.data);
        setLoading(false);
      });
    };
    fetchData();
  }, [uuid]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Total Achizitii"
            value={data.totalSpentRon + ' Ron'}
            icon="fas fa-shopping-cart"
          />
        </Col>
        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Cumparatura medie"
            value={data.shopAvgRon + ' Ron/Vizita'}
            icon="fas fa-shopping-cart"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Total vizite"
            value={`${data.acquisitionsNo} Vizite`}
            icon="fas fa-shopping-cart"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Ultima vizita"
            value={
              data.lastTimeAtSory
                ? dayjs(data.lastTimeAtSory).format('DD/MM/YYYY - HH:mm')
                : 'N/A'
            }
            icon="fas fa-shopping-cart"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Puncte Active"
            value={Number(data?.points).toFixed(2) + ' Puncte'}
            icon="fas fa-coins"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Puncte Bonus Acumulate"
            value={data.bonusPoints + ' Puncte'}
            icon="fas fa-coins"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Puncte Folosite"
            value={Number(data.claimsPoints).toFixed(2) + ' Puncte'}
            icon="fas fa-coins"
          />
        </Col>

        <Col xl={3} md={6}>
          <CardWidget
            loading={loading}
            title="Nr. Achizitii folosind puncte"
            value={data.claimsNo + ' Achizitii'}
            icon="fas fa-coins"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserStatistics;
