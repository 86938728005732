import axios from './axios';

export const getTranslationsRequest = (search: string = '') => {
  return axios.post('/translations', {
    search,
  });
};

export const updateTranslationRequest = (id: string, value: string) => {
  return axios.post(`/translations/${id}`, {
    t_value: value,
  });
};
