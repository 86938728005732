import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '@/components/Modal';
import { Button, Input, Row } from 'reactstrap';
import { Col } from 'reactstrap';
import FormikField from '@/components/FormikField';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Titul Notificarii este obligatoriu'),
  body: Yup.string().required('Continutul Notificarii este obligatoriu'),
});

const ChangeNotification = ({ onClose, onSubmit, notification }: any) => {
  const formik = useFormik({
    initialValues: {
      title: notification.title,
      body: notification.body,
    },
    onSubmit: onSubmit,
    validationSchema,
  });

  return (
    <Modal open toggle={onClose} title="Modifica Notificare">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col className="mb-3" md={12}>
            <div className="form-group">
              <label>Notificare</label>
              <Input
                type="text"
                name="title"
                label="Denumire Notificare"
                disabled
                value={notification.name}
              />
              <p className="text-muted">
                Titlul este doar cu caracter informativ, nu va fi afisat
              </p>
            </div>
          </Col>
          <Col md={12}>
            <FormikField
              name="title"
              label="Titlu Notificare"
              formik={formik}
            />
          </Col>
          <Col md={12}>
            <FormikField
              name="body"
              label="Continut Notificare"
              formik={formik}
            />
          </Col>
          <Col md={12}>
            <div>
              Pot fi folosite urmatoarele variabile: <br />
              <strong>{notification.options}</strong>
              <hr />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <label>Event</label>
              <Input
                type="text"
                name="event"
                label="Event"
                disabled
                value={notification.event}
              />
            </div>
          </Col>
          <Col className="mt-4 d-flex justify-content-end" md={12}>
            <Button
              disabled={formik.isSubmitting}
              color="primary"
              type="submit"
            >
              Modifica
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default ChangeNotification;
