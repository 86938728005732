import axios from './axios';

export const searchUsers = (params: any = {}) => {
  return axios.get('/autocomplete/users', { params });
};

export const searchGroup = (params: any = {}) => {
  return axios.get('/autocomplete/groups', { params });
};

export const searchLocations = (params: any = {}) => {
  return axios.get('/autocomplete/locations', { params });
};

export const searchCategories = (params: any = {}) => {
  return axios.get('/autocomplete/categories', { params });
};
