import React, { useCallback, useState } from 'react';
import FormikField from '@/components/FormikField';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DropFile from '@/components/DropFile';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu'),
  description: Yup.string().required('Descrierea este obligatorie'),
  mode: Yup.string().required('Modul este obligatoriu'),
  show_on: Yup.string().required('Afisarea este obligatorie'),
});

interface AppContentValues {
  name: string;
  description: string;
  mode: string;
  show_on: string;
  priority?: number;
  url?: string;
  start_date?: string;
  end_date?: string;
  image?: any;
}

const AppContentForm = ({ content, handleSubmit }: any) => {
  const [file, setFile] = useState<any>(null);

  const initialValues: AppContentValues = {
    name: '',
    description: '',
    mode: '',
    show_on: '',
    priority: 0,
    url: '',
    start_date: '',
    end_date: '',
    ...content,
  };

  const onSubmit = (values: any, formikProps: any) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('mode', values.mode);
    formData.append('show_on', values.show_on);
    formData.append('priority', values.priority ? values.priority : 0);
    formData.append('url', values.url ? values.url : '');
    formData.append('start_date', values.start_date);
    formData.append('end_date', values.end_date);

    if (file) {
      formData.append('image', file);
    }
    handleSubmit(formData, formikProps);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const onFileDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    setFile(file);
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <FormikField
              name="name"
              formik={formik}
              label="Nume"
              placeholder="Nume"
            />
            <FormikField
              name="description"
              formik={formik}
              label="Descriere"
              placeholder="Descriere"
              type="textarea"
            />
            <FormikField
              name="mode"
              formik={formik}
              label="Mod"
              placeholder="Mod"
              type="select"
              disabled={!!content.id}
            >
              <option value={''}>Selecteaza mod</option>
              <option value="slider">Slider</option>
              <option value="small_banner">Banner</option>
            </FormikField>
            <FormikField
              name="show_on"
              formik={formik}
              label="Afisare"
              placeholder="Afisare"
              type="select"
            >
              <option value={''}>Selecteaza afisare</option>
              <option value="home">Home</option>
              <option value="offers">Offers</option>
              <option value="both">Ambele</option>
            </FormikField>
            <FormikField
              name="priority"
              formik={formik}
              label="Prioritate"
              placeholder="Prioritate"
              type="number"
            />
          </Col>
          <Col
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <FormikField
              name="start_date"
              type="datetime-local"
              formik={formik}
              label="Data de inceput"
              placeholder="Data de inceput"
            />
            <FormikField
              name="end_date"
              type="datetime-local"
              formik={formik}
              label="Data de sfarsit"
              placeholder="Data de sfarsit"
            />
          </Col>
          {formik.values.mode !== 'slider' && (
            <>
              <Col md={12}>
                <FormikField
                  name="url"
                  formik={formik}
                  label="Url"
                  placeholder="Link"
                />
                <p>Imagine</p>
                <DropFile
                  onDropFile={onFileDrop}
                  defaultPreview={content.image}
                />
              </Col>
            </>
          )}
          <Col className="d-flex justify-content-end" md={12}>
            <Button color="primary" type="submit">
              Salveaza
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AppContentForm;
