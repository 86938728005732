import React from 'react';
import { Modal } from 'reactstrap';
import MilestoneForm from '@/pages/User/LoyaltyCampaigns/components/MilestoneForm';
import { IMilestone } from '@/types/loyalty-campaign';

const MilestoneModal = ({
  isOpen,
  onClose,
  submitAction,
  milestone,
}: {
  milestone?: IMilestone | null;
  isOpen: boolean;
  onClose: () => void;
  submitAction: (data: IMilestone) => any;
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleSubmitAction = async (values: any) => {
    return submitAction(values);
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Prag</h5>
        <button type="button" className="close" onClick={() => handleClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <MilestoneForm
          submitAction={handleSubmitAction}
          milestone={milestone}
        />
      </div>
    </Modal>
  );
};

export default MilestoneModal;
