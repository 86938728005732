import axios from './axios';

export const getProductsRequest = async (params: any = {}) => {
  return await axios.get('/products', { params });
};

export const getProduct = (id: number | string) => {
  return axios.get(`/products/${id}`);
};

export const createProduct = (data: any) => {
  return axios.post('/products', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateProduct = (id: number | string, data: any) => {
  return axios.post(`/products/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
