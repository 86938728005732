interface ISubMenu {
  sublabel?: string;
  link?: string;
  title?: string;
}

interface IMenuItem {
  label: string;
  icon?: string;
  url?: string;
  isHasArrow?: boolean;
  isMainMenu?: boolean;
  issubMenubadge?: boolean;
  bgcolor?: string;
  badgeValue?: string;
  subItem?: ISubMenu[];
}

const SidebarData: IMenuItem[] = [
  {
    label: 'Menu',
    isMainMenu: true,
  },
  {
    label: 'Dashboard',
    icon: 'mdi mdi-home-variant-outline',
    url: '/app',
    issubMenubadge: false,
    isHasArrow: true,
    bgcolor: 'bg-primary',
    badgeValue: '3',
  },
  {
    label: 'Utilizatori',
    isMainMenu: true,
  },
  {
    label: 'Utilizatori',
    icon: 'mdi mdi-account-circle-outline',
    url: '/app/users',
    isHasArrow: true,
  },
  {
    label: 'Grupuri',
    icon: 'mdi mdi-account-group-outline',
    url: '/app/groups',
    isHasArrow: true,
  },
  {
    label: 'Companii',
    icon: 'mdi mdi-briefcase-outline',
    url: '/app/companies',
    isHasArrow: true,
  },
  {
    label: 'Marketing',
    isMainMenu: true,
  },
  {
    label: 'Servicii Marketing',
    icon: 'mdi mdi-bullhorn-outline',
    url: '/app/marketing',
    isHasArrow: true,
  },
  {
    label: 'Calendar Marketing',
    icon: 'mdi mdi-calendar',
    url: '/app/marketing/calendar',
    isHasArrow: true,
  },
  {
    label: 'Bonus lunar',
    icon: 'mdi mdi-star-outline',
    url: '/app/loyalty-campaigns',
    isHasArrow: true,
  },
  {
    label: 'Cumparaturi',
    isMainMenu: true,
  },
  {
    label: 'Produse',
    icon: 'mdi mdi-cart-outline',
    url: '/app/products',
    isHasArrow: true,
  },
  {
    label: 'Categorii',
    icon: 'mdi mdi-folder-outline',
    url: '/app/categories',
    isHasArrow: true,
  },
  // {
  //   label: "Discount / Extra puncte",
  //   icon: "mdi mdi-tag-outline",
  //   url: "/app/discounts",
  //   isHasArrow: true,
  // },

  {
    label: 'Rapoarte',
    isMainMenu: true,
  },
  {
    label: 'Utilizatori',
    icon: 'mdi mdi-account-circle-outline',
    url: '/app/reports/users',
    isHasArrow: true,
  },
  {
    label: 'Puncte',
    icon: 'mdi mdi-star-outline',
    url: '/app/reports/points',
    isHasArrow: true,
  },
  {
    label: 'Vanzari',
    icon: 'mdi mdi-cart-outline',
    url: '/app/reports/sales',
    isHasArrow: true,
  },
  {
    label: 'Utilizatori-Magazine',
    icon: 'mdi mdi-file-document-outline',
    url: '/app/location-users',
    isHasArrow: true,
  },
  {
    label: 'Magazine',
    icon: 'mdi mdi-store-outline',
    url: '/app/reports/locations',
    isHasArrow: true,
  },
  {
    label: 'Magazine - Categorrii',
    icon: 'mdi mdi-store-outline',
    url: '/app/reports/location-categories',
    isHasArrow: true,
  },
  {
    label: 'Setari',
    isMainMenu: true,
  },
  // {
  //   label: "Setari",
  //   icon: "mdi mdi-cog-outline",
  //   url: "/app/settings",
  //   isHasArrow: true,
  // },
  {
    label: 'Magazine',
    icon: 'mdi  mdi-store-outline',
    url: '/app/locations',
    isHasArrow: true,
  },
  {
    label: 'Continut App',
    icon: 'mdi mdi-cellphone-link',
    url: '/app/contents',
    isHasArrow: true,
  },
  {
    label: 'Tema Applicatie',
    icon: 'mdi mdi-cellphone-link',
    url: '/app/theme',
    isHasArrow: true,
  },
  {
    label: 'Termeni si conditii',
    icon: 'mdi mdi-file-document-outline',
    url: '/app/terms',
    isHasArrow: true,
  },
  {
    label: 'Intrebari frecvente',
    icon: 'mdi mdi-help-circle-outline',
    url: '/app/faq',
    isHasArrow: true,
  },
  {
    label: 'Notificari personalizate',
    icon: 'mdi mdi-bell-outline',
    url: '/app/notification-templates',
    isHasArrow: true,
  },
  {
    label: 'Text aplicatie',
    icon: 'mdi mdi-text-box-outline',
    url: '/app/app-text',
    isHasArrow: true,
  },
  {
    label: 'Tombole / Concursuri',
    icon: 'mdi mdi-trophy-outline',
    url: '/app/contest',
    isHasArrow: true,
  },
];
export default SidebarData;
