import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

type PaginationProps = {
  page: number;
  perPage: number;
  onClick: (page: number) => void;
  total: number;
};

const PaginationComponent = ({
  page,
  perPage,
  onClick,
  total,
}: PaginationProps) => {
  const totalPages = Math.ceil(total / perPage);
  const [currentPages, setCurrentPages] = useState<[number, number]>([1, 6]);

  const handleClick = (newPage: number) => {
    onClick(newPage);
    const [start, end] = currentPages;
    if (newPage <= start) {
      setCurrentPages([newPage, newPage + 5]);
    } else if (newPage >= end) {
      setCurrentPages([newPage - 5, newPage]);
    }
  };

  const pages: JSX.Element[] = [];
  let key = 0;

  if (totalPages <= 1) {
    return null;
  }

  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PaginationItem key={key++} active={i === page}>
          <PaginationLink onClick={() => handleClick(i)}>{i}</PaginationLink>
        </PaginationItem>,
      );
    }
  } else {
    const [start, end] = currentPages;
    if (start > 1) {
      pages.push(
        <PaginationItem key={key++}>
          <PaginationLink onClick={() => handleClick(1)}>1</PaginationLink>
        </PaginationItem>,
      );
      if (start > 2) {
        pages.push(
          <PaginationItem key={key++} disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>,
        );
      }
    }
    for (let i = start; i <= end; i++) {
      pages.push(
        <PaginationItem key={key++} active={i === page}>
          <PaginationLink onClick={() => handleClick(i)}>{i}</PaginationLink>
        </PaginationItem>,
      );
    }
    if (end < totalPages) {
      if (end < totalPages - 1) {
        pages.push(
          <PaginationItem key={key++} disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>,
        );
      }
      pages.push(
        <PaginationItem key={key++}>
          <PaginationLink onClick={() => handleClick(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>,
      );
    }
  }

  return (
    <Pagination className="flex-grow-1 pb-0 m-0" style={{ marginBottom: 0 }}>
      <PaginationItem disabled={page === 1}>
        <PaginationLink previous onClick={() => handleClick(page - 1)} />
      </PaginationItem>
      {pages}
      <PaginationItem disabled={page === totalPages}>
        <PaginationLink next onClick={() => handleClick(page + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
