const options = (labels: string[]) => ({
  options: {
    chart: {
      width: 300,
      type: 'pie',
    },
    labels,
    title: {
      text: 'Puncte Generate vs Folosite',
    },
    colors: ['#00BCD4', '#E91E63'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  },
});

export default options;
