import React from 'react';
import { ILoyaltyCampaign } from '@/types/loyalty-campaign';
import { Link } from 'react-router-dom';
import { format_date } from '@/helpers/dates';
import StatusBadge from '@/components/StatusBadge';
import Swal from 'sweetalert2';

interface ITableRowProps {
  campaign: ILoyaltyCampaign;
  onDeleteAction: any;
  onActivateAction: any;
  onPauseAction?: any;
  onEditAction: any;
}

const TableRow = ({
  campaign,
  onDeleteAction,
  onActivateAction,
  onPauseAction,
  onEditAction,
}: ITableRowProps) => {
  const handleDeleteCampaign = (campaignId: number) => {
    Swal.fire({
      title: 'Esti sigur?',
      text: 'Datele vor fi sterse definitiv!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#a2acb6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da, sterge!',
      cancelButtonText: 'Nu, renunta!',
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteAction(campaignId);
      }
    });
  };

  return (
    <tr>
      <td>{campaign.id}</td>
      <td>{campaign.name}</td>
      <td>{format_date(campaign.start_date)}</td>
      <td>{format_date(campaign.end_date)}</td>
      <td>
        <StatusBadge status={campaign.status} />{' '}
      </td>
      <td>
        <button
          onClick={() => onEditAction(campaign)}
          className={'btn me-2 cursor-pointer text-success'}
        >
          <i className="fas fa-edit"></i>
        </button>
        <Link to={`/app/loyalty-campaigns/${campaign.id}`}>
          <i className="fas fa-eye text-info"></i>
        </Link>
        <button
          onClick={() => handleDeleteCampaign(campaign.id)}
          className={'btn text-danger cursor-pointer ms-2'}
        >
          <i className="fas fa-trash" color={'text-danger'}></i>
        </button>
        {campaign.status === 'pending' && (
          <a
            role={'button'}
            className={'ms-4 btn btn-primary btn-sm'}
            onClick={() => onActivateAction(campaign.id)}
          >
            Activeaza
          </a>
        )}
        {campaign.status === 'active' && (
          <a
            role={'button'}
            className={'ms-4 btn btn-warning btn-sm'}
            onClick={() => onPauseAction(campaign.id)}
          >
            Opreste
          </a>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
