import React from 'react';
import Modal from '@/components/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikField from '@/components/FormikField';

interface IModalGProps {
  open: boolean;
  onToggle: () => void;
  handleSubmit: (values: any) => void;
}

const initialValues = {
  points: 0,
  title: '',
  message: '',
  has_notification: false,
  details: '',
};

const validationSchema = Yup.object().shape({
  points: Yup.number().required('Punctele sunt obligatorii'),
  details: Yup.string().required('Detaliile sunt obligatorii'),
});

const ModalG: React.FC<IModalGProps> = ({ open, onToggle, handleSubmit }) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const { isSubmitting } = formik;
  console.log(isSubmitting);
  return (
    <Modal title="Adauga Puncte" open={open} toggle={() => onToggle()}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <FormikField
              formik={formik}
              name="points"
              label="Puncte"
              type="number"
              min={-10000}
              max={10000}
            />

            <FormikField
              label="Cu Notificare"
              formik={formik}
              name="has_notification"
              type="checkbox"
            />
            {formik.values.has_notification && (
              <>
                <FormikField
                  formik={formik}
                  name="title"
                  type="text"
                  label="Titlu"
                />

                <FormikField
                  formik={formik}
                  name="message"
                  label="Mesaj"
                  type="textarea"
                />
              </>
            )}
            <FormikField
              formik={formik}
              name="details"
              label="Detalii Puncte"
              type="textarea"
            />
          </div>
          <div className="col-md-12 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary py-1 mt-4"
              disabled={isSubmitting}
            >
              Adauga
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ModalG;
