import axios from './axios';

export const getDashboardSummary = () => axios.get('/dashboard/summary');

export const getOverview = () => axios.get('/dashboard/overview');

export const getProductsOverview = () => axios.get('/dashboard/products');

export const getCategoriesOverview = () => axios.get('/dashboard/categories');

export const getPointsOverview = () => axios.get('/dashboard/points');

export const getLatestTransactions = () => axios.get('/dashboard/transactions');

export const getLatestUsers = () => axios.get('/dashboard/users');

export const getSuspectTransactions = () => axios.get('/dashboard/suspect');
