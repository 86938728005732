import { contestParticipants } from '@/api/contest';
import CardWidget from '@/components/CardWidget';
import Loader from '@/components/Loader';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

const columnsParts = [
  {
    keyField: 'id',
    name: 'ID',
    keyof: 'id',
    selector: (row: any, index: any) => {
      return index + 1;
    },
    sortable: false,
  },
  {
    keyField: 'tx_id',
    name: 'ID Transaction',
    selector: (row: any) => row.tx_id,
    sortable: true,
  },
  // {
  //   keyField: 'tx_value',
  //   name: 'Tranzactie RON|pct',
  //   selector: (row: any) => `${row.value}|${row.points}`,
  // },
  {
    keyField: 'name',
    name: 'Utilizator',
    selector: (row: any) => row.name,
    sortable: true,
  },
  {
    keyField: 'location',
    name: 'Locatie',
    selector: (row: any) => row.location,
    sortable: true,
  },
  {
    keyField: 'email',
    name: 'Email',
    selector: (row: any) => row.email,
    sortable: true,
  },
  {
    keyField: 'phone_no',
    name: 'Telefon',
    selector: (row: any) => row.phone_no,
    sortable: true,
  },
];

const ContestParticipants = ({
  contest_id,
  locationId,
  search,
}: {
  contest_id: number;
  locationId: number;
  search: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [statistics, setStatistics] = useState<any>({
    total: 0,
    users: 0,
    transactions: 0,
    avg: 0,
  });

  const [perPage] = useState(25);

  const timer = useRef<any>(null);
  const [participants, setParticipants] = React.useState<any>([]);

  const fetchTransactions = useCallback(async () => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      contestParticipants(contest_id, {
        location_id: locationId,
        page,
        perPage: perPage,
        search,
      })
        .then((res) => {
          setParticipants(res.data.participations);
          setStatistics(res.data.statistics);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [contest_id, locationId, page, perPage, search]);

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contest_id, page, search, locationId]);

  useEffect(() => {
    setPage(1);
  }, [search, locationId]);

  const exportToCSV = () => {
    const csvString = Papa.unparse(participants);
    const blob = new Blob([csvString], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'export.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Card>
      <CardHeader className="bg-primary d-flex justify-content-between">
        <CardTitle className="text-white">Participari per user</CardTitle>
      </CardHeader>
      <CardBody>
        {!loading && participants && (
          <>
            <Row>
              <Col xl={3} md={6}>
                <CardWidget
                  loading={loading}
                  title="Total Participari"
                  value={`${statistics.total}`}
                  icon="fas fa-users"
                />
              </Col>
              <Col xl={3} md={6}>
                <CardWidget
                  loading={loading}
                  title="Participanti unici"
                  value={`${statistics.users}`}
                  icon="fas fa-user"
                />
              </Col>
              <Col xl={3} md={6}>
                <CardWidget
                  loading={loading}
                  title="Numar tranzactii"
                  value={`${statistics.transactions}`}
                  icon="fas fa-arrow-down"
                />
              </Col>
              <Col xl={3} md={6}>
                <CardWidget
                  loading={loading}
                  title="Medie participari per user"
                  value={`${statistics.avg.toFixed(2)}`}
                  icon="fas fa-star"
                />
              </Col>
            </Row>
            <DataTable
              columns={columnsParts}
              data={participants}
              pagination
              dense
              paginationPerPage={50}
              actions={[
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => exportToCSV()}
                >
                  Export to CSV
                </button>,
              ]}
            />
          </>
        )}
        {loading && <Loader loading={loading} />}
        {!loading && !participants && (
          <p className="text-center">Nu exista participari</p>
        )}
      </CardBody>
    </Card>
  );
};

export default ContestParticipants;
