import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

interface ILocationScheduleProps {
  schedule: any;
}

const days = {
  monday: 'Luni',
  tuesday: 'Marti',
  wednesday: 'Miercuri',
  thursday: 'Joi',
  friday: 'Vineri',
  saturday: 'Sambata',
  sunday: 'Duminica',
} as any;

const LocationSchedule: React.FC<ILocationScheduleProps> = ({ schedule }) => {
  const renderSchedule = (schedule: any) => {
    return Object.keys(days).map((day) => {
      return (
        <Row>
          <Col sm={6}>{days[day]}</Col>
          <Col style={{ textAlign: 'right' }} sm={6}>
            {schedule[day]}
          </Col>
        </Row>
      );
    });
  };
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-info">
          <CardTitle>Program Locatie</CardTitle>
          {schedule && (
            <CardSubtitle>
              Rating magazin - {schedule.rating} stele
            </CardSubtitle>
          )}
        </CardHeader>
        <CardBody style={{ textAlign: 'center' }} className="text-black">
          {!schedule ? (
            <p>
              Locatia nu are un program de functionare. Asigura-te ca google
              place Id e setat. Mergi pe pagina principala si da click pe
              sincronizare program.
            </p>
          ) : (
            renderSchedule(schedule)
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LocationSchedule;
