import React from 'react';
import dayjs from 'dayjs';
import img from './user.jpg';
import { Card, CardBody, Col } from 'reactstrap';

const ProfileDetails = ({
  user,
  invitedBy,
  openModal,
  toggleUserActive,
  notifModal,
}: any) => {
  return (
    <React.Fragment>
      <Col md={4}>
        <div className="card">
          <div className="card-body">
            <div className="card-title">Profil {user.first_name}</div>

            <div className="d-flex py-3">
              <div className="flex-shrink-0 me-3 align-self-center">
                <img className="rounded-circle avatar-xs" alt="" src={img} />
              </div>

              <div className="flex-grow-1 overflow-hidden">
                <h5 className="font-size-14 mb-1">
                  {user.first_name} {user.last_name}
                </h5>
                <p className="text-truncate mb-0">{user.email}</p>
              </div>

              <div className="flex-shrink-0 font-size-13">
                {dayjs(user.created_at).format('DD.MM.YYYY')}
              </div>
            </div>

            <div className="d-flex py-3 border-top">
              <div className="flex-shrink-0 me-3 align-self-center">
                <p className="p-0 m-0">
                  Puncte Stranse: {Number(user.points).toFixed(2)}
                </p>
                <p className="p-0 m-0">
                  Invitat de:{' '}
                  {invitedBy
                    ? `${invitedBy.first_name} ${invitedBy.last_name}`
                    : 'N/A'}
                </p>
                <p className="p-0 m-0">
                  Data de nastere:{' '}
                  {user.birth_date
                    ? `${dayjs(user.birth_date).format(
                        'DD.MM.YYYY',
                      )} (${dayjs().diff(user.birth_date, 'years')} ani)`
                    : 'Nu a fost setata'}
                </p>
                <p className="p-0 m-0">Gen: {user.sex}</p>
                <p className="p-0 m-0">
                  Cod de recomandare: {user.referral_code}
                </p>
                <p className="p-0 m-0">
                  Cumparatura Medie: {user.avg_spent} Ron
                </p>
                <p className="p-0 m-0">
                  Numar de cumparaturi: {user.transaction_count}
                </p>
              </div>
            </div>

            <div className="d-flex py-3 border-top justify-content-between">
              <button
                onClick={() => openModal()}
                className="btn btn-outline-success btn-sm"
              >
                Ofera Puncte
              </button>
              <button
                onClick={() => notifModal()}
                className="btn btn-outline-warning btn-sm"
              >
                Trimite Notificare
              </button>
              <button
                onClick={toggleUserActive}
                className={`btn btn-outline-${
                  user.enabled ? 'danger' : 'info'
                } btn-sm`}
              >
                {user.enabled ? 'Dezactiveaza' : 'Activeaza'}
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col md={4}>
        <Card>
          <CardBody style={{ height: 200 }}>
            <h4 className="card-title">Date de contact</h4>
            <p className="p-0 m-0">
              Telefon:{' '}
              {user.phone_no ? (
                <a href={`tel:${user.phone_no}`}>{user.phone_no}</a>
              ) : (
                'N/A'
              )}
            </p>
            <p className="p-0 m-0">
              Email: <a href={`mailto:${user.email}`}>{user.email}</a>
            </p>
            <p className="p-0 m-0">
              Adresa:{' '}
              {user.address ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${user.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.address}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="card-title">Notificari</h4>
            <p className="p-0 m-0">
              Notificari Push:{' '}
              {user.notifications_push ? (
                <i className="mdi mdi-check text-success" />
              ) : (
                <i
                  className="mdi mdi-close text-danger"
                  title="Acest utilizator nu doreste notificari de tip push"
                />
              )}
            </p>
            <p className="p-0 m-0">
              Notificari Email:{' '}
              {user.notifications_email ? (
                <i className="mdi mdi-check text-success" />
              ) : (
                <i className="mdi mdi-close text-danger" />
              )}
            </p>
            <p className="p-0 m-0">
              Notificari SMS:{' '}
              {user.notifications_sms ? (
                <i className="mdi mdi-check text-success" />
              ) : (
                <i className="mdi mdi-close text-danger" />
              )}
            </p>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProfileDetails;
