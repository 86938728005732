import axios from './axios';

export const getCategoriesRequest = async (params: any = {}) => {
  return await axios.get('/categories', { params });
};

export const getAllCategoriesRequest = async () => {
  return await axios.get('/categories/all');
};

export const updateCategoryRequest = async (id: number, data: any) => {
  return await axios.post(`/categories/${id}`, data);
};

export const createCategoryRequest = async (data: any) => {
  return await axios.post('/categories', data);
};
