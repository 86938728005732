import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FieldArray, Form, useFormikContext } from 'formik';
import FormikField from '@/components/FormikField';
import CriteriaFormSection from '@/pages/User/Groups/Components/CriteriaFormSection';

const UserGroupForm = () => {
  const formik = useFormikContext<any>();

  const criteria = formik.values.criteria || [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={12}>
          <FormikField
            label="Nume"
            name="name"
            formik={formik}
            placeholder="Nume"
          />
        </Col>
        <Col md={12}>
          <FormikField
            formik={formik}
            name={'description'}
            label={'Descriere'}
            multiline={true}
          />
        </Col>
        <Col md={12}>
          <FormikField
            formik={formik}
            name={'type'}
            label={'Tip grup'}
            type={'select'}
          >
            <option value="manual">Manual</option>
            <option value="automatic">Automat</option>
          </FormikField>
        </Col>
        <Col md={12}>
          {formik?.values.type === 'automatic' && (
            <FieldArray
              name={'criteria'}
              render={(arrayHelpers) => {
                return (
                  <div>
                    <a
                      className={'btn btn-outline-primary mb-2'}
                      role={'button'}
                      onClick={() =>
                        arrayHelpers.push({
                          attribute: '',
                          operator: '',
                          value: '',
                        })
                      }
                    >
                      Adauga criteriu
                    </a>
                    <div>
                      {criteria &&
                        criteria?.length > 0 &&
                        criteria?.map((criterion, index) => {
                          return (
                            <CriteriaFormSection
                              index={index}
                              name={`criteria.${index}`}
                              key={index}
                              remove={arrayHelpers.remove}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              }}
            ></FieldArray>
          )}
        </Col>

        <Col md={12}>
          <Button type="submit" className="btn btn-primary btn-success">
            Salveaza
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserGroupForm;
